import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../services/notifications/notifications.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {NOTIFICATION_TYPE} from '../../constants/notifications';
import {Unsubscribe} from '../../helpers/unsubscribe';

@Component({
    selector: 'app-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent extends Unsubscribe implements OnInit, OnDestroy {

    public date = this.getDate();

    public subscriber: any;

    public formData: {};

    public notification: any;

    public loader = false;

    public error: any;

    public button: any = 'Add Notification';

    public notification_type = null;

    searchCityInput = new Subject<string>();

    public NOTIFICATION_TYPE = NOTIFICATION_TYPE;

    public cities: string[] = [];

    public notificationGroup: FormGroup = new FormGroup({
        related_at: new FormControl('', [
            Validators.required,
        ]),
        title: new FormControl('', [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(255)
        ]),
        description: new FormControl('', [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5000)
        ]),
        related_type: new FormControl('', []),
        related_id: new FormControl('', []),
        invitation: new FormControl('', []),
        country_of_residence: new FormControl('', []),
        notifications_scheduler: new FormControl('', []),
        city: new FormControl('', [])
    });


    constructor(
      private notifications: NotificationsService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private snack: MatSnackBar,
      private self: MatDialogRef<NotificationModalComponent>
    ) {
      super();

      this.searchCityInput.pipe(
          debounceTime(400),
          distinctUntilChanged(),
          takeUntil(this.destroy)
      ).subscribe(value => {
              this.searchCity(value);
      });
    }

    ngOnInit() {
      if (this.data.full_name && this.data.lead_number && !this.data.notification && this.data.invitation) {
        this.notificationGroup.get('title').setValue(`Reminder: Payment schedule for Client ${this.data.full_name} [${this.data.lead_number}]`);
      }

      if (this.data.notification) {
        this.button = `Edit ${ this.NOTIFICATION_TYPE[this.data.notification.notification_type] }`;

        this.notification_type = this.data.notification.notification_type;
      } else {
        this.button = `Create ${ this.data.invitation ? 'schedule' : 'callback' }`;

        this.notification_type = this.data.invitation ? 1 : 3;
      }

      this.notificationGroup.get('related_at').patchValue(this.date);

      this.notificationGroup.get('related_at').valueChanges.pipe(
        takeUntil(this.destroy)
      ).subscribe(() => {
        this.notificationGroup.get('related_id').setErrors(null);
      });

      this.notificationGroup.get('invitation').valueChanges.pipe(
        takeUntil(this.destroy)
      ).subscribe(() => {
        this.notificationGroup.get('related_id').setErrors(null);
      });

      if (this.data.invitation) {
        this.notificationGroup.get('city').setValidators([Validators.required]);
      }

      if (this.data.notification) {
          this.notification = this.data.notification;

          for (const name in this.data.notification) {
              if (this.data.notification.hasOwnProperty(name) && this.notificationGroup.get(name)) {
                  this.notificationGroup.get(name).patchValue(this.data.notification[name]);
              }
          }
      }
    }

    ngOnDestroy() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

    private errorsHandling(error): void {
      if (error && Object.keys(error).length) {
        const errorsArr = [];

        for (const name in error) {
          if (this.notificationGroup.get(name)) {
            this.notificationGroup.get(name).setErrors({name: error[name]});
          } else {
            errorsArr.push(error[name]);
          }
        }

        if (errorsArr.length) {
          this.notificationGroup.setErrors({name: errorsArr.join(', ')});
        }

      } else {
        this.snack.open('Error! Something went wrong!', 'Ok!', {
            duration: 5000,
            panelClass: ['error-snackbar']
        });
      }
    }

    public addNotification() {
        if (this.notificationGroup.invalid) {
          this.notificationGroup.markAsTouched();

          return false;
        }

        this.loader = true;

        this.formData = {
            // related_at: Math.floor(this.notificationGroup.value.related_at.getTime() / 1000),
            related_at: Math.floor((this.notificationGroup.value.related_at).getTime() / 1000),
            title: this.notificationGroup.value.title,
            text: this.notificationGroup.value.description,
            related_type: this.data.type,
            related_id: this.data.id ? parseInt(this.data.id, 10) : null,
            country_of_residence: this.data.country_of_residence,
            notifications_scheduler: this.notificationGroup.value.notifications_scheduler,
            city: this.notificationGroup.value.city,
        };

        if (this.data.invitation) {
          this.formData['send_email'] = this.notificationGroup.value.invitation;
          this.formData['related_lead_number'] = this.data.lead_number;

          this.subscriber = this.notifications.setScheduleNutification(this.formData).subscribe((response) => {
            this.loader = false;

            this.notificationGroup.reset();

            this.snack.open('Schedule was successfully updated', 'Great!', {
              duration: 5000
            });

            this.self.close(response);
          }, (err) => {
            this.errorsHandling(err.error);

            this.loader = false;
          });
        } else if (!this.notification) {
            this.subscriber = this.notifications.addNotification(this.formData).subscribe((response) => {
                this.loader = false;

                this.notificationGroup.reset();

              this.snack.open('Callback was successfully created', 'Great!', {
                duration: 5000
              });

              this.self.close(response);
            }, (err) => {
              this.errorsHandling(err.error);

              this.loader = false;
            });
        } else {
            this.subscriber = this.notifications.editNotification(this.formData, this.notification.id).subscribe((response) => {
                this.loader = false;

                this.notificationGroup.reset();

                this.snack.open('Notification was successfully updated', 'Great!', {
                  duration: 5000
                });

                this.self.close(response);
            }, (err) => {
              this.errorsHandling(err.error);

              this.loader = false;
            });
        }

    }

    private getDate() {
      if (this.data.notification && this.data.notification.start) {
        return new Date(this.data.notification.start * 1000);
      } else {
        const date = new Date();
        date.setTime((new Date).getTime() + (2 * 60 * 1000));
        return date;
      }
    }

    public disableSave(): boolean {
      // if (this.canEdit()) {
      //   return (!this.notificationGroup.valid && this.error);
      // } else {
        return !this.notificationGroup.valid;
      // }
    }

    public searchCity(event) {
        const data = {
            country: this.data.country_of_residence,
            city: event,
        };

        this.notifications.searchCity(data).pipe(
          takeUntil(this.destroy)
        ).subscribe((response) => {
            this.cities = response;
        });
    }
}
