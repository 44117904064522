import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsSystemModalComponent } from './notifications-system-modal.component';
import { MatBadgeModule, MatButtonModule, MatIconModule, MatSnackBarModule } from '@angular/material';
import { NotificationsModule } from './notifications/notifications.module';


@NgModule({
  declarations: [
    NotificationsSystemModalComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatSnackBarModule,
    NotificationsModule,
  ],
  exports: [
    NotificationsSystemModalComponent,
  ]
})
export class NotificationsSystemModalModule {
}
