export const getErrorMsg = (err) => {
  if (typeof err === 'string') {
    return err;
  } else if (err.error) {
    if (typeof err.error === 'string') {
      return err.error;
    } else if (typeof err.error === 'object') {
      if (err.error.type === 'error') {
        return err.name;
      } else if (err.error.error) {
        return getErrorMsg(err.error);
      } else {
        const arr = [];
        for (const key in err.error) {
          arr.push(`${key.toUpperCase()}: ${err.error[key]}`);
        }
        return arr.join('; ');
      }
    } else {
      return 'Something went wrong!';
    }
  } else {
    return 'Something went wrong!';
  }
}
