import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckInModalComponent } from './check-in-modal.component';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { PieLoaderModule } from '../pie-loader/pie-loader.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        CheckInModalComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        PieLoaderModule,
        TranslateModule,
    ],
    exports: [
        CheckInModalComponent
    ],
    entryComponents: [
        CheckInModalComponent
    ]
})
export class CheckInModalModule {
}
