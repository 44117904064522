import {Component, Inject, OnInit} from '@angular/core';
import { CheckinService } from '../../../services/checkin/checkin.service';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-check-in-modal',
    templateUrl: './check-in-modal.component.html',
    styleUrls: ['./check-in-modal.component.scss']
})
export class CheckInModalComponent implements OnInit {

    public dialCode: string;

    public loader: any = true;

    public error: any = {
        status: false,
        text: '',
    };

    constructor(private checkInService: CheckinService,
        public self: MatDialogRef<CheckInModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
      console.log(this.data);
        this.checkInService.checkInSip({call_system_id: this.data.callSystemId}).subscribe((result) => {
            if (result.code) {
                this.loader = false;
                this.dialCode = result.code;
            }
        }, (error) => {
            if (error.error) {
                this.error.status = true;
                this.loader = false;
                for (const name in error.error) {
                    if (error.error.hasOwnProperty(name)) {
                        this.error.text = error.error[name];
                        break;
                    }
                }
            }
        });
    }
}
