import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LevelsService } from 'src/app/services/levels/levels.service';
import { UserService } from 'src/app/services/users/user.service';
import {Router} from '@angular/router';
import {PermissionsHelper} from '../../../../../helpers/Permission';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss']
})
export class HeaderUserComponent implements OnInit {
  @Input() user: any;

  public loading = true;
  public levelsData: any;
  public progress: number;

  public timezone: string;

  public showLevels: boolean;

  public avatar: string;

  constructor(
    private dialog: MatDialog,
    private levelsService: LevelsService,
    private userService: UserService,
    private router: Router,
    public permission: PermissionsHelper
  ) { }

  ngOnInit(): void {
    this.checkPointsBlock();
    if (this.showLevels) {
      this.getLevels();
    }
    this.avatar = this.userService.getAvatar(this.user.id); // crm_id
    this.setTimezone();
  }

  public getLevels() {
    this.levelsService.get(this.user.level_id).toPromise().then((res) => {
      this.levelsData = res;
      this.loading = false;
      const current_level = res.current_level;
      if (current_level) {
        const nextLevel = this.levelsData.levels.find(el => el.level_number > current_level.level_number);
        if (nextLevel) {
          const progress = (this.user.info_point.active_points - current_level.points) / (nextLevel.points - current_level.points) * 100;
          if (progress > 100) {
            this.progress = 100;
          } else if (progress < 0) {
            this.progress = 0;
          } else {
            this.progress = Math.round(progress);
          }
        } else {
          this.progress = 100;
        }
      }
    });
  }

  public checkPointsBlock() {
      this.showLevels = this.permission.boardSettings('rewards', 'view');
  }

  public congratsModal() {
    if (this.permission.roleGeneral('can_view_user_info')) {
      this.router.navigate([`/user/${this.user.hash}`]);
    }
  }

  private setTimezone(): void{
    const offset = new Date().getTimezoneOffset();
    const offsetAbs = Math.abs(offset);

    this.timezone = 'GMT' + (offset < 0 ? "+" : "-") + ("00" + Math.floor(offsetAbs / 60)).slice(-2) + ":" + ("00" + (offsetAbs % 60)).slice(-2);
  }
}
