import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators';
import localStore from 'store';
import md5 from 'md5';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  public defaultLayout = true;

  private subescribers: any = {}

  public constructor(
    private router: Router,
    private translate: TranslateService
  ) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((navEnd: NavigationEnd) => {
      this.defaultLayout = !(new RegExp(/^\/login/)).test(navEnd.url);

      setTimeout(() => {
        this.onRouteChange();
      }, 200)
    });
    this.identity();
    // this.initQuizzes();
  }

  ngOnInit(): void {
    const lang = localStore.get('lang');
    this.translate.use(lang || 'en');
    // this.initQuizzesCent();
  }

  ngOnDestroy(): void {
    for (const key in this.subescribers) {
      if (this.subescribers[key] && this.subescribers[key].unsubscribe) {
        this.subescribers[key].unsubscribe();
      }
    }
  }

  public identity() {
    const user = localStore.get('user');
    if (!user) { return; }
    window['cve']('identity', md5(user.crm_id))
  }

  public onRouteChange() {
    const user = localStore.get('user');
    let add: any = {};
    if (user) {
      add = {
        full_name: user.full_name,
        id: user.id,
        crm_id: user.crm_id
      };
    }
    window['cve']('track', 'crm', 'page_visit', add);
  }
}
