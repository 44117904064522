import {Action} from '@ngrx/store';

export enum PermissionsActionTypes {
    SET_PERMISSIONS = '[Permissions] SetPermissions'
}

export class SetPermissions implements Action {
    readonly type = PermissionsActionTypes.SET_PERMISSIONS;
    constructor(public payload: any) {}
}

export type PermissionsActionUnion = SetPermissions;
