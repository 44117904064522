import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import localStore from 'store';
import { CommissionsService } from 'src/app/services/commissions/commissions.service';
import { User } from 'src/app/services/users/user';
import SearchResponse from 'src/app/response/search.response';

@Component({
  selector: 'app-product-commission-modal',
  templateUrl: './product-commission-modal.component.html',
  styleUrls: ['./product-commission-modal.component.scss',]
})
export class ProductCommissionModalComponent implements OnInit, AfterViewInit {

  public loader: boolean = true;

  public info = {
    big_sale: '',
    per_product: '',
    refund: '',
    sale: '',
    sales_goal: '',
    monthly_group_goal: '',
  }

  public currencyR = User.CURRENSY;

  public salesTypesLabels = {
    partial: 'Partial Payment',
    full: 'Full Payment',
    'partial-completed': 'Complete Payment'
  };

  private user;

  public commission: number;

  public chartInited = false;

  public activityTable: SearchResponse = new SearchResponse({
    page_size: 10
  });

  public activityTableColumns: string[] = ['created_at', 'product_name', 'visa_type', 'payment_type', 'reward', 'refund_type'];

  public productTargets: any[] = [];

  public productTargetsColumns: string[] = ['sales_type', 'to_amount', 'reward'];

  public am4Core: any = (window as any).am4core;
  
  public am4Charts: any = (window as any).am4charts;

  public am4ThemesAnimated: any = (window as any).am4themes_animated;
   
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<ProductCommissionModalComponent>,
    public commissionsService: CommissionsService
  ) {
    this.commission = this.data.commission;
    this.self.addPanelClass('product-commission-modal-panel');
  }

  ngOnInit(): void {
    this.user = localStore.get('user');
    this.getActivityList();
  }

  ngAfterViewInit(): void {
    this.initDonutChart();
  }

  public refundTypeTooltip (element: any): string {
    let type = '';
    let accrued = '';

    switch (element.refund_type) {
      case 'Partial Refund':
      case 'Full Refund':
        type = 'Refund';
        accrued = 'refund';
        break;

      case 'Chargeback':
        type = 'Chargeback';
        accrued = 'chargeback';
        break;

      // Sale
      case null:
        type = 'Sale';
        accrued = 'selling';
        break;

      default:
        type = element.refund_type;
        accrued = element.refund_type;
    }

    return `It is a ${type}! This commission is accrued after ${accrued} ${element.product_name || ''} for ${element.lead_number || 'client'}.`;
  }

  private getActivityList() {
    this.loader = true;
    this.commissionsService.getAchievedProductCommission(
      this.user.id,
      this.user.role,
      1,
      this.activityTable.getReqData(),
    ).toPromise().then((res) => {
      if (res) {;
        this.activityTable = Object.assign(this.activityTable, res);
      }
      this.loader = false;
    }, (err) => {
      this.loader = false;
    });
  }



  private getRulesList() {
    this.loader = true;
    this.commissionsService.getAchievedProductCommission(
      this.user.id,
      this.user.role,
      0,
      {}
    ).toPromise().then((res) => {
      if (res && res.data) {
        const products = [];
        res.data.forEach((product) => {
          const productVisasObj = {};
          product.products.forEach((visa) => {
            const visa_name = (visa.visa_program_type || []).join(', \n');
            if (!productVisasObj[visa_name]) {;
              productVisasObj[visa_name] = [];
            }
            productVisasObj[visa_name].push({
              sales_type: visa.sales_type,
              to_amount: visa.to_amount,
              reward: visa.reward,
            });
          });
          const productVisas = [];
          for (const key in productVisasObj) {
            productVisas.push({
              visa_program_type: key,
              conditions: productVisasObj[key]
            })
          }
          products.push({
            product_name: product.product_name,
            products: productVisas
          })
        });
        this.productTargets = products;
      }
      this.loader = false;
    }, (err) => {
      this.loader = false;
    });
  }

  public initDonutChart() {
    if (!document.getElementById('pcm-donut')) { return; }
    // Themes begin
    this.am4Core.useTheme(this.am4ThemesAnimated);
    this.am4Core.addLicense("ch-custom-attribution");
    // Themes end
    // Create chart instance
    let chart = this.am4Core.create("pcm-donut", this.am4Charts.PieChart);

    // Add data
    chart.data = [ {
      "Type": "Achieved Product Commissions",
      "Points": Math.ceil(this.commission || 0),
      "color": this.am4Core.color("#009A15")
    }];

    // Set inner radius
    chart.innerRadius = this.am4Core.percent(50);

    // Add and configure Series
    let pieSeries = chart.series.push(new this.am4Charts.PieSeries());
    pieSeries.dataFields.value = "Points";
    pieSeries.dataFields.category = "Type";
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.stroke = this.am4Core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    this.chartInited = true;
  }

  public changePage(event: any, type: string): void {
    this[type].page = event.pageIndex;
    this[type].page_size = event.pageSize;
    if (type === 'activityTable') {
      this.getActivityList();
    } else if (type === 'productTargets') {
      this.getRulesList();
    }
  }

  public onSelectedTabChange(evt) {
    if (evt.index === 0) {
      if (!this.chartInited) {
        this.initDonutChart()
      }
    } else if (evt.index === 1) {
      if (!(this.productTargets && this.productTargets.length)) {
        this.getRulesList();
      }
    }
  }
}
