import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleNotificationComponent } from './schedule-notification.component';
import {
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTooltipModule
} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import { CustomNotificationComponent } from './custom-notification/custom-notification.component';
import { SelectFieldComponent } from './select-field/select-field.component';

@NgModule({
  declarations: [
    ScheduleNotificationComponent,
    CustomNotificationComponent,
    SelectFieldComponent
  ],
  exports: [
    ScheduleNotificationComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatDialogModule
  ],
  entryComponents: [
    CustomNotificationComponent
  ]
})
export class ScheduleNotificationModule { }
