import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import SearchResponse from '../../response/search.response';
import {LiveService} from '../live/live.service';

function generatePassword() {
  return Math.random().toString(36).slice(-12).replace('.', '4');
}

@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  constructor(private api: ApiService,
        private live: LiveService) {
  }

  public getLeadsList(params): Observable<any> {
    if (params instanceof SearchResponse) {
      params = params.getReqData();
    }
    return this.api.get('/leads', {
      params: params
    });
  }

  public getAllLeads(params): Observable<any> {
    if (params instanceof SearchResponse) {
      params = params.getReqData();
    }
    return this.api.get('/leads/tech', {
      params: params
    });
  }

  public actionQualify(id, data): Observable<any> {
    return this.api.post('/leads/' + id + '/qualify', {
      body: data
    });
  }

  public actionDisqualify(id, data): Observable<any> {
    return this.api.post('/leads/' + id + '/disqualify', {
      body: data
    });
  }

  public disqualifyLead(data): Observable<any> {
    return this.api.post(`/client_action/disqualified`, { body: data })
  }

  public getProfile(id, type): Observable<any> {
    return this.api.get('/' + type + '/' + id + '/lead', {});
  }

  public getPaymentLink(id, data) {
    return this.api.post('/leads/' + id + '/payment/link', {
      body: data
    });
  }

  public incoming(): Observable<any> {
    return this.live.subscribe('leads');
  }

  public paymentHistory(id, params): Observable<any> {
    return this.api.get('/payment/' + id, {
      params: params
    });
  }

  public terminateCall(id): Observable<any> {
    return this.api.get(`/call-actions/terminate/${id}`, {
      params: {}
    });
  }
  public searchBin(bin, limit): Observable<any> {
    return this.api.get(`/leads/verify_payment_bin/${limit}/${bin}`, {});
  }

  public create(data, params = {}): Observable<any> {
    const body:any =  {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone.toString(),
      email: data.email,
      country_of_residence: data.country_of_residence,
      country_of_birth: data.country_of_birth,
      assigned_to: data.assigned_to,
      product: data.product,
      status_reason: data.status_reason,
      birthday: data.birthday.toString(),
      payment_template: data.payment_template,
      quote: data.quote,
      password: data.password.toString(),
      secondary_email: data.secondary_email,
      campaign_source: data.campaign_source,
      age: data.age,
      keyword: data.keyword,
      is_fake: data.is_fake
    };

    if(data.user_print) body.user_print = data.user_print;

    return this.createLead(
      body,
      params
    );
  }

  public createLead(body, params): Observable<any> {
    return this.api.post('/leads', { body, params });
  }

  public createFake(data): Observable<any> {
    return this.api.post('/leads', {
      body: {
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone.toString() || '0',
        email: data.email,
        country_of_residence: data.country_of_residence,
        country_of_birth: data.country_of_birth,
        birthday: data.birthday.toString(),
        // payment_template: data.payment_template,
        // quote: data.quote,
        // age: data.age,
        // keyword: data.keyword,
        is_fake: data.is_fake,

        assigned_to: 1,
        // product: data.product,
        status_reason: 'Unprocessed',
        password: generatePassword(),
        // secondary_email: data.secondary_email,
        campaign_source: 'Fake Lead',
      }
    });
  }

  public deleteLead(ids): Observable<any> {
    // console.log(ids);
    return this.api.post('/leads/delete', {
      body: {
        lead_ids: ids
      }
    });
  }

  public setPaymentBinLead(id, bin): Observable<any> {
    return this.api.put(`/leads/set_payment_bin/${id}`, {
      body: {
        payment_bin: bin
      }
    });
  }

  public downloadLead(params, ids = [], type = {result: 0, password: 0}): Observable<any> {
    if (params instanceof SearchResponse) {
      params = params.getReqData();
    }
    params = Object.assign(params, {password: type.password});
    if (ids.length > 0 &&  type.result === -1) {
      return this.api.get('/leads?excel=all&excel_users=' + ids, {
        params: params,
        responseType: 'text'
      });
    } else if (type.result === 1) {
      return this.api.get('/leads?excel=all', {
        params: params,
        responseType: 'text'
      });
    } else if (type.result === 2) {
      return this.api.get('/leads?excel=' + params.page_size, {
        params: params,
        responseType: 'text'
      });
    }
  }

  public connectWithLead(params): Observable<any> {
    return this.api.post('/leads/connect', { body: params });
  }

}
