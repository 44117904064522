import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Notification, NotificationModel} from './notification.interface';
import {EventBusService} from '../../services/event-bus/event-bus.service';
import _findLastIndex from 'lodash/findLastIndex';

const NotificationActions = {
  create: 'create',
  update: 'update',
  delete: 'delete'
}

@Component({
  selector: 'app-push-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public audioSound = null;

  readonly CLOSE_TIME = 15000;

  public collapsed = true;

  public notifications: Notification[] = [];

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private eventBus: EventBusService,
  ) { }

  ngOnInit() {
    this.audioSound = new Audio('../../../assets/audio/notification.mp3');
    this.audioSound.loop = false;
    this.audioSound.volume = 1;

    this.eventBus.on('push-notification').subscribe( (data: NotificationModel) => {
      this.ngZone.run(() => {
        if (data.action === NotificationActions.update) {
          const index = this.notifications.findIndex(notification => notification.id === data.notification.id);

          if (index !== -1 && this.notifications[index]) {
            this.notifications[index] = Object.assign(this.notifications[index], data.notification);
          }
        } else if (data.action === NotificationActions.delete) {
          this.notifications = this.notifications.filter(notification => notification.id !== data.notification.id);
        } else {
          if (!this.notifications.length) {
            this.collapsed = true;
          }

          this.setNotification(data.notification);

          this.ringtone(true);
        }
      });

      setTimeout(() => {
        this.closeNotification(data.notification);
      }, this.CLOSE_TIME);
    });
  }

  private setNotification(notification): void {
    if (notification.type !== 'call') {
      const last = _findLastIndex(this.notifications, ['type', 'call']);
      if (last !== -1) {
        this.notifications.splice(last + 1, 0, notification);

        return;
      }
    }

    this.notifications.unshift(notification);
  }

  public showAll(): void {
    if (this.collapsed) {
      this.collapsed = false;
    }
  }

  private closeNotification(notification: Notification): void {
    if (notification.onClose) {
      notification.onClose(notification);
    }

    this.notifications = this.notifications.filter(i => i.id !== notification.id);
  }

  public close(notification: Notification): void {
    this.closeNotification(notification);
  }

  public open(notification: Notification): void {
    if (notification.onOpen) {
      notification.onOpen(notification);
    }

    if (notification.settings.closeOnOpen) {
      this.closeNotification(notification);
    }
  }

  private ringtone(__bool) {
    if (!this.audioSound) {
      return;
    }

    if (__bool) {
      const playPromise = this.audioSound.play();
      if (typeof playPromise !== 'undefined') {
        playPromise.then(() => {
          this.audioSound.play();
        }).catch(error => {
          // console.log('error', error);
        });
      }
    } else {
      if (this.audioSound) {
        this.audioSound.pause();
        this.audioSound.currentTime = 0;
      }
    }
  }
}
