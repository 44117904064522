export default class SearchResponse {

    public info: any = null;

    public data: Array<any> | any = [];

    public rows = 0;

    public page_size = 100;

    public page_count = 0;

    public page = 0;

    public additional: any;

    public sort: any = {
        'id': 'desc'
    };

    public filter: any = {};

    constructor(data?: any) {
        for (const name in data) {
            if (data.hasOwnProperty(name) && this[name] !== undefined) {
                this[name] = data[name];
            }
        }
    }

    public getInfo(name: string): any {
        if (this.info && this.info[name]) {
            return this.info[name];
        }
        return {};
    }

    public hasType(name: string, types: Array<string>): any {

        const info = this.getInfo(name);

        if (Object.keys(info).length <= 0) {
            return false;
        }

        for (const type of types) {
            if (info.type.includes(type)) {
                return true;
            }
        }

        return false;
    }

    public getReqData(): any {
        return {
            page: this.page,
            page_size: this.page_size,
            sort: this.encodeObj(this.sort),
            filter: this.encodeObj(this.filter)
        };
    }
    public getCustomReqData(): any {
      return {
        page: this.page,
        page_size: this.page_size,
        sort: this.encodeObj(this.sort),
        filter: this.encodeCustomObj(this.filter)
      };
    }

    public addSort(name, value) {
        this.clearEntity('sort');
        this.addEntity(name, value, 'sort');
    }

    public addFilter(name, value) {
        this.addEntity(name, value, 'filter');
    }

    public removeFilter(name) {
        this.addEntity(name, '', 'filter');
    }

    public addFilters(event) {
        this.clearFilter();
        for (const name in event) {
            if (event.hasOwnProperty(name)) {
                this.addFilter(name, event[name]);
            }
        }
    }

    public clearSort() {
        this.clearEntity('sort');
    }

    public clearFilter() {
        this.clearEntity('filter');
    }

    public clear() {
        this.page = 0;
        this.clearFilter();
        this.clearSort();
    }

    public changeEntity(id, data) {
        for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].id === id) {
                const entity = this.data[i];
                for (const name in data) {
                    if (data.hasOwnProperty(name)) {
                        entity[name] = data[name];
                    }
                }
                this.data[i] = entity;
            }
        }
    }

    private encodeObj(obj): string {
        if (!obj || Object.keys(obj).length <= 0) {
            return '';
        }
        return Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
    }
    private encodeCustomObj(obj): string {
      if (!obj || Object.keys(obj).length <= 0) {
        return '';
      }

      // return Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
      const req =  Object.keys(obj).map(k => {
        if (typeof obj[k] === 'object') {
          return `${encodeURIComponent(k)}=${JSON.stringify(obj[k])}`;
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
      }).join('&');
      return encodeURI(req);
    }



    private addEntity(name: string, value: string | number, entity: string): void {

        const entityRef = this[entity];

        if (!entityRef) {
            return;
        }

        entityRef[name] = value;
    }

    private clearEntity(entity: string) {
        this[entity] = {};
    }

    public headerPosition(): void {
        if (this.data.length === 0) {
            const emptyEl = document.querySelector('.ngx-datatable.scroll-vertical .datatable-body');
            if (emptyEl) {
                setTimeout(() => {
                    (<any>emptyEl).scrollLeft = 1;
                }, 0);
            }
        }
    }
}
