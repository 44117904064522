import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ViewRewardsModalComponent} from '../view-rewards-modal/view-rewards-modal.component';
import {PointsService} from '../../../services/points/points.service';

@Component({
    selector: 'app-quick-points-modal',
    templateUrl: './quick-points-modal.component.html',
    styleUrls: ['./quick-points-modal.component.scss']
})
export class QuickPointsModalComponent implements OnInit {

    public subscriber: any;

    public points: any = [];

    public loader: any = true;


    public tooltips: any = {
        attempt: 'Call attempts > 210 = 10p',
        effective: '(Effective calls/Answered calls) >= 40% = 20p',
        effective_vs_swipe: '(Number of swiped leads/Effective calls) > 10% = 10p; (Number of swiped leads/Effective calls) > 12% = 25p; (Number of swiped leads/Effective calls) > 15% = 40p',
        sale: 'Sales amount 0 > 500$ = 10p,\n 500$ > 1000$ = 25p,\n 1000$ > 2000$ = 40p,\n > 2000$ = 60p daily',
        effective_amount: 'Effective calls >= 13 = 10p',
        big_sale: 'One sale more than 800$ = 40p',
        login: 'Daily login gives you 5p',
        productivity: 'Talk time + dial 70% > 75% = 5p; Talk time + dial 75% > 80% = 10p; Talk time + dial > 80% = 15p',
        effective_vs_sales: '',
        timer: 'Idle time > 15sec = -1p; Idle time > 30 sec = -2p; Idle time > 45 = -3p; Idle time > 60 = -4p p; etc...',
    };


    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private self: MatDialogRef<QuickPointsModalComponent>,
                private matDialog: MatDialog,
                private pointsService: PointsService) {
    }

    ngOnInit() {
        this.subscriber = this.pointsService.get({}).subscribe((res) => {
            this.points = res;
            this.loader = false;

            this.points.kpi.sort((a, b) => {

                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }

                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }

                return 0;
            });
        });
    }

    public openRevards() {
        this.self.close(true);
        this.matDialog.open(ViewRewardsModalComponent, {
            width: '800px',
            height: '500px',
            panelClass: 'reward-info',
            hasBackdrop: true,
            data: ''
        });
    }

}
