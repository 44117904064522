import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Notification} from '../notification.interface';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public isActive = false;

  value = 60;
  seconds = '--';
  minutes = '--';
  hours = '--';
  startPointCounter = 100000000;
  interval = 1000;

  @Input() set settings(val) {
    if (val) {
      Object.keys(val).forEach(key => {
        if ((this as object).hasOwnProperty(key)) {
          this[key] = val[key];
        }
      });
    }
  };

  public btnName: string = 'Open';
  public timer: boolean = false;

  @Input() notification: Notification = null;

  @Output() close: EventEmitter<Notification> = new EventEmitter();
  @Output() open: EventEmitter<Notification> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.isActive = true;
    }, 100);
  }

  public closeHandler(e, notification: Notification): void {
    e.stopPropagation();

    this.close.emit(notification);
  }

  public openHandler(e, notification: Notification): void {
    e.stopPropagation();

    this.open.emit(notification);
  }

}
