import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductCommissionModalComponent } from '../product-commission-modal/product-commission-modal.component';
import _get from 'lodash/get';
import localStore from 'store';

@Component({
  selector: 'app-product-commission',
  templateUrl: './product-commission.component.html',
  styleUrls: ['./product-commission.component.scss',]
})
export class ProductCommissionComponent implements OnInit, OnDestroy {

  public commission: number;

  private bonusStageRoles = ['agent', 'conversion_interview'];

  private role = _get(localStore.get('user'), 'role', null);

  @Input() set setData(data) {
    this.isBonusStage = _get(data, 'individual_goal.status', false) && _get(data, 'individual_goal.details.reached', false) && this.bonusStageRoles.includes(this.role);

    if (data.product_target_reward) {
      this.commission = (_get(data, 'product_target_reward.commission', 0) || 0) * 10.6;
    }
  }

  public isBonusStage = false;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }

  public openModal(): void {
    this.dialog.open(ProductCommissionModalComponent, {
      data: {
        commission: this.commission,
        isBonusStage: this.isBonusStage
      }
    });
  }
}
