import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutocompleteComponent} from './autocomplete.component';
import {
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatChipsModule,
  MatRippleModule,
  MatTooltipModule
} from '@angular/material';
import {MultipleSelectorModule} from '../multiple-selector/multiple-selector.module';
import {CustomTranslatePipeModule} from '../../../pipes/customTranslate/customTranslate.pipe.module';

@NgModule({
  declarations: [
    AutocompleteComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatTooltipModule,
    MatChipsModule,
    MatRippleModule,
    MultipleSelectorModule,
    CustomTranslatePipeModule,
  ],
  exports: [
    AutocompleteComponent
  ],
  entryComponents: [],
})
export class AutocompleteModule {
}
