import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-small-loader',
    templateUrl: './small-loader.component.html',
    styleUrls: ['./small-loader.component.scss']
})
export class SmallLoaderComponent implements OnInit {

    @Input() public show: boolean;

    @Input() public fixed: boolean = false;

    constructor() {
    }

    ngOnInit() {
    }

}
