import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {LeadsService} from '../../../services/leads/leads.service';
import {ConfirmModalComponent} from '../../general/confirm-modal/confirm-modal.component';
import _get from 'lodash/get';
import {Router} from '@angular/router';
import {EventBusService} from '../../../services/event-bus/event-bus.service';
import localStore from 'store';

interface Opportunities {
  error: string;
  opportunities: {
    product: string;
    paid_amount: number;
    created_at: number;
  }[];
}

@Component({
  selector: 'app-register-lead-modal',
  templateUrl: './register-lead-modal.component.html',
  styleUrls: ['./register-lead-modal.component.scss']
})
export class RegisterLeadModalComponent implements OnInit {

  private validationError = {
    '-99': 'Phone number is invalid',
    0: 'Phone number is invalid',
    1: 'Invalid country code',
    2: 'Too short',
    3: 'Too long',
    4: 'Is possible local only',
    5: 'Invalid length'
  };

  public telOptions = {
    separateDialCode: true,
    placeholder: 'Phone Number *',
    initialCountry: 'gb',
  };

  public telInput = null;

  public loader: boolean = false;

  public opportunities: Opportunities = null;
  // {
  //   error: 'the lead is already exists, is already qualified and can\'t be reassigned',
  //   opportunities: [
  //     {
  //       product: 'IELTS',
  //       paid_amount: 29,
  //       created_at: 170423532554
  //     }
  //   ]
  // };

  public createForm: FormGroup = new FormGroup({
    first_name: new FormControl('', [
      Validators.required,
      Validators.min(3)
    ]),
    last_name: new FormControl('', []),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.min(3)
    ])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<RegisterLeadModalComponent>,
    private eventBus: EventBusService,
    private leadsService: LeadsService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.createForm.patchValue({
        first_name: this.data.first_name,
        last_name: this.data.last_name,
        email: this.data.lead_email,
        phone: this.data.lead_phone,
      });
    }
  }

  public hasError(status): void {
    if (!status) {
      const errorCode = this.telInput.getValidationError();
      const phone = this.createForm.get('phone').value

      if (phone !== '') {
        this.createForm.get('phone').setErrors({ 'name': this.validationError[errorCode] });
      }
    }
  }

  public telInputObject(obg): void {
    this.telInput = obg;
  }

  public submit(): void {
    if (this.createForm.invalid) {
      return;
    }

    this.matDialog.open(ConfirmModalComponent, {
      width: '500px',
      hasBackdrop: true,
      data: {
        title: 'Create New Lead',
        message: 'Are you really want create ' + this.createForm.value.first_name + ' ?'
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.create();
      }
    });
  }

  public create(): void {
    this.loader = true;

    const phone = this.telInput.getSelectedCountryData();
    const formData = this.createForm.getRawValue();

    formData.phone = '+' + phone.dialCode + formData.phone;

    this.leadsService.createLead(
      formData,
      {
        inbound: true
      }
    ).pipe(
      finalize(() => this.loader = false)
    ).subscribe((res) => {
      const message = res.was_created ? 'Lead was successfully created!' : 'Lead with this email or phone number already exists!';

      this.snackBar.open(message, 'Great!', {
        duration: 5000,
      });

      this.eventBus.emit('call-data', res);


      const call_data = localStore.get('call_data');
      if (call_data) {
        call_data.first_name =  res.first_name;
        call_data.last_name =  res.last_name;
        call_data.lead_phone =  res.phone;
        call_data.lead_number =  res.lead_number;
        call_data.lead_email =  res.email;
        call_data.lead_id =  res.id;
        call_data.lead_crm_id =  res.crm_id;

        localStore.set('call_data', call_data);
      }

      if (res.id) {
        this.router.navigate([`/details/leads/${res.id}`]);
      }

      // document.dispatchEvent(new CustomEvent('newLead',
      //   {
      //     detail: res,
      //   },
      // ));

      this.self.close(res);
    }, (err) => {
      if (err.error) {

        if (err.error.opportunities) {
          this.opportunities = err.error;
        }

        for (const name in err.error) {
          if (err.error.hasOwnProperty(name)) {
            if (this.createForm.get(name)) {
              this.createForm.get(name).setErrors({
                name: err.error[name]
              });
            }
          }
        }

        if (err.error.error) {
          let errorMessage = _get(err, ['error', 'error'], 'Something Went Wrong');

          if (String(errorMessage).includes('Email is invalid')) {
            errorMessage = 'Email is invalid';
          }

          if (String(errorMessage).includes('This email is already in use')) {
            errorMessage = 'This email is already in use';
          }

          this.snackBar.open(errorMessage, 'Dismiss!', {
            duration: 5000,
          });
        }
      }
    });
  }

}
