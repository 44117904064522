export const enum PERFORMANCE_STATUS_ENUM {
  NO = 1,
  IN_PROGRESS = 2,
  TRIAL = 3
};

export const PERFORMANCE_STATUS = {
  NO: 1,
  IN_PROGRESS: 2,
  TRIAL: 3
};
