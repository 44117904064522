import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentInfoComponent} from './agent-info.component';

@NgModule({
    declarations: [AgentInfoComponent],
    imports: [
        CommonModule
    ],
    exports: [
        AgentInfoComponent
    ]
})
export class AgentInfoModule {
}
