import {ValidatorFn, FormArray} from '@angular/forms';
import _uniqWith from 'lodash/uniqWith';

export function uniqueValues(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    const values = formArray.controls.map(control => control.value);
    const uniqueItems = _uniqWith(values, (d, v) => d.count === v.count && d.type === v.type);

    if (values.length !== uniqueItems.length) {
      return { 'nonUniqueValues': true };
    }

    return null;
  };
}
