import {Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';
import {UserService} from '../../../services/users/user.service';
import _groupBy from "lodash/groupBy";
import _union from "lodash/union";
import _find from "lodash/find";

@Component({
    selector: 'app-agents-sheet2',
    templateUrl: './agents-sheet.component.html',
    styleUrls: ['./agents-sheet.component.scss']
})
export class NewAgentsSheetComponent implements OnInit, OnChanges {

    public agents: any = [];

    public selected: any;

    public multiple: any = false;

    public newSelected: any = null;

    public filtered: any;

    public all: any = false;

    constructor(
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private userService: UserService,
      private self: MatBottomSheetRef<NewAgentsSheetComponent>
    ) {
        this.agents = this.data.agents;
        this.selected = this.data.selected;
        this.multiple = this.data.multiple;
        // this.filtered = Object.assign([], this.agents);
        const agents = Object.assign([], this.agents);
        this.filtered = this.sortByRole(agents);
        this.self.backdropClick().subscribe(() => {
            if (!this.multiple) {
                this.self.dismiss({
                    selected: this.selected,
                    all: this.all
                });
            } else {
                this.self.dismiss({
                    selected: this.selected,
                    all: this.all
                });
            }
        });
    }

    ngOnInit() {
        if (this.data.all) {
            this.all = true;
            this.selectAll();
        }
        this.agents.map((agent, index) => {
            agent['is_hidden'] = false;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    public selectAgent(agent): void {

        if (this.multiple) {
            const index = this.getIndex(agent);

            if (index === -1) {
                this.selected.push(agent);
            } else {
                this.selected.splice(index, 1);
            }
        } else {
            this.newSelected = agent;
            this.selected = [agent];

            this.self.dismiss({
              selected: this.selected ,
              all: this.all
            });
        }

        this.selected = [...this.selected];

        if (this.selected.length !== this.agents.length) {
            this.all = false;
        } else {
            this.all = true;
        }
    }

    public getIsActive(agent): any {
        if (this.multiple) {
            return this.getIndex(agent) >= 0;
        }
        return this.selected ? this.selected.some(i => this.getId(i.id) === this.getId(agent.id)) : false;
    }

    public sortAgents(): void {
      const selected = this.multiple ? this.selected.map(agent => agent.id) : [this.selected.id];
      if (selected.length > 0) {
        Object.keys(this.filtered).forEach(item => {
          this.filtered[item] = this.filtered[item].filter(agent => {
            return !_find(this.selected, { id: agent.id });
          });
        });
        this.filtered['[aaa]current selected agent'] = [...this.selected];
      } else {
        delete this.filtered['current selected agent'];
      }
    }

    public searchInput(event): void {
        const filtered = this.agents.filter((agent, index) => {
            return agent.full_name.toLowerCase().includes(event.target.value.toLowerCase());
        });
        // this.filtered = Object.assign([], filtered);

        this.filtered = this.sortByRole(filtered);
    }

    public clearAgents() {
        this.selected = this.multiple ? [] : null;
        this.selected = [...this.selected];
    }

    private getIndex(agent) {
        return this.selected.findIndex(x => {
            return x.id === agent.id;
        });
    }

    private getId(id: any) {
        if (typeof id !== 'number') {
            return parseInt(id, 10);
        }
        return id;
    }

    public selectAll() {
        this.selected = Object.assign([], this.agents);
        this.all = true;
    }

    public getAgentAvatar(crm_id): string {
        return this.userService.getAvatar(crm_id);
    }




  private allSelected = {};

    public sortByRole(agents): any {
      return _groupBy(agents, 'role');
    }

    selectAllAgent(allRollAgents, role): void {
      if (!this.allSelected[role]) {
        this.allSelected[role] = true;

        this.selected = _union(this.selected, allRollAgents);
      } else {
        delete this.allSelected[role];
        allRollAgents.map(item => {
          const index = this.getIndex(item);
          this.selected.splice(index, 1);
        });
      }
    }

    getRoleStatus(role): boolean {
      return this.allSelected[role] || false;
    }

  public getName(name: string): string {
    return name.replace(/[-\._]/g, ' ').replace('[aaa]', '');
  }
}
