import {Action} from '@ngrx/store';

export enum PaymentNotificationActionsType {
    TOGGLE_PAYMENT_NOTIFICATIONS = '[PaymentNotification] togglePaymentNotifications',
    REMOVE_BUBBLE_MESSAGE = '[PaymentNotification] removeBubbleMessage',
    SET_BUBBLE_MESSAGE = '[PaymentNotification] setBubbleMessage',
    SET_NOT_VIEWED_MESSAGE = '[PaymentNotification] setNotViewedMessage',
    SET_PAYMENT_NOTIFICATION_HISTORY = '[PaymentNotification] setPaymentNotificationHistory',

}

export class TogglePaymentNotifications implements Action {
    readonly type = PaymentNotificationActionsType.TOGGLE_PAYMENT_NOTIFICATIONS;
    constructor(public payload?: any) {}
}
export class SetBubbleMessage implements Action {
    readonly type = PaymentNotificationActionsType.SET_BUBBLE_MESSAGE;
    constructor(public payload: any) {}
}

export class RemoveBubbleMessage implements Action {
    readonly type = PaymentNotificationActionsType.REMOVE_BUBBLE_MESSAGE;
    constructor(public payload: any) {}
}

export class SetNotViewedNotifications implements Action {
    readonly type = PaymentNotificationActionsType.SET_NOT_VIEWED_MESSAGE;
    constructor(public payload: any) {}
}

export class SetPaymentNotificationsHistory implements Action {
    readonly type = PaymentNotificationActionsType.SET_PAYMENT_NOTIFICATION_HISTORY;
    constructor(public payload: any) {}
}

export type PaymentNotificationActionsUnion = TogglePaymentNotifications | SetBubbleMessage | RemoveBubbleMessage | SetNotViewedNotifications | SetPaymentNotificationsHistory;
