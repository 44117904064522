import {Action} from '@ngrx/store';

export enum FieldsActionTypes {
    SET_FIELDS = '[Fields] SetFields',
}

export class SetFields implements Action {
    readonly type = FieldsActionTypes.SET_FIELDS;
    constructor(public payload: any) {}
}

export type FieldsActionUnion = SetFields;
