import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangeTimeInputComponent } from './range-time-input.component';
import {MatFormFieldModule, MatInputModule} from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import {OwlDateTimeModule} from 'ng-pick-datetime';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [RangeTimeInputComponent],
  exports: [
    RangeTimeInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    OwlDateTimeModule,
    MatInputModule
  ]
})
export class RangeTimeInputModule { }
