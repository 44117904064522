import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Rx';

@Injectable({
    providedIn: 'root'
})
export class PauseService {

    constructor(private api: ApiService) {
    }

    public get(params): Observable<any> {
        return this.api.get('/pauses', {
            params: params
        });
    }

    public setPause(id, data): Observable<any> {
        return this.api.post('/pauses/' + id, {
            body: data
        });
    }
}
