import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstallmentService {

  constructor(
    private api: ApiService
  ) { }

  public getInstallments(params?): Observable<any> {
    return this.api.get(`/instalment/not_set/applications`, { params });
  }

  public updateInstallment(body?): Observable<any> {
    return this.api.post(`/instalment/update`, { body });
  }

}
