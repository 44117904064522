import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RichTextEditorComponent} from '@syncfusion/ej2-angular-richtexteditor';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-notification-popup-modal',
  templateUrl: './notification-popup-modal.component.html',
  styleUrls: ['./notification-popup-modal.component.scss']
})
export class NotificationPopupModalComponent implements OnInit {

  constructor(
    private self: MatDialogRef<NotificationPopupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    self.disableClose = true;
    // config.showNavigationIndicators = true;
    // config.interval = 10000000;
  }

  @ViewChild('toolsRTE')
  public rteObj: RichTextEditorComponent;

  public transformedTemplate: any;

  public messages: any;

  public message: any;

  public initialMessagesLength: number;

  ngOnInit() {

    if (typeof this.data === 'object' || Array.isArray(this.data)) {
      this.messages = this.data;

      for (let i = 0; i < this.data.length; i++) {
        this.data[i].transformed_template = this.sanitizer.bypassSecurityTrustHtml(this.data[i].message);
      }
      this.messages = this.data;
    } else {
      this.data.transformed_template = this.sanitizer.bypassSecurityTrustHtml(this.data.message);
      this.messages.push(this.data);
    }

    if (this.messages.length > 0) {
      this.message = this.messages[this.messages.length - 1];
    }

    this.initialMessagesLength = this.messages.length;
  }

  public confirm() {
    this.self.close(true);
  }

  public next() {

    this.messages.pop();

    if (this.messages.length > 0) {
      this.message = this.messages[this.messages.length - 1];
    } else {
      this.self.close(true);
    }
  }

}
