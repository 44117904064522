import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/services/users/user';

@Component({
  selector: 'app-commission-period',
  templateUrl: './commission-period.component.html',
  styleUrls: ['../commissions.scss',]
})
export class CommissionPeriodComponent implements OnInit {
  @Input() data: any;
  @Input() shift_target_type: number;
  @Input() shift_main_target: number;

  public percentage = 0;
  public progress = 456;
  public totalReward = 0;
  public currentTargetIndex = 0;

  public for_each: boolean;

  public type: string;
  public targets: any[];

  public reached_by_user: number;
  public reached_by_user_usd: number;

  public targetsShow: any[];

  ngOnInit(): void {
    if (!this.data) { return; }
    const reward_settings = this.data.reward_settings;
    this.targets = reward_settings.details.targets
    this.type = this.data.type;
    this.reached_by_user = this.data.reached_by_user;

    if (typeof reward_settings.details.for_each === 'boolean') {
      this.for_each = reward_settings.details.for_each && reward_settings.details.for_each_value;
    } else {
      this.for_each = reward_settings.details.for_each_value;
    }

    this.targetsShow = this.targets.map((el, index) => {
      return {
        target: el.target,
        reward: el.reward,
        status: this.getStatus(el, index),
      }
    });
    if (this.currentTargetIndex === this.targets.length - 1) {
      this.setProgress(100);
    }
  }

  public getStatus(el: any, index: number): string {
    const prevTarget = index - 1 >= 0 ? this.targets[index - 1].target : 0;
    let val = this.reached_by_user;
    if (this.shift_main_target) {
      val = val / this.shift_main_target * 100;
    }
    if (val >= el.target) {
      this.totalReward = el.reward || 0;
      this.currentTargetIndex = index;
      return 'achieved';
    } else {
      if (val >= prevTarget) {
        this.calcProgress(el, prevTarget);
        return 'active'
      } else {
        return 'next';
      }
    }
  }

  public calcProgress(el: any, prevTarget: number): void {
    let reached_by_user = this.reached_by_user || 0;
    if (this.shift_main_target) {
      reached_by_user = reached_by_user / this.shift_main_target * 100;
    }
    let val = Math.round((reached_by_user - prevTarget) / (el.target - prevTarget) * 100);
    this.setProgress(val);
  }
  public setProgress(val: number): void {
    // Start 456; // End 190 // Duration 266
    this.percentage = val;
    this.progress = 456 - (2.66 * val);
  }
}
