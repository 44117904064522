import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-custom-notification',
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.scss']
})
export class CustomNotificationComponent implements OnInit {

  public readonly customTypes = [
    {
      label: 'Minutes',
      value: 'minutes'
    },
    {
      label: 'Hours',
      value: 'hours'
    },
    {
      label: 'Days',
      value: 'days'
    },
  ];

  public customForm: FormGroup = new FormGroup({
    count: new FormControl(1, [Validators.required]),
    type: new FormControl('minutes', [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<CustomNotificationComponent>,
  ) { }

  ngOnInit() {
  }

  public submit(): void {
    if (this.customForm.valid) {
      this.self.close(this.customForm.getRawValue());
    }
  }

}
