import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss']
})
export class ProgressLoaderComponent implements OnInit {
  @Input() white: boolean;
  @Input() fixed: boolean;
  @Input() diameter: number;

  constructor() { }

  ngOnInit(): void {}

}
