import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatBottomSheet} from '@angular/material';
import {MultipleSelectorComponent} from '../multiple-selector/multiple-selector.component';
import {select, Store} from '@ngrx/store';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _toInteger from 'lodash/toInteger';
import {UserService} from '../../../services/users/user.service';


@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnChanges {

    public selection = [];

    @Input() public showEmpty: any;

    @Input() public related: any;

    @Input() public fieldName: any;

    @Input() public selected: any = [];

    @Input() public model: any;

    @Input() public label: any;

    @Output() public change = new EventEmitter();

    public users: any[];

    constructor(private bottomSheet: MatBottomSheet,
                private userService: UserService,
                private store: Store<any>) {
    }

    ngOnInit() {
        if (this.model) {
            this.setSelectedItem();
        }
        if (this.related === 'users') {
            const users = this.store.pipe(select((state) => {
                return state.users.all;
            })).subscribe((data: any) => {
                if (data && this.selection.length <= 0) {
                    this.selection = data.map((user) => {
                        if (this.fieldName === 'closed_by') {
                            return {
                                label: user.full_name,
                                value: user.id.toString(),
                                image: this.getAgentAvatar(user.crm_id),
                            };
                        }
                        return {
                            label: user.full_name,
                            value: user.crm_id.toString(),
                            image: this.getAgentAvatar(user.crm_id),
                        };
                    });
                }

            });

            if (users) {
                users.unsubscribe();
            }
        }
        if (this.fieldName === 'csr_assign_to' || this.fieldName === 'csr_previous_assign_to') {
            const users = this.store.pipe(select((state) => {
                return state.users.all;
            })).subscribe((data: any) => {
                data = _filter(data, el => el.role === 'csr-agent' || el.role === 'csr_manager');
                if (data) {
                    this.selection = data.map((user) => {
                        return {
                            label: user.full_name,
                            value: user.crm_id.toString(),
                            image: this.getAgentAvatar(user.crm_id),
                        };
                    });
                }

            });

            if (users) {
                users.unsubscribe();
            }
        }


        if (this.fieldName === 'mdc_assign_to') {
            const users = this.store.pipe(select((state) => {
                return state.users.all;
            })).subscribe((data: any) => {
                data = _filter(data, el => el.role === 'mdc_dep');
                if (data) {
                    this.selection = data.map((user) => {
                        return {
                            label: user.full_name,
                            value: user.crm_id.toString(),
                            image: this.getAgentAvatar(user.crm_id),
                        };
                    });
                }

            });

            if (users) {
                users.unsubscribe();
            }
        }

        if (this.fieldName === 'mdc_backoffice_assign_to') {
            const users = this.store.pipe(select((state) => {
                return state.users.all;
            })).subscribe((data: any) => {
                data = _filter(data, el => el.role === 'backoffice_mdc');
                if (data) {
                    this.selection = data.map((user) => {
                        return {
                            label: user.full_name,
                            value: user.crm_id.toString(),
                            image: this.getAgentAvatar(user.crm_id),
                        };
                    });
                }

            });

            if (users) {
                users.unsubscribe();
            }
        }

        if (this.fieldName === 'document') {
          this.selection = [
            {
              label: 'Signed',
              value: 'signed'
            },
            {
              label: 'No Signed',
              value: 'not_signed'
            },
            {
              label: 'Not need to sign',
              value: 'not_need_to_sign'
            }
          ]
        }

        if (this.fieldName === 'mdc_evaluation_assign_to') {
            const users = this.store.pipe(select((state) => {
                return state.users.all;
            })).subscribe((data: any) => {
                data = _filter(data, el => el.role === 'mdc_dep');
                if (data) {
                    this.selection = data.map((user) => {
                        return {
                            label: user.full_name,
                            value: user.crm_id.toString(),
                            image: this.getAgentAvatar(user.crm_id),
                        };
                    });
                }

            });

            if (users) {
                users.unsubscribe();
            }
        }



        const fields = this.store.pipe(select((state) => {
            return state.fields;
        })).subscribe((result) => {
            if (result && result[this.related]) {
                this.selection = result[this.related];


                if (this.needChangeAutocomplete()) {
                    this.selection = result[this.related].map(el => {
                        return {
                            label: el.label,
                            value: String(el.value),
                        };
                    });

                    if (this.model) {
                        this.setSelectedItem();
                    }
                }
                if (this.fieldName === 'product' || this.fieldName === 'products') {
                    this.selection = result[this.related].map(el => {
                        return {
                            label: this.getProduct(el.label),
                            value: el.value
                        };
                    });
                }

                if (this.selection[0].value !== 'empty' && this.showEmpty) {
                    this.selection.unshift({
                        label: 'Empty',
                        value: 'empty'
                    });
                }
            }
        });

        if (fields) {
            fields.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.model.firstChange && !changes.model.currentValue) {
            this.selected = [];
        }
        if (changes['selected'] && !changes.selected.currentValue) {
            this.selected = [];
        }
    }

    public open() {
        this.bottomSheet.open(MultipleSelectorComponent, {
            data: {
                selected: Object.assign([], this.selected),
                selection: this.selection,
            }
        }).afterDismissed().subscribe((result) => {
            const prev = this.selected.map(o => o.label).join('');
            const next = result.map(o => o.label).join('');
            if (prev !== next) {
                this.change.emit({
                    name: this.fieldName,
                    value: result,
                });
            }
            this.selected = Object.assign([], result);
        });
    }

    public needChangeAutocomplete(): boolean {
        const changedFields = [
            'instalment_status',
            'type',
            'status',
            'priority',
            'requirement_statuses',
            'requirement_statuses',
            'ticket_types',
            'ticket_statuses',
            'ejobbo_status',
            'hold_status',
            'complaint_status',
            'win_status'
        ];


        return changedFields.includes(this.fieldName);
    }

    public getAgentAvatar(crm_id): string {
        return this.userService.getAvatar(crm_id);
    }

    private setSelectedItem() {
        let arr = JSON.parse(this.model);
        arr = (Array.isArray(arr) ? arr : []);

        if (this.related !== 'users') {
            if (this.fieldName === 'priority' || this.fieldName === 'status' || this.fieldName === 'type' || this.fieldName == 'ejobbo_status'
              || this.fieldName == 'hold_status' || this.fieldName == 'complaint_status' || this.fieldName === 'win_status') {

                this.selected = arr.map(el => ({label: _get(_find(this.selection, {value: String(el)}), ['label'], null), value: el}));

                return;
            }

            this.selected = arr.map(el => ({label: el, value: el}));
            return;
        }
        this.store.pipe(select((state) => state.users))
            .subscribe(response => this.users = response.all);

        this.selected = _map(
            _filter(arr, crmId => _find(this.users, {crm_id: _toInteger(crmId)})),
            crmId => (
                user => ({
                    label: _get(user, 'full_name'),
                    value: crmId,
                    image: this.getAgentAvatar(_get(user, 'crm_id'))
                })
            )(_find(this.users, {crm_id: _toInteger(crmId)}))
        );
    }

    public getProduct(product): string {
        switch (product) {
            case 'IELTS Ashton - Retention - General Course':
                return 'IELTS Conley University - Retention - General Course';
            case 'IELTS Ashton - Retention - Academic Course':
                return 'IELTS Conley University - Retention - Academic Course';
            default:
                return product;
        }
    }
}
