import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Rx";
import { PublicApiService } from '../publicApi/publicApi.service';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(private api: PublicApiService) { }

  public onLogin(): Observable<any> {
    return this.api.get(`/agent/agent_quiz/trigger/login`, {});
  }
  public onHold(): Observable<any> {
    return this.api.get(`/agent/agent_quiz/trigger/hold`, {});
  }

  public viewResult(quiz_id, agent_id): Observable<any> {
    return this.api.get(`/agent/agent_quiz/result/${quiz_id}/${agent_id}`, {});
  }

  public submitQuiz(quiz_id, group_id, body): Observable<any> {
    return this.api.post(`/agent/agent_quiz/passing/${quiz_id}?group_id=${group_id}`, { body });
  }

  public partialSave(quiz_id, body): Observable<any> {
    return this.api.post(`/agent/agent_quiz/partial_save/${quiz_id}`, { body });
  }

  public sendRequest(FormId, UserId, Description): Observable<any> {
    return this.api.post(`/api/quizzes/quiz_request`, {
      body: { Description, FormId, UserId },
      headers: { secret_key: '039e3cea804ae6c10a53ab3a4b7ae4c9' }
    });
  }

}
