import { NgModule } from '@angular/core';
import {ToCurrencyPipe} from './to-currency.pipe';

@NgModule({
  declarations: [ToCurrencyPipe],
  exports: [
    ToCurrencyPipe
  ]
})
export class ToCurrencyPipeModule { }
