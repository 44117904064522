import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
// import { CommissionV3ModalComponent } from 'src/app/components/commission-v3-modal/commission-v3-modal.component';
// import { rres } from 'src/app/components/commissions-v3/example';
import { canPersonalSettings } from 'src/app/helpers/canPersonalSettings';
import { CommissionsService } from 'src/app/services/commissions/commissions.service';
import localStore from 'store';
import {MatSnackBar} from '@angular/material';
import {PermissionsHelper} from '../../../../helpers/Permission';

@Component({
  selector: 'app-header-bars',
  templateUrl: './header-bars.component.html',
  styleUrls: ['./header-bars.component.scss',]
})
export class HeaderBarsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container') container: ElementRef;

  public showArrows: boolean;
  public agent_show_wizard: boolean;
  public can_view_new_header_bars: boolean;

  public isStatsMenuOpened = false;

  public commissionsData: any = {};

  public commissionBarsCount = 0;
  public showPoints = false;
  public showProductCommission: boolean;

  public evtScrollTo: string | number;

  constructor(
    private commissionsService: CommissionsService,
    public permission: PermissionsHelper
  ) { }

  ngOnInit(): void {
    const user = localStore.get('user');

    this.showPoints = this.permission.boardSettings('rewards', 'view');
    this.agent_show_wizard = canPersonalSettings('agent_show_wizard');
    this.can_view_new_header_bars = canPersonalSettings('can_view_new_header_bars');

    // this.user.id = 5;
    // this.user.role = 'retention_agent_manager';
    // this.data = exCom

    if (!this.can_view_new_header_bars) {
      if (this.showPoints) { this.commissionBarsCount++; }
      // if (this.showProductCommission) { this.commissionBarsCount++; }
      return;
    }


    this.commissionsService.getCommissionsAgent({
      user_id: user.id,
      role_id: user.role,
      shift_ids: JSON.stringify(user.shift_ids)
    }).toPromise().then((res) => {
      const event = new CustomEvent('commissions', { detail: res });
      localStore.set('commissions', res);
      document.dispatchEvent(event)
      if (!this.can_view_new_header_bars) {
        res.short.total += res.short.total_pending;
      }
      this.commissionsData = res;
      if (res.short && res.short.product_target_reward && typeof res.short.product_target_reward.commission === 'number') {
        this.showProductCommission = true;
      }
      this.calcVisibleCommissionBars(res);
      // this.commissionsData = rres;
    }, (err) => {
      console.warn(err);
    });
  }

  public calcVisibleCommissionBars(data) {
    let count = 0;
    if (data.short.total !== null) { count++; }
    if (data.short.group_daily_goal !== null) { count+=2; }
    if (data.short.personal_daily_goal !== null) { count++; } //..._for
    if (data.short.group_target || data.short.individual_target) { count++; }
    if (data.short.personal_monthly_goal) { count++; }
    if (this.showPoints) { count++; }
    if (this.showProductCommission) { count++; }
    if ((data.short.milestone_info) && Object.keys(data.short.milestone_info).length > 0 && (data.short.milestone_info.milestones && data.short.milestone_info.milestones.length > 0)
    ) count ++;
    this.commissionBarsCount = count;
    this.checkAutoresize();
  }



  ngAfterViewInit() {
    this.checkAutoresize();
  }

  public observer: any;
  ngOnDestroy(): void {
    if (!this.container) { return; }
    if (!this.observer) { return; }
    this.unobserve();
  }

  public showWizard: number = 0;
  public onShowWizard(show) {
    if (typeof show === 'boolean') {
      this.showWizard = show ? 111 : 0;
    } else {
      this.showWizard = show;
    }
    this.checkAutoresize();
  }

  public checkAutoresize() {
    if (!this.container) { return; }
    if (this.observer) { this.unobserve(); }
    this.observer = new (window as any).ResizeObserver((entries) => {
      if (entries[0]) {
        const width = entries[0].contentRect.width;

        // const WIZARD_BAR = 120; // 12 margin
        const BAR_WIDTH = 183; // 180 + 3 margin

        this.showArrows = (this.commissionBarsCount * BAR_WIDTH + this.showWizard) > width;
      }
    })
    this.observer.observe(this.container.nativeElement);
  }
  private unobserve() {
    this.observer.unobserve(this.container.nativeElement);
    this.observer.disconnect();
  }

  // public toggleStatsMenu() {
  //   if (this.can_view_new_header_bars) {
  //     this.isStatsMenuOpened = !this.isStatsMenuOpened;
  //     this.matDialog.open(CommissionV3ModalComponent, {
  //       data: {
  //         setData: this.commissionsData
  //       },
  //       panelClass: 'popup-header-cards'
  //     });
  //   }
  // }
}
