import {Component, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {CustomNotificationComponent} from '../custom-notification/custom-notification.component';
import _find from 'lodash/find';
import _get from 'lodash/get';
import {NOTIFICATION_TYPES} from '../../../constants/notifications';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectFieldComponent
    }
  ]
})
export class SelectFieldComponent implements OnInit, ControlValueAccessor {

  public notificationTypes = [...NOTIFICATION_TYPES];

  public control: FormControl = new FormControl(null);

  public editData = null;

  public touched = false;
  public disabled = false;

  onChange = (value) => {};
  onTouched = () => {};

  constructor(
    private dialog: MatDialog
  ) {}

  private prevValue = null;

  ngOnInit() {
    this.control.valueChanges.subscribe((val) => {
      if (val === 'custom') {
        this.dialog.open(CustomNotificationComponent).afterClosed().subscribe(res => {
          if (res) {
            // this.notificationTypes = [...NOTIFICATION_TYPES];

            const newOption = {
              label: `${res.count} ${res.type}`,
              value: res
            };

            if (this.editData) {
              newOption.value['uuid'] = this.editData.uuid;
              newOption.value['date'] = this.editData.date;
            }

            this.notificationTypes.splice(6, 0, newOption);

            this.control.setValue(newOption.value, {emitEvent: false});

            this.prevValue = newOption.value;

            this.onChange(res);
          }
          else {
            this.control.setValue(this.prevValue, {emitEvent: false});
          }
        });
      }
      else {
        // this.notificationTypes = [...NOTIFICATION_TYPES];
        //

        if (this.editData) {
          val['uuid'] = this.editData.uuid;
          val['date'] = this.editData.date;
        }

        this.onChange(val);

        this.prevValue = val;
      }
    });
  }

  writeValue(control: any): void {
    this.editData = control;

    const option = _get(_find(this.notificationTypes, i => i.value.count === control.count && i.value.type === control.type), 'value', null);

    if (option) {
      this.control.patchValue(option);
    } else {
      if (control.count && control.type) {
        this.notificationTypes.splice(6, 0, {
          label: `${control.count} ${control.type}`,
          value: control
        });

        this.control.setValue(control, {emitEvent: false});
      }
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

}
