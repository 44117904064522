import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { ProductCommissionComponent } from './product-commission.component';
// import { ProductCommissionModalModule } from '../header-points-modal/header-points-modal.module';

@NgModule({
  declarations: [
    ProductCommissionComponent
  ],
  imports: [
    CommonModule,
    // RouterModule,

    MatIconModule,
    MatDialogModule,
    // MatTooltipModule,

    // ProductCommissionModalModule,
  ],
  exports: [
    ProductCommissionComponent
  ]
})
export class ProductCommissionModule {
}
