import {
  Component,
  // ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
// import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { Router } from '@angular/router';
import SearchResponse from 'src/app/response/search.response';
import { ApplicationsService } from 'src/app/services/applications/applications.service';
import { NotificationSystemService } from 'src/app/services/notification-system/notification-system.service';
import storage from 'store';
// import { NotificationComponent } from './notification/notification.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  @ViewChild('notificationsWrapper', { read: ViewContainerRef }) container;
  @Output() closeNotifications: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() unreadMessagesCount;
  @Output() unreadMessagesCountChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() newMessagesCount;
  @Output() newMessagesCountChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() initialNotificationsCount = 0;

  public loader = true;

  public notifications = [];
  public newNotifications;

  // public notificationsCount;

  public notificationsFilters = [
    {
      name: 'ALL',
      value: 'all',
      office: ['mdc_dep', 'backoffice_mdc', 'management'],
      active: true
    },
    {
      name: 'Tickets',
      value: 'tickets',
      filters: ['ticket_create', 'ticket_reply', 'ticket_close', 'ticket_fixed', 'ticket_disapproved'],
      office: ['mdc_dep', 'backoffice_mdc', 'management'],
      active: false
    },
    {
      name: 'Doc Uploaded - Client',
      value: 'doc_uploaded',
      filters: ['doc_uploaded'],
      office: ['backoffice_mdc', 'management'],
      active: false
    },
    {
      name: 'Doc Pre Approved',
      value: 'doc_pre_approved',
      filters: ['doc_pre_approved'],
      office: ['mdc_dep'],
      active: false
    },
    {
      name: 'Doc Approved',
      value: 'doc_approved',
      filters: ['doc_approved'],
      office: ['backoffice_mdc', 'management'],
      active: false
    },
    {
      name: 'ITA received',
      value: 'ita_received',
      filters: ['ita_received'],
      office: ['mdc_dep', 'backoffice_mdc', 'management'],
      active: false
    },
    {
      name: 'LOA received',
      value: 'loa_received',
      filters: ['loa_received'],
      office: ['mdc_dep'],
      active: false
    },
    {
      name: 'Visa Updates - Client',
      value: 'visa_updates',
      filters: ['visa_updates', 'ticket_visa_respond',],
      office: ['mdc_dep', 'backoffice_mdc', 'management'],
      active: false
    },
    {
      name: 'Customer Queries',
      value: 'customer_queries',
      filters: ['customer_queries'],
      office: ['mdc_dep', 'backoffice_mdc', 'management'],
      active: false
    }
  ];

  public users;
  public agent;

  public applications: SearchResponse = new SearchResponse();

  public params: any = {
  };

  public timer;
  public observer;

  constructor(
    private notificationSystemService: NotificationSystemService,
    // private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private applicationsService: ApplicationsService
  ) { }

  ngOnInit() {
    this.users = storage.get('users');
    this.agent = storage.get('user');

    this.getNotifications('all');

    this.getLocalStorage();
  }


  private visa_types;
  private document_types;
  private ticket_types;
  private requirement_types;
  private getLocalStorage() {
    const autocomplete = storage.get('autocomplete');
    this.visa_types = autocomplete.visa_types;
    this.document_types = autocomplete.document_types;
    this.ticket_types = autocomplete.ticket_types;
    this.requirement_types = autocomplete.requirement_types;
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
    this.clearData();
  }

  public toggleFilter(value) {
    if (value === 'all') {
      this.notificationsFilters.map(n => n.active = false);
      this.notificationsFilters[0].active = true;
    } else {
      if (this.notificationsFilters[0].active === true) {
        this.notificationsFilters[0].active = false;
      }
      const filter = this.notificationsFilters.filter(type => type.value === value)[0];
      filter.active = !filter.active;
      if (this.notificationsFilters.filter(f => f.active).length === 0) {
        this.notificationsFilters[0].active = true;
      }
    }

    this.clearData();

    if (this.notificationsFilters[0].active) {
      this.params.types = '';
    } else {
      let filters = [];
      this.notificationsFilters.filter(f => f.active).map(f => filters = filters.concat(f.filters));
      this.params.types = JSON.stringify(filters);
    }

    this.getNotifications();
  }

  public getNotifications(read?, refresh?) {
    this.loader = true;

    this.notificationSystemService.getNotifications(
      this.params, this.agent.crm_id
    ).toPromise().then((res) => {
      this.setNotifications(res, read, refresh);
    }, (err) => {
      this.loader = false;
    });
  }

  public setNotifications(res, read?, refresh?) {
    this.params.after = res.next;
    this.unreadMessagesCountChange.emit(res.unread_events);
    // this.notificationsCount = +res.all_events;

    this.newNotifications = Object.assign([], res.events);
    this.newNotifications = this.newNotifications.map(n => this.addTextToNotification(n));

    if (read === 'all' && this.unreadMessagesCount) {
      this.readAllNotifications();
    }

    if (refresh) {
      this.initialNotificationsCount = +res.all_events;
    }

    this.notifications = this.notifications.concat(this.newNotifications);

    if (this.notifications.length === 0) {
      this.loader = false;
      return;
    }

    this.addTime();
    this.appendNotifications();
  }

  public onDelete(notif, i) {
    const user_crm_id = storage.get('user').crm_id;
    const updated_at = this.getUpdatedAtTime(notif);
    this.notificationSystemService.deleteNotification(
      user_crm_id, updated_at
    ).toPromise().then((res) => {
      this.notifications.splice(i, 1)
      // this.appendNotifications();
      this.loader = false;
    }, (err) => {
      this.loader = false;
      // console.log(2301, err);
    });
  }


  private appendNotifications() {
    // for (const notification of notifications) {
    //   const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NotificationComponent);
    //   const componentRef = this.container.createComponent(componentFactory);
    //   componentRef.instance.notification = notification;
    // }

    if (this.initialNotificationsCount > this.notifications.length) {
      if (!this.observer) {
        this.lazyLoadNotifications();
      } else {
        const newEntry = document.querySelector('app-notification:last-of-type');
        if (this.observer && newEntry) {
          this.observer.observe(newEntry);
        }
        this.loader = false;
      }
    } else {
      this.loader = false;
    }
  }

  public lazyLoadNotifications() {
    const options = {
      threshold: 1
    };

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.getNotifications();
          this.observer.unobserve(entry.target);
        }
      });
    }, options);

    const entry = document.querySelector('app-notification:last-of-type');
    if (this.observer && entry) {
      this.observer.observe(entry);
    }

    this.loader = false;
  }

  public addTextToNotification(notification) {
    const leadNumber = notification.additional_info ? notification.additional_info.lead_number : '';
    // console.log(254, notification)
    if (!notification.additional_info) { notification.additional_info = notification.object_model.additional_info }

    switch (notification.action) {
      case 'ticket_create':
        notification.text = `<b>${this.getTicketType(notification.object_model.type)}</b> for <b>${leadNumber}</b> created
                             by <b>${this.getUser(notification.object_model.opened_by)}</b>. Assigned to
                             <b>${this.getUser(notification.object_model.assigned_to)}</b>.`;
        notification.new_action = 'tickets';
        break;
      case 'ticket_reply':
        notification.text = `<b>${this.getUser(notification.user_id)}</b> responded to
                             <b>${this.getTicketType(notification.object_model.type)}</b> on
                             <b>${leadNumber}</b>.`;
        notification.new_action = 'tickets';
        break;
      case 'ticket_close':
        notification.text = `<b>${this.getUser(notification.user_id)}</b> closed
                             <b>${this.getTicketType(notification.object_model.type)}</b> on
                             <b>${leadNumber}</b>.`;
        notification.new_action = 'tickets';
        break;
      case 'ticket_fixed':
        notification.text = `<b>${this.getUser(notification.user_id)}</b> fixed
                             <b>${this.getTicketType(notification.object_model.type)}</b> on
                             <b>${leadNumber}</b>.`;
        notification.new_action = 'tickets';
        break;
      case 'ticket_disapproved':
        notification.text = `<b>${this.getUser(notification.user_id)}</b> disapproved
                             <b>${this.getTicketType(notification.object_model.type)}</b> on
                             <b>${leadNumber}</b>.`;
        notification.new_action = 'tickets';
        break;
      case 'ticket_visa_respond':
        notification.text = `<b>${leadNumber}</b> has responded to a visa requirement ticket.`;
        notification.new_action = 'visa_updates';
        break;
      case 'doc_uploaded':
        notification.text = `<b>${leadNumber}</b> has uploaded
                             <b>${this.getDocumentType(notification.object_model.type)}</b>
                             for <b>${this.getRequirementType(notification.additional_info.requirement_type)}</b>.`;
        notification.new_action = 'doc_uploaded';
        break;
      case 'doc_pre_approved':
        notification.text = `<b>${this.getDocumentType(notification.additional_info.document_type)}</b> for
                             <b>${this.getVisaType(notification.additional_info.visa_type)}</b> was pre-approved for
                             <b>${leadNumber}</b>.`;
        notification.new_action = 'doc_pre_approved';
        break;
      case 'doc_approved':
        notification.text = `<b>${this.getDocumentType(notification.additional_info.document_type)}</b> for
                             <b>${this.getVisaType(notification.additional_info.visa_type)}</b> was approved for
                             <b>${leadNumber}</b>.`;
        notification.new_action = 'doc_approved';
        break;
      case 'ita_received':
        notification.text = `<b>${notification.object_model.lead_number}</b> status has changed from
                             <b>${notification.additional_info.processing_visa_statuses}</b> to
                             <b>${notification.object_model.processing_visa_statuses}</b>.`;
        notification.new_action = 'ita_received';
        break;
      case 'loa_received':
        notification.text = `<b>${leadNumber}</b> has uploaded
                             <b>${this.getDocumentType(notification.object_model.type)}</b>.`;
        notification.new_action = 'loa_received';
        break;
      case 'visa_updates':
        notification.text = `<b>${leadNumber}</b> has fixed a visa requirement ticket.`;
        notification.new_action = 'visa_updates';
        break;
      case 'customer_queries':
        notification.text = `<b>${leadNumber}</b> has responded to a visa requirement ticket.`;
        notification.new_action = 'customer_queries';
        break;
      default:
        notification.text = `Unknown action.`;
        break;
    }

    if (notification.object_type === 'ticket') {
      notification.fn = () => {
        this.checkLikeClicked(notification);
        this.router.navigate([`/details/tickets/${notification.object_model.id}`]);
        this.closeNotifications.emit(true);
      };
    } else {
      notification.fn = () => {
        this.applications.addFilter('lead_number', JSON.stringify([leadNumber]));

        this.applicationsService.getAllList(Object.assign(this.applications.getReqData())).subscribe((res) => {
          this.checkLikeClicked(notification);

          this.applications = Object.assign(this.applications, res);
          this.router.navigate([`/details/applications/${this.applications.data[0].id}`]);
          this.closeNotifications.emit(true);
        });
      };
    }

    return notification;
  }

  private getUser(id) {
    if (+id === 1 || +id === 0) {
      return 'Client';
    } else {
      const user = this.users.find(u => u.crm_id === +id);
      return user ? user.full_name : 'Unknown';
    }
  }


  private getVisaType(type) {
    const foundedType = this.visa_types.find(t => t.value === type);
    return foundedType ? foundedType.label : 'Unknown';
  }

  private getDocumentType(type) {
    const foundedType = this.document_types.find(t => t.value === type);
    return foundedType ? foundedType.label : 'Unknown';
  }

  private getTicketType(type) {
    const foundedType = this.ticket_types.find(t => t.value === type);
    return foundedType ? foundedType.label : 'Unknown';
  }

  private getRequirementType(type) {
    const foundedType = this.requirement_types.find(t => t.value === type);
    return foundedType ? foundedType.label : 'Unknown';
  }

  public addTime() {
    this.calculateTime(this.notifications);

    if (this.timer) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      this.calculateTime(this.notifications);
    }, 1000);
  }

  private calculateTime(notifications) {
    for (const notification of notifications) {
      const updatedAt = this.getUpdatedAtTime(notification) * 1000;
      const days = (Date.now() - updatedAt) / (1000 * 60 * 60 * 24);

      if (days < 1) {
        const hours = Math.trunc((Date.now() - updatedAt) / (1000 * 60 * 60));
        const minutes = Math.trunc(((Date.now() - updatedAt) - (hours * 60 * 60 * 1000)) / (1000 * 60));

        notification.time = `${hours ? hours + 'h' : ''} ${minutes}m`;
      }
    }

    return notifications;
  }

  private checkLikeClicked(notification) {
    const body = {
      user_crm_id: storage.get('user').crm_id,
      scores: []
    };

    if (!notification.is_clicked) {
      body.scores.push(this.getUpdatedAtTime(notification));
    }

    if (body.scores.length) {
      this.notificationSystemService.clickNotiication(
        body
      ).toPromise().then((res) => {
        if (res === 'OK') {
          notification.is_clicked = true;
        }
      }, (err) => {
        this.loader = false;
      });
    }
  }

  public readAllNotifications() {
    const body = {
      user_crm_id: storage.get('user').crm_id,
    };

    this.notificationSystemService.readNotification(
      body
    ).toPromise().then((res) => {
      if (res === 'OK') {
        this.unreadMessagesCountChange.emit(0);

        for (const notification of this.newNotifications) {
          notification.is_read = true;
        }
      }
    }, (err) => { });
  }

  private getUpdatedAtTime(notification) {
    if (['ticket_visa_respond', 'ticket_reply'].includes(notification.action)) {
      return notification.object_model.comments[0].updated_at;
    } else if (notification.action === 'visa_updates') {
      if (!notification.additional_info) {
        notification.additional_info = notification.object_model.additional_info;
      }
      return notification.additional_info.updated_at;
    } else {
      return notification.object_model.updated_at;
    }
  }

  public refreshNotifications() {
    this.clearData();
    this.getNotifications('all', true);
  }

  private clearData() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    if (this.container) {
      this.container.clear();
    }

    this.notifications = [];
    this.params.after = '';
    this.params.next = '';
    this.newMessagesCountChange.emit(0);
  }
}
