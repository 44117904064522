import {Component, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { WizardsService } from '../../services/wizards/wizards.service';
import SearchResponse from '../../response/search.response';
import { MatDialog } from '@angular/material';
import { CentrifugeService } from '../../services/centrifuge/centrifuge.service';
import {filter} from 'rxjs/operators';
import localStore from 'store';
import { Wizards2Service } from 'src/app/services/wizards2/wizards.service';
import { canPersonalSettings } from 'src/app/helpers/canPersonalSettings';
import {WizardCountService} from '../../services/wizards2/wizardCount.service';
import store from 'store';
import {MenuHelper} from '../../helpers/MenuHelper';
import {CommonInfoService} from '../../services/common-info/common-info';
import {PermissionsHelper} from '../../helpers/Permission';

@Component({
  selector: 'app-wizard-v3',
  templateUrl: './wizard-v3.component.html',
  styleUrls: ['./wizard-v3.component.scss']
})
export class WizardV3Component implements OnInit, OnDestroy {
  @ViewChild('link') link: ElementRef;

  @Output() showWizardE: EventEmitter<boolean> = new EventEmitter<boolean>()

  public wizards: SearchResponse = new SearchResponse();

  public wizardId: number;

  public wizardEntity: string;

  public wizardCount = 0;
  public wizardCountCalled = 0;

  public showWizard = false;

  public user: any;

  public counts = [];

  public countByType = [];

  private subscribers: any = {};
  private centListener: any;

  constructor(
    private wizardsService: WizardsService,
    private wizards2Service: Wizards2Service,
    private modal: MatDialog,
    private centrifugeService: CentrifugeService,
    private ngZone: NgZone,
    private wizardCountService: WizardCountService,
    public permission: PermissionsHelper
  ) {
    this.user = localStore.get('user');
  }

  ngOnInit() {
    this.subscribeWizardCount();

    this.checkWizardCount();
  }

  private checkWizardCount(): void {
    const defaultMenuItems: any = [...MenuHelper.defaultMenuItems];

    let url = null;

    for (const item of defaultMenuItems) {
      if (this.permission.boardSettings(item.type, 'is_default')) {
        url = item.link;
        break;
      }
    }

    if (
      (
        this.showOnlyWizard() &&
        !document.getElementsByTagName('app-wizard-page-new').length &&
        !(window.location.pathname === '/dashboard' && (url === '/applications' || url === '/wizard' || url === '/dashboard/leads'))
      ) || (
        canPersonalSettings('agent_show_wizard') &&
        !document.getElementsByTagName('app-wizard-page-new').length &&
        !(
          this.showOnlyWizard() &&
          (window.location.pathname === '/dashboard' && (url === '/applications' || url === '/wizard' || url === '/dashboard/leads'))
        )
      )
    ) {
      this.detectWizardVer();
    }
  }

  public showOnlyWizard(): boolean {
    return this.permission.roleGeneral('show_only_wizard')
  }

  ngOnDestroy(): void {
    for (const key in this.subscribers) {
      if (this.subscribers[key] && this.subscribers[key].unsubscribe) {
        this.subscribers[key].unsubscribe();
      }
    }
    if (this.centListener && this.centListener.close) {
      this.centListener.close();
    }
  }

  private subscribeWizardCount(): void {
    this.wizardCountService.wizardCount.pipe(
      filter(data => !!data)
    ).subscribe( count => {
      this.wizardCountCalled = count.count_called;
      this.wizardCount = count.count;
      this.showWizard = true;

      if (!this.showWizard) {
        setTimeout(() => {
          if (this.link && this.link.nativeElement) {
            const width = this.link.nativeElement.getBoundingClientRect().width;
            this.showWizardE.emit(width);
          } else {
            this.showWizardE.emit(true);
          }
        });
      }
    });
  }

  public detectWizardVer() {
      this.getWizardsDataNew();
  }

  public getWizardsDataNew() {
    this.wizardEntity = localStore.get('wizard_type');
    const wizardInfo = localStore.get('wizard_info');
    this.wizardId = wizardInfo.id;

    if (!wizardInfo.active || !wizardInfo.id) { return; }

    const req = {
      agent_id: this.user.crm_id,
      wizard_type: this.wizardEntity,
      wizard_id: wizardInfo.id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    this.wizards2Service.getWizardsWidgetCount(
      req
    ).toPromise().then((res) => {
      this.wizardCountCalled = res.total.count_called;
      this.wizardCount = res.total.count;
      this.showWizard = true;
      setTimeout(() => {
        if (this.link && this.link.nativeElement) {
          const width = this.link.nativeElement.getBoundingClientRect().width;
          this.showWizardE.emit(width);
        } else {
          this.showWizardE.emit(true);
        }
      });
    });
  }
}
