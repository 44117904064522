import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActionNotificationComponent} from './action-notification.component';
import {NbCardModule, NbCheckboxModule, NbListModule} from '@nebular/theme';
import {NotificationModalComponent} from '../notification-modal/notification-modal.component';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {PieLoaderModule} from '../general/pie-loader/pie-loader.module';
import {ConfirmModalModule} from '../general/confirm-modal/confirm-modal.module';
import {ErrorModalModule} from '../general/error-modal/error-modal.module';
import {MatBadgeModule, MatButtonModule, MatCheckboxModule, MatTabsModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import { NotificationModalModule } from '../notification-modal/notification-modal.module';

@NgModule({
    declarations: [
        ActionNotificationComponent
    ],
    imports: [
        CommonModule,
        NbCardModule,
        NbListModule,
        NbCheckboxModule,
        NgbModalModule,
        PieLoaderModule,
        ConfirmModalModule,
        NotificationModalModule,
        ErrorModalModule,
        MatButtonModule,
        MatBadgeModule,
        MatTabsModule,
        MatCheckboxModule,
        FormsModule
    ],
    exports: [
        ActionNotificationComponent
    ],
    entryComponents: [
        NotificationModalComponent
    ]

})
export class ActionNotificationModule {
}
