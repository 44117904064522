import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommissionAchievedPopupComponent } from './commission-achieved-popup.component';

@NgModule({
  declarations: [
    CommissionAchievedPopupComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommissionAchievedPopupComponent
  ],
  entryComponents: [
    CommissionAchievedPopupComponent
  ]
})
export class CommissionAchievedPopupModule {
}
