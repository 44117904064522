import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import {MatButtonModule, MatIconModule, MatTooltipModule} from '@angular/material';
import { NotificationComponent } from './notification/notification.component';
import {CallBarModule} from '../@theme/components/header/call-bar/call-bar.module';
import {CounterDirectiveModule} from '../@theme/directives/counter.directive.module';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationComponent
  ],
  exports: [
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    CounterDirectiveModule
  ],
})
export class NotificationsModule { }
