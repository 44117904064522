import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Rx';

@Injectable({
    providedIn: 'root'
})
export class PointsService {

    constructor(private api: ApiService) {
    }

    public get(params): Observable<any> {
        return this.api.get('/points', {
            params: params
        });
    }

    public history(params): Observable<any> {
        return this.api.get('/points/history', {
            params: params
        });
    }

    public getV2(params?): Observable<any> {
        return this.api.get('/points/v2', { params });
    }

    public historyV2(params?): Observable<any> {
        return this.api.get('/points/v2/history', { params });
    }

    public getHeaderPoints(params?): Observable<any> {
        return this.api.get('/points/v2/info', { params })
    }
    public info(): Observable<any> {
      return this.api.get('/points/v2/info', {});
    }
}
