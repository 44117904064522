import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CurrentUserComponent} from './current-user.component';
import {MatMenuModule, MatTooltipModule} from '@angular/material';
import {LevelModalModule} from '../general/level-modal/level-modal.module';
import { QuickPointsModalModule } from '../general/quick-points-modal/quick-points-modal.module';

@NgModule({
    declarations: [
        CurrentUserComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatTooltipModule,
        LevelModalModule,
        QuickPointsModalModule,
    ],
    exports: [
        CurrentUserComponent
    ]
})
export class CurrentUserModule {
}
