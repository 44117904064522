import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatWindowComponent } from './chat-window.component';
import {ChatContentModule} from '../chat-content/chat-content.module';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
      ChatWindowComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ChatContentModule
  ],
  exports: [
    ChatWindowComponent
  ]
})
export class ChatWindowModule { }
