import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CounterDirective} from './counter.directive';

@NgModule({
  declarations: [
    CounterDirective
  ],
  imports: [],
  exports: [
    CounterDirective
  ]
})
export class CounterDirectiveModule {
}
