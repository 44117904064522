import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import localStore from 'store';
import { InfoV2ApiService } from './infoV2.api.service';
import * as CryptoJS from 'crypto-js';
import { base64url } from 'src/app/helpers/base64url';
import { User } from '../users/user';
import {Router} from '@angular/router';
import {PermissionsHelper} from '../../helpers/Permission';

@Injectable({
  providedIn: 'root'
})

export class InfoV2Service {

  constructor(
    private api: InfoV2ApiService,
    public permission: PermissionsHelper
  ) {}

  private getJWT(entityType: string): string {
    const fieldsRaw = localStore.get(`${entityType}_model_info`) || [];
    const user = localStore.get(`user`) || {};

    let fields = [];
    for (const key in fieldsRaw) {
      fields.push(fieldsRaw[key].name)
    }

    let type;
    if (entityType === 'leads') {
      type = 'assigned_to';
    } else if (entityType === 'applications') {
      type = 'assigned_user_crm_id';
    }

    const value = this.permission.boardSettings(entityType, 'assigned_only');

    const header = {
      alg: 'HS256',
      typ: 'JWT'
    };
    const data = {
      fields,
      permissions: {
        onlyAssigned: {
          type,
          value
        }
      },
      user: {
        role: user.role,
        crm_id: user.crm_id
      }
    }
    const key = 'BPz5hCcjMYdVw2d3BX3kyY2WX7HVzkHL';

    const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    const encodedHeader = base64url(stringifiedHeader);

    const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    const encodedData = base64url(stringifiedData);

    let signature = CryptoJS.HmacSHA256(`${encodedHeader}.${encodedData}`, key);
    const encodedSignature = base64url(signature);

    const token = `${encodedHeader}.${encodedData}.${encodedSignature}`;

    return token;
  }

  public get(entityType: string, lead_id: any): any {
    return this.api.get(`entity/index?entity=${entityType}&identity=${lead_id}`, {
      headers: {
        Permissions: this.getJWT(entityType)
      }
    });
  }

  public groupFieldsInfo(): Observable<any> {
    return this.api.get('setting/group-fields', {});
  }

  public getOpportunitiesByLeadNumber(lead_number: number): Observable<any> {
    return this.api.get(`entity/payments?account=${lead_number}`, {});
  }
}
