import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';

import { HeaderPointsModalComponent } from './header-points-modal.component';

import { ProgressLoaderModule } from '../../general/progress-loader/progress-loader.module';
@NgModule({
  declarations: [
    HeaderPointsModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,

    ProgressLoaderModule
  ],
  exports: [
    HeaderPointsModalComponent
  ],
  entryComponents: [
    HeaderPointsModalComponent
  ]
})
export class HeaderPointsModalModule {
}
