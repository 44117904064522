import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { CommissionsService } from 'src/app/services/commissions/commissions.service';
// import {RoleCommissionsService} from 'src/app/services/role_commissions/role-commissions.service';
import { User } from 'src/app/services/users/user';

@Component({
  selector: 'app-commission-modal',
  templateUrl: './commission-modal.component.html',
  styleUrls: ['./commission-modal.component.scss']
})
export class CommissionModalComponent implements OnInit {

  public loading = false;

  public role: string;

  public commissions: any;

  public noData = false;

  public total = {
    title: 'Personal Daily Commission',
  };

  public is_retention: boolean;

  public showVideo: boolean;

  public currencyRatio = User.CURRENSY;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commissionsService: CommissionsService
  ) { }

  ngOnInit() {
    this.role = this.data.user.role;

    this.is_retention = this.role === User.RETENTION_AGENT || this.role === User.RETENTION_AGENTS_J;

    this.loading = true;
    this.commissionsService.getCommissionsAgent({
      user_id: this.data.user.id,
      role_id: this.role,
      shift_ids: JSON.stringify(this.data.user.shift_ids),
      details: 1
    }).toPromise().then((res) => {
      if (
        res.details &&
        res.details.data &&
        typeof res.details.data === 'object' &&
        res.details.data.length === 0
      ) {
        this.noData = true;
      }
      this.setTotal();

      this.commissions = res.details.data;
      this.data.update(res);
      this.loading = false;
    }, (err) => {
      this.noData = true;
      this.commissions = {};
      this.setTotal();
      this.loading = false;
    });
  }

  public setTotal() {
    this.total.title = this.data.title;
  }

  public onTabChange(index) {
    if (index === 2) {
      setTimeout(() => {
        this.showVideo = true;
      }, 220);
    } else {
      this.showVideo = false;
    }
  }

}
