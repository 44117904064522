import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progtess-15',
  templateUrl: './progtess-15.component.html',
  styleUrls: ['./progtess-15.component.scss',]
})
export class Progress15Component implements OnInit {
  @Input() value: number;

  constructor() { }

  ngOnInit(): void {}

}
