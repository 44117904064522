import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnnouncementsComponent} from './announcements.component';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';

@NgModule({
    declarations: [
        AnnouncementsComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatBadgeModule,
    ],
    exports: [
        AnnouncementsComponent
    ]
})
export class AnnouncementsModule {
}
