export class Update {

    static token: string;

    static get() {
        if (!Update.token) {
            Update.token = Math.random().toString(36).substring(7) +
                Math.random().toString(36).substring(7);
        }
        return Update.token;
    }

}
