import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import SearchResponse from '../../../../response/search.response';
import {CollectionService} from '../../../../services/collection/collection.service';

export interface Product {
  product: string;
  id: number;
  quote_amount: number;
  paid_amount: number;
  max_discount: number;
}


@Component({
  selector: 'app-discount-modal',
  templateUrl: './discount-modal.component.html',
  styleUrls: ['./discount-modal.component.scss']
})
export class DiscountModalComponent implements OnInit {

  public discountGroup: FormGroup;

  public defaultDiscount = 0;

  public discountPaid = false;

  public isDefault = false;

  public discountAuto = [];

  public opportunities: SearchResponse = new SearchResponse();

  public loader: boolean;

  public products: Array<Product>;

  public quoteAmount: number;

  public quoteDiscount: number;

  public opportunityId: number;

  constructor(
    private self: MatDialogRef<DiscountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private collectionService: CollectionService,
    private snackBar: MatSnackBar,
  ) {
    this.defaultDiscount = this.data.is_amount ? this.data.discount : Math.round(this.data.discount * 100) / 100;
    this.discountPaid = this.data.discount_paid;
    this.isDefault = this.data.is_default;
  }

  ngOnInit() {
    this.discountGroup = new FormGroup({
        product: new FormControl('', [Validators.required]),
        discount: new FormControl('', [
          Validators.required,
        ]),
      }
    );

    if (this.data.is_new) {
      this.discountAuto.push({value: 0, label: 0 + (this.data.is_amount ? ' $' : ' %')});

      if (this.data.options && this.data.options.length > 1) {
        const initialized = this.data.is_amount ? 1 : 1;
        for (let i = this.data.options[0]; i <= this.data.options[1]; i += initialized) {
          this.discountAuto.push({value: i, label: i + (this.data.is_amount ? ' $' : ' %')});
        }
      } else {
        this.discountAuto.push({value: this.data.options[0], label: this.data.options[0] + (this.data.is_amount ? ' $' : ' %')});
      }

      this.discountGroup.get('product').patchValue(this.data.product);
      this.discountGroup.get('discount').patchValue(this.defaultDiscount);

      if (this.defaultDiscount > this.discountAuto[this.discountAuto.length - 1].value) {
        this.discountAuto.push({value: this.defaultDiscount, label: this.defaultDiscount + this.data.is_amount ? ' $' : ' %'});
      }
    } else {
      for (let i = 0; i <= 25; i++) {
        this.discountAuto.push({value: i, label: i + '%'});
      }

      this.discountGroup.get('product').patchValue(this.data.product);
      this.discountGroup.get('discount').patchValue(this.defaultDiscount);

      if (this.defaultDiscount > 25) {
        this.discountAuto.push({value: this.defaultDiscount, label: this.defaultDiscount + '%'});
      } else {
        for (let i = 0; i <= this.discountAuto.length - 1; i++) {
          if (this.defaultDiscount > this.discountAuto[i].value && this.defaultDiscount < this.discountAuto[i + 1].value ) {
            this.discountAuto.splice(i + 1 , 0, {value: this.defaultDiscount, label: this.defaultDiscount + '%'});
            break;
          }
        }
      }
    }

    this.collectionService.getProducts(this.data.entity.lead_number ? this.data.entity.lead_number : this.data.entity.account_number)
      .subscribe(res => {
        this.loader = false;
        this.products = res;

        if (this.data.product && this.products) {
          const selected = this.products.filter(pr => this.changeAshton(pr.product) === this.data.product)[0];

          if (selected) {
            this.discountGroup.get('product').patchValue(selected.id);
          }
        }

      }, err => {
        this.loader = false;
      });

  }

  public changeAshton(product: string): string {
    switch (product) {
      case 'IELTS Conley University - Retention - Academic Course':
        return 'IELTS Ashton - Retention - Academic Course';
      case 'IELTS Ashton - Retention - Academic Course':
        return 'IELTS Conley University - Retention - Academic Course';
      case 'IELTS Conley University - Retention - General Course':
        return 'IELTS Ashton - Retention - General Course';
      case 'IELTS Ashton - Retention - General Course':
        return 'IELTS Conley University - Retention - General Course';
      default:
        return product;
    }
  }

  public createDiscount() {
    this.self.close({
      discount: this.discountGroup.get('discount').value,
    });
  }

  public addDiscount(discount: number) {
    this.quoteDiscount = Math.round(this.quoteAmount - this.quoteAmount / 100 * discount);
  }

}
