import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateLeadModalComponent} from './create-lead-modal.component';
import {MatButtonModule, MatDialogModule, MatInputModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSelectModule} from 'ngx-select-ex';
import {ConfirmModalModule} from '../confirm-modal/confirm-modal.module';
import {SmallLoaderModule} from '../small-loader/small-loader.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {ArrayTranslatePipeModule} from '../../../pipes/arrayTranslate/arrayTranslate.pipe.module';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {PhoneInputModule} from '../phone-input/phone-input.module';

@NgModule({
  declarations: [
    CreateLeadModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxSelectModule,
    MatButtonModule,
    MatInputModule,
    ConfirmModalModule,
    SmallLoaderModule,
    MatSlideToggleModule,
    MatIconModule,
    TranslateModule,
    ArrayTranslatePipeModule,
    Ng2TelInputModule,
    PhoneInputModule
  ],
  exports: [
    CreateLeadModalComponent
  ],
})
export class CreateLeadModalModule {
}
