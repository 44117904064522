export class DateConverter {

    public static toDateString(date: Date) {
        return date.getFullYear() +
            '-' + ('0' + (date.getMonth() + 1)).slice(-2) +
            '-' + ('0' + date.getDate()).slice(-2);
    }

    public static addDays(date: Date, days: number, convert?: any): any {
        const newDate = new Date();
        newDate.setDate(date.getDate() + days);
        if (!convert) {
            return newDate;
        }
        return DateConverter.toDateString(newDate);
    }

    public static addMonth(date: Date, months: number, day: number, convert?: any): any {
        const year = date.getFullYear();
        const month = date.getMonth();
        const newDate = new Date(year, month + months, day);
        if (!convert) {
            return newDate;
        }
        return DateConverter.toDateString(newDate);
    }

    public static startOfDay(date: Date) {
        date.setHours(0, 0, 0, 0);
        return date;
    }

    public static endOfDay(date: Date) {
        date.setHours(23, 59, 59, 0);
        return date;
    }

    public static startOfDayUTC(date: Date) {
        date.setHours(0, 0, 0, 0);
        return date.getTime() - (date.getTimezoneOffset() * 60 * 1000);
    }

    public static endOfDayUTC(date: Date) {
        date.setHours(23, 59, 59, 0);
        return date.getTime() - (date.getTimezoneOffset() * 60 * 1000);
    }

    public static startOfMonthUTC(date: Date) {
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        return date.getTime() - ((date).getTimezoneOffset() * 60 * 1000);
    }

    public static endOfMonthUTC(date: Date) {
        date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        date.setHours(23, 59, 59, 0);
        return date.getTime() - ((date).getTimezoneOffset() * 60 * 1000);
    }

    public static convertFromUTC(time) {
        return parseInt(time, 10) + ((new Date(time * 1000)).getTimezoneOffset() * 60);
    }

  public static convertToUTC(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    // return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
  }
}
