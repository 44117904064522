import {base64url} from './base64url';
import * as CryptoJS from 'crypto-js';

const KEY = 'BPz5hCcjMYdVw2d3BX3kyY2WX7HVzkHL';
const HEADER: any = {
  alg: 'HS256',
  typ: 'JWT'
};

export class JWT {
  public static encode(data: any) {
    const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(HEADER));
    const encodedHeader = base64url(stringifiedHeader);

    const stringifedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    const encodedData = base64url(stringifedData);

    const signature = CryptoJS.HmacSHA256(`${encodedHeader}.${encodedData}`, KEY);
    const encodedSignature = base64url(signature);

    const token = `${encodedHeader}.${encodedData}.${encodedSignature}`;

    return token;
  }
}
