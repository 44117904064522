import {Action} from '@ngrx/store';

export enum PaymentLinkActionsType {
  SUCCESS_SENT_PAYMENT_LINK = '[PaymentLink] successSentPaymentLink',
  ERROR_SENT_PAYMENT_LINK = '[PaymentLink] errorSentPaymentLink',

}

export class SuccessSentPaymentLink implements Action {
  readonly type = PaymentLinkActionsType.SUCCESS_SENT_PAYMENT_LINK;
  constructor(public payload?: any) {}
}
export class ErrorSentPaymentLink implements Action {
  readonly type = PaymentLinkActionsType.ERROR_SENT_PAYMENT_LINK;
  constructor(public payload: any) {}
}

export type PaymentLinksActionsUnion = SuccessSentPaymentLink | ErrorSentPaymentLink;
