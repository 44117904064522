import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { EventBusService } from 'src/app/services/event-bus/event-bus.service';
import { User } from 'src/app/services/users/user';

@Component({
  selector: 'app-commission-achieved-popup',
  templateUrl: './commission-achieved-popup.component.html',
  styleUrls: ['./commission-achieved-popup.component.scss'],
})

export class CommissionAchievedPopupComponent implements OnInit, AfterViewInit {

  public tops = [1584, 1584, 1584, 1584];
  public digitsIterator = [0, 1, 2, 3];
  public newTops = [1584, 1584, 1584, 1584];

  public counterDone = false;
  public moveToPosition = false;

  public achieved_commission: number;
  public is_pending: boolean;

  public target = {
    top: '50%',
    left: '50%',
  }
  public currentTarget = {
    top: '50%',
    left: '50%',
  }

  public closed: boolean;

  public numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  public DIGIT_H = 176;
  
  public currencyRatio = User.CURRENSY; 

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<CommissionAchievedPopupComponent>,
    private eventBus: EventBusService
  ) {
    this.self.addPanelClass('commisssion-achieved-modal');


    const commissionData = data.commission;

    this.achieved_commission = commissionData.achieved_commission || 0;
    this.is_pending = commissionData.is_pending || false;

    const str = '00000' + (Math.round(this.achieved_commission * this.currencyRatio) || 0).toString();
    let digits = [
      +(str[str.length - 4]),
      +(str[str.length - 3]),
      +(str[str.length - 2]),
      +(str[str.length - 1])
    ];
    if (+str[str.length - 5]) {
      digits.unshift(+str[str.length - 5]);
      this.tops.push(1584);
      this.newTops = this.tops;
      this.digitsIterator.push(4)
    }

    for (let i = 0; i < digits.length; i++) {
      const ci = i;
      // if (digits[ci] === 9) { this.tops[ci] = (this.numbers.length - 1) * this.DIGIT_H; }
      if ((ci + 1) % 2) {
        this.newTops[ci] = digits[ci] * this.DIGIT_H + this.DIGIT_H * 10;
      } else {
        if (digits[ci] === 9) {
          this.newTops[ci] = digits[ci] * this.DIGIT_H + this.DIGIT_H * 10;
        } else {
          this.newTops[ci] = digits[ci] * this.DIGIT_H;
        }
      }
    }
    //

    this.self.afterClosed().toPromise().then((res) => {
      this.closed = true;
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.detectTargetPosition();
    setTimeout(() => {
      if (this.closed) {
        this.eventBus.emit('change-commission-in-header', { achieved_commission: this.achieved_commission, is_pending: this.is_pending });
        return;
      }
      this.tops = this.newTops;
      setTimeout(() => {
        if (this.closed) {
          this.eventBus.emit('change-commission-in-header', { achieved_commission: this.achieved_commission, is_pending: this.is_pending });
          return;
        }
        this.counterDone = true;
        setTimeout(() => {
          if (this.closed) {
            this.eventBus.emit('change-commission-in-header', { achieved_commission: this.achieved_commission, is_pending: this.is_pending });
            return;
          }
          this.moveToPosition = true;
          this.currentTarget = this.target;
          setTimeout(() => {
            this.eventBus.emit('change-commission-in-header', { achieved_commission: this.achieved_commission, is_pending: this.is_pending });
            this.self.close();
          }, 590);
        }, 200);
      }, 2000);
    }, 100);
  }

  public detectTargetPosition() {
    if (!document) { return; }
    let element: any;

    const cont = document.getElementById('points-v3-scroll');
    if (cont) {
      cont.scrollTo({
        left: cont.offsetLeft + 183,
        behavior: 'instant'
      })
    }

    if (this.is_pending) {
      element = document.getElementById('header-commission-pending');
    } else {
      element = document.getElementById('header-commission-current');
    }
    if (!element) { return; }
    const targetGBCR = element.getBoundingClientRect();
    this.target = {
      top: targetGBCR.top + (targetGBCR.height / 2) + 'px',
      left: targetGBCR.left + (targetGBCR.width / 2) + 'px'
    }
  }

}
