import {Component, OnInit, Input, OnDestroy, NgZone} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ChatService} from '../../../../../services/manager-chat/chat.service';
import {CentrifugeService} from '../../../../../services/centrifuge/centrifuge.service';
import * as ChatActions from '../../../../../actions/chat.actions';
import {animate, style, transition, trigger} from '@angular/animations';
import {UserService} from '../../../../../services/users/user.service';

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.less'],
  animations: [
    trigger('showAnim', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ])
    ]),
  ],
})
export class MessagesListComponent implements OnInit, OnDestroy {

  @Input() agent: any;

  private historyDate = 0;

  public messageList: any;

  private noScroll = false;

  private chatPosition = 0;

  public loader: boolean;

  private countHistory = 0;

  private subs = {
    chat: null,
    history: null,
    message: null
  };

  constructor(
      private ngZone: NgZone,
      private store: Store<any>,
      private chatService: ChatService,
      private userService: UserService,
      private centrifugeService: CentrifugeService
  ) {
    this.subs.chat = this.store.pipe(select('chat')).subscribe((value) => {
      // console.log('----messageList-----', value.messages)
      this.messageList = value.messages;
      if (this.messageList && this.countHistory < value.messages.items.length ) {
        this.countHistory = this.messageList.items.length;

        this.ngZone.run(() => {
          setTimeout(() => {
            this.scrollBottom();
          }, 0);
        });
      }
    });
    this.setStore();
  }

  ngOnInit() {

    this.getHistory();

    this.loadMoreScroll(() => {
      if (this.messageList.meta.page !== this.messageList.meta.count) {
        this.chatPosition = document.querySelector('.chat-container .content').scrollHeight;
        this.noScroll = true;
        this.getHistory(this.messageList.meta.page + 1);
      }
    })

    this.subs.message = this.centrifugeService.listen(`chat_${this.agent.id}_focused`).subscribe(value => {
      // console.log('-----chat_${this.agent.id}_assigner_focused------', value)
      this.store.dispatch(new ChatActions.SetMessage(value.data.message));
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ChatActions.SetMessageHistory(null))
    for (const name in this.subs) {
      if (this.subs.hasOwnProperty(name) && this.subs[name]) {
        this.subs[name].unsubscribe();
      }
    }
  }

  public setDate(date: number): number {
    return date.toString().length === 10 ? date * 1000 : date;
  }

  private scrollBottom(): void {
    const list = document.querySelector('.messages-list');
    const content = document.querySelector('.chat-container .content');

    let unreadHeight = 0;
    document.querySelectorAll('.message.unreaded').forEach((item: any) => {
      unreadHeight += item.offsetHeight + 11;
      item.classList.remove('unreaded');
    });

    if (list && !this.noScroll) {
      content.scrollTop = list.scrollHeight - unreadHeight;
    } else if (this.noScroll) {
      content.scrollTop = list.scrollHeight - this.chatPosition;
      setTimeout(() => {
        this.noScroll = false;
      }, 50);
    }
  }

  private loadMoreScroll(callback?) {
    const stage = document.querySelector('.chat-container .content');
    stage.addEventListener('scroll', function() {
      const stageTop = +stage.scrollTop;
      if (stageTop === 0) {
        if (callback) {
          callback();
        }
      }
    });
  }

  public personInfo(message): object {
    if (message.sender === 1) {
      const assigner =  this.messageList['assigners'][message.assigner_id]
      return {
        full_name: assigner.full_name,
        avatar: this.getAgentAvatar(assigner.crm_id) ? this.getAgentAvatar(assigner.crm_id) : this.getAgentAvatar(assigner.full_name),
      };
    } else if (message.sender === 2) {
      return this.messageList['user'];
    }
    return {
      full_name: 'default',
      avatar: 'http://flathash.com/default'
    }
  }

  public getHistory(page?): void {
    this.loader = true;
    this.subs.history = this.chatService.getMessageHistory(page).subscribe(res => {
      this.store.dispatch(new ChatActions.SetMessageHistory(res));
      this.loader = false;
      this.scrollBottom();
    });
  }

  public dateSeparator(date): boolean {
    date = date.toString().length === 10 ? date * 1000 : date;
    const messageDate = new Date(date).setHours(0, 0, 0, 0);

    if (this.historyDate < messageDate) {
      this.historyDate = messageDate;
      return true;
    }

    this.historyDate = messageDate;
    return false;
  }

  public getAgentAvatar(crm_id): string {
    return this.userService.getAvatar(crm_id);
  }

  private setStore(): void {
    this.chatService.getInfo().subscribe((res) => {
      this.store.dispatch(new ChatActions.SetAssigners(res.available_assigners));
      if (res.not_viewed) {
        this.store.dispatch(new ChatActions.SetNotViewedMessage(res.not_viewed));
      }
    });
  }
}
