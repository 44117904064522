import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutocompleteBoolComponent} from './autocomplete-bool.component';
import {
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatChipsModule,
    MatRippleModule,
    MatTooltipModule
} from '@angular/material';
import {MultipleSelectorModule} from '../multiple-selector/multiple-selector.module';
import {CustomTranslatePipeModule} from '../../../pipes/customTranslate/customTranslate.pipe.module';

@NgModule({
    declarations: [
        AutocompleteBoolComponent
    ],
  imports: [
    CommonModule,
    MultipleSelectorModule,
    MatButtonModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatTooltipModule,
    MatChipsModule,
    MatRippleModule,
    CustomTranslatePipeModule
  ],
    exports: [
        AutocompleteBoolComponent
    ],
    entryComponents: [
    ],
})
export class AutocompleteBoolModule {
}
