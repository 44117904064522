import {Pipe, PipeTransform} from '@angular/core';
import {User} from '../../services/users/user';
import storage from 'store';
import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'toCurrency'
})
export class ToCurrencyPipe extends DecimalPipe implements PipeTransform {

  private user = storage.get('user');

  transform(value: any, digitsInfo = '1.0-0'): string {
    value = value || 0;

    if (
      this.user.role === User.CONVERSION_UA ||
      this.user.role === User.LATAM ||
      this.user.role === User.LATAM_MANAGER
    ) {

      return '$' + super.transform(value, digitsInfo);

    } else {

      return super.transform(value * User.CURRENSY, digitsInfo) + 'R';

    }
  }
}
