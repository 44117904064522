export default class Maps {

    public maps: any = {};

    static create(reference, fieldName) {
        const map = {};
        for (let i = 0; i < reference.length; i++) {
            const elem = reference[i];
            map[elem[fieldName]] = reference[i];
        }
        return map;
    }

    constructor(initial?: any) {
        if (initial) {
            for (const name in initial) {
                if (initial.hasOwnProperty(name)) {
                    this.maps[name] = initial[name];
                }
            }
        }
    }

    public set(name: string, value: any) {
        this.maps[name] = value;
    }

    public get(name: string) {
        if (this.maps[name]) {
            return this.maps[name];
        }
        return {};
    }

    public findValue(name: string, value: any) {

        const map = this.get(name);

        if (!map[value]) {
            return {};
        }

        return map[value];
    }

}
