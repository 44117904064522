import { Component, OnInit, Input } from '@angular/core';
import {Store} from '@ngrx/store';
import * as ChatActions from '../../../../../actions/chat.actions';
import {UserService} from '../../../../../services/users/user.service';

@Component({
  selector: 'app-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.less']
})
export class BubbleComponent implements OnInit {

  @Input() message: any;

  private timer: any;

  constructor (
      private store: Store<any>,
      private userService: UserService,
  ) { }

  ngOnInit() {
    this.timer = setTimeout(() => {
      this.store.dispatch(new ChatActions.RemoveBubbleMessage(this.message.message.id));
    }, 4000);
  }

  public openChat(): void {
    // this.store.dispatch(new ChatActions.SetActiveAgent(this.message.user_id));
    this.store.dispatch(new ChatActions.ToggleChat(true));
    this.store.dispatch(new ChatActions.RemoveBubbleMessage(this.message.message.id));
  }

  public close(): void {
    clearTimeout(this.timer);
    this.store.dispatch(new ChatActions.RemoveBubbleMessage(this.message.message.id));
  }

  public getAgentAvatar(crm_id: number): string {
    return this.userService.getAvatar(crm_id);
  }
}
