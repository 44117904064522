import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-salary-projection-bar-modal',
  templateUrl: './salary-projection-bar-modal.component.html',
  styleUrls: ['./salary-projection-bar-modal.component.scss',]
})
export class SalaryProjectionBarModalComponent implements OnInit {

  public days: any[] = [];

  public tableData: any;
  public tabelColumns: string[] = ['name', 'progress', 'active', 'projection', 'info'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<SalaryProjectionBarModalComponent>,
  ) {
    this.self.addPanelClass('salary-projection-bar-modal-panel');
  }

  ngOnInit(): void {
    this.initHistogram();
    this.initTable();
    this.modifyTable();
  }

  public initHistogram() {
    const worked_days = this.data.worked_days;
    const current_month_left_working_days = this.data.current_month_left_working_days;
    const all_days = Math.ceil(worked_days + current_month_left_working_days);
    const days = new Array(all_days);
    for (let i = 0; i < all_days; i++) {
      days[i] = {
        active: i < worked_days,
        height: Math.random() * (100 - 25) + 25
      }
    }
    this.days = days;
  }

  public initTable() {
    const details = this.data.details;
    if (!details) { return; }
    const data = [];
    for (const key in details.Current) {
      let progress = ((details.Current[key] || 0) / (details.Projection[key] || 1)) * 100;
      if (progress < 0) { progress = 0; }
      if (progress > 100) { progress = 100; }
      progress = Math.abs(progress);
      data.push({
        name: key,
        progress,
        active: details.Current[key],
        projection: details.Projection[key],
        info: details.Info[key] || ''
      });
    }
    this.tableData = data;
  }


  public modifyTable() {
    if (!(this.tableData && Array.isArray(this.tableData))) { return; }
    this.tableData.forEach((value, index) => {
      if (value.name === 'Base Salary') {
        this.tableData[index].info = 'Dear Account Manager, this is your Base Salary Known to you already.'
      }
    });
  }
}


