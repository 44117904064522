import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DiplomasService {
  public courses: any = {
    diplomas: [
      {
        name:  `Business Administration in International Trading`,
        cost_tuition: `5625`,
        cost_registration: '1500',
        description: `This program prepares students with a solid foundation in international trade, including manufacturing, marketing, legal and financial aspects of business.
                      Students will learn the principles of accounting, inventory management, supply chain, and business skills in order to prepare them for a career in international business.`,
        job_opportunities: ["Exporter",
                            "Importer",
                            "Purchasing Agent",
                            "Inventory planner",
                            "Logistics Manager"],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                        Score 5.5 On IELTS Test`,
                       `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                       `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/business-administration-in-international-trading/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Business+Administration+in+International+Trading.jpg',
        duration: '49 weeks / 980 hours'
      },
      {
        name: `Community Service Worker`,
        cost_tuition: `5962.50`,
        cost_registration: '1500',
        description: `With an emphasis on social services, this program prepares students with the sociology, psychology, and other social science foundations that enable them to counsel and assist individuals (and their families) in personal and social situations.`,
        job_opportunities: ["Counselor",
                            "Social Services"],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                        Score 5.5 On IELTS Test`,
                        `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                        `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/community-service-worker/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Community+Service+Worker.jpg',
        duration: '55 weeks / 1040 hours'
      },
      {
        name: `Computer System and Network Specialist`,
        cost_tuition: `5962.50`,
        cost_registration: '1500',
        description: `This program will prepare you to design, install, maintain, manage, and improve computer networks. Students will be equipped with knowledge of local area networks; its application in wide area networks; and its interconnectivity to nodes, servers and other end-user devices. Students will work with various types of software, including client software and management systems, web page creation and integration, network security measures, user control, and network event log monitoring for troubleshooting.`,
        job_opportunities: ["Computer Network Support",
                            "IT Support",
                            "Network Installation",
                            "Network Administration",
                            "Network Design & Integration"],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                        Score 5.5 On IELTS Test`,
                       `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                       `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/computer-system-and-network-specialist/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Computer+System+and+Network+Specialist.jpg',
        duration: '33 weeks / 600 hours'
      },
      {
        name: `Computerized Accounting and Payroll`,
        cost_tuition: `7380`,
        cost_registration: '1500',
        description: `In this program, you will learn how to keep journal and accounting books, how to prepare reports for small businesses, and how to use financial statements for business planning..`,
        job_opportunities: ["General Accountant",
                            "Payroll",
                            "Accounts",
                            "Tax",
                            "Bookkeeper",
                            "Financial Clerk"],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                        Score 5.5 On IELTS Test`,
                       `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                       `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/computerized-accounting-and-payroll/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Computerized+Accounting+and+Payroll.jpg',
        duration: '43 weeks / 900 hours'
      },
      {
        name: `Customer Service Specialist`,
        cost_tuition: `5287.50`,
        cost_registration: '1500',
        description: `This program prepares you to become a capable and enthusiastic customer service professional. You will learn to provide service and support that is reliable, responsive and empathetic, while meeting customer needs and enhancing business relationships.`,
        job_opportunities: ["Customer Support",
                            "Client Management",
                            "Customer Service",
                            "Customer Relations"],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                       Score 5.5 On IELTS Test`,
                       `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                       `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/customer-service-specialist/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Customer+Service+Specialist.jpg',
        duration: '55 weeks / 1,040 hours'
      },
      {
        name: `Developmental Service Worker`,
        cost_tuition: `7312.50`,
        cost_registration: '1500',
        description: `This program will provide students with an in-depth understanding of developmental disabilities, along with the support skills required to help others and build a career in this field of social services.`,
        job_opportunities: ["Supporting people with developmental disabilities",
                            "At schools and culture based institutions"],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                        Score 5.5 On IELTS Test`,
                        `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                        `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/developmental-service-worker/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Developmental+Service+Worker.jpg',
        duration: '56 weeks / 1500 hours'
      },
      {
        name: `Early Childhood Assistant`,
        cost_tuition: `5962.50`,
        cost_registration: '1500',
        description: `The Early Childhood Assistant Diploma Program has been designed to equip caregivers of children with the valuable knowledge, skills and experience for employment as assistants in day care centers or early learning centers. In addition to covering early childhood development, program planning, communication, and other vital topics in child care, the program also includes the role of the assistant in a child care setting.`,
        job_opportunities: ["Personal Caregiver",
                            "Or at schools and nurseries"],
                            requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                            Score 5.5 On IELTS Test`,
                            `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                            `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- This course will also allow you to apply for a Caregiver Visa and take that route to permanent residency`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/early-childhood-assistant/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Early+Childhood+Assistant.jpg',
        duration: '56 weeks / 1500 hours'
      },
      {
        name: `Hospitality Management`,
        cost_tuition: `7312.50`,
        cost_registration: '1500',
        description: `The Diploma in Hotel Management will equip you with the knowledge and skills necessary to fulfill leadership roles that focus on providing your guests with exceptional experiences. You will learn a combination of management strategies focused on the development of your service skills, leadership skills, guest interactions, communication, and hospitality science.`,
        job_opportunities: ["Supervisor and Management Roles at a luxury hotel, resort, restaurant, tourist attraction, club, cruise line, and much more."],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                        Score 5.5 On IELTS Test`,
                        `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                        `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/hospitality-management/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Hospitality+Management.jpg',
        duration: '88 weeks / 1,640 hours'
      },
      {
        name: `Hospitality Operation`,
        cost_tuition: `5287.50`,
        cost_registration: '1500',
        description: `This program will equip you for entry positions in a fast-paced and rewarding career field within travel and tourism. After just one year of study, students will complete an internship and begin networking within the industry. For those interested in management roles, this program prepares students for Evergreen's two-year Hospitality Management Diploma Program.`,
        job_opportunities: ["Hospitality Operations Manager at  a luxury hotel, resort, restaurant, tourist attraction, club, cruise line, and much more."],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                        Score 5.5 On IELTS Test`,
                        `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                        `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- This course will also allow you to apply for a Caregiver Visa and take that route to permanent residency`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/hospitality-operations/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Hospitality+Operation.jpg',
        duration: '55 weeks / 480 hours'
      },
      {
        name: `Medical Office Administrator`,
        cost_tuition: `5962.50`,
        cost_registration: '1500',
        description: `In this program, students will learn to take medical records, explain treatment procedures, and prepare patients for exams. Emphasis is placed on medical terminology, body systems, medical transcription, and office procedures.`,
        job_opportunities: ["Medical Office Assistant",
                            "Medical Office Administrator",
                            "Medical Secretary",
                            "Medical Stenographer",
                            "Medical Billing Specialists",
                            "Wing Secretary",
                            "Medical Transcriber."],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                        Score 5.5 On IELTS Test`,
                        `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                        `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/medical-office-administrator/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Medical+Office+Administrator.jpg',
        duration: '32 weeks / 675 hours'
      },
      {
        name: `Personal Support Worker`,
        cost_tuition: `5119.20`,
        cost_registration: '1500',
        description: `If you value caring for others, it is possible to enjoy a career as a personal support worker (TAP), where you will be a valued member of an interprofessional care team. In this role, their main functions include caring for people and assisting in their daily life so that they can maintain independence and self-direction.`,
        job_opportunities: ["TAPs are used in long-term care facilities, home care settings, nursing homes, supportive housing, group homes, adult day programs, hospitals, and educational facilities."],
        requirements: [`17 Years Or older with Ontario  High School Diploma or equivalent WES Evaluation for HighSchool
                        Score 5.5 On IELTS Test`,
                        `Degree/Diploma with WES evaluation for equivalency of college or university degree with IELTS 5.5 or higher`,
                        `19 years or older and pass Wonderlic Entry Test`],
        notes: [`- The school needs to send the curiculum and applicant grades to WES in English`,
                `- This course will also allow you to apply for a Caregiver Visa and take that route to permanent residency`,
                `- IELTS Course is prefered and will increase chances of getting a Student Visa`],
        link: `https://www.evergreencollege.com/programs/personal-support-worker/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Personal+Support+Worker.jpg',
        duration: '31 weeks / 700 hours'
      }
    ],
    non_diplomas: [
      {
        name: `Food Service Worker`,
        cost_tuition: `2722.50`,
        cost_registration: '1500',
        description: `The Food Service Worker (FSW) program will prepare students to perform effectively and efficiently in various healthcare institutions such as long-term care homes, nursing homes, nursing homes, commercial and institutional food service.`,
        job_opportunities: ["Volunteer Work while on Tourist Visa"],
        requirements: [`No admissions requirements - Anyone can apply for this
                       Student may need to go through English test to find their level)`],
        notes: [`- Cannot be used to Obtain a Student Visa`],
        link: `https://www.evergreencollege.com/programs/food-service-worker/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Food+Service+Worker.jpg',
        duration: '12 weeks / 300 hours'
      },
      {
        name: `English Preparation Programme`,
        cost_tuition: `1687.50`,
        cost_registration: '1500',
        description: `Students who progress and improve their level of English before beginning their studies are able to thrive in the professional diploma programs offered at Evergreen University.
                      Serves as English preperation course to increase Wonderlic / IELTS Score`,
        job_opportunities: ["N/A"],
        requirements: [`No admissions requirements - Anyone can apply for this
                       Student may need to go through English test to find their level)`],
        notes: [`- Cannot be used to Obtain a Student Visa`,
                `- IF Client Performed Bad in IELTS / Wonderlic - They can do this to get a better score`],
        link: `https://www.evergreencollege.com/programs/english-preparation-program/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_English+Preperation+Programme.jpg',
        duration: '12 weeks / 240 hours'
      }
    ],
    post_graduate_programs: [
      {
        name: `Business in the Digital Era`,
        cost_tuition: `6975`,
        cost_registration: '1500',
        description: `The Business in a Digital Era Post-Graduate program is a unique and advanced course designed to conceptualize business concepts in today’s global digital age. This course strives to prepare students for the challenges of the modern corporate world so that they will become successful entrepreneurs and business leaders.
                      Students will learn key concepts related to business strategy, finance, human resources management, marketing and operations with a focus on digital trends like e-commerce and big data analytics to forecast and understand business trends, digital marketing and recruitment.`,
        job_opportunities: ["Occupations in advertising, marketing, and public relations, which are typically employed by consulting firms, advertising agencies, corporations, associations, government, social agencies, museums, galleries, public interest groups, and cultural and other organizations, or they can be autonomous. Agents such as sports agents for entertainment, literary and are included in this primary group."],
        requirements: [`Degree/Diploma with WES evaluation for equivalency of college or university degree with ILETS 5.5 or higher.`],
        notes: [`- Do NOT recommend to somone without a Degree`,
                `- IELTS is mandatory, No Wonderlic`,
                `- The school needs to send the curiculum and applicant grades to WES in English`],
        link: `https://www.evergreencollege.com/programs/business-in-the-digital-era/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Business+in+the+Digital+Era.jpg',
        duration: '49 weeks / 980 hours'
      },
      {
        name: `Clinical Research`,
        cost_tuition: `6975`,
        cost_registration: '1500',
        description: `Students will learn how to generate and validate hypotheses; bibliographic database research; Study design Clinical trial; write clinical trial protocols; collect data from clinical research; apply statistical tests for the analysis of collected clinical research data; and work with stakeholders, including pharmaceutical, biotech, and medical device companies; contract research organizations, regulatory agencies; Principal researchers; and research institutions. Students also learn how to deal with ethical issues, apply quality standards, prepare human research ethics applications, monitor databases and clinical safety, evaluate adverse events received.`,
        job_opportunities: ["Lab Assistant",
                            "Medical Scientist"],
        requirements: [`Degree/Diploma with WES evaluation for equivalency of college or university degree with ILETS 5.5 or higher.`],
        notes: [`- Do NOT recommend to somone without a Degree`,
                `- IELTS is mandatory, No Wonderlic`,
                `- The school needs to send the curiculum and applicant grades to WES in English`],
        link: `https://www.evergreencollege.com/programs/clinical-research/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Clinical+Research.jpg',
        duration: '49 weeks / 980 hours'
      },
      {
        name: `Cyber Security & Cloud Computing`,
        cost_tuition: `11025`,
        cost_registration: '1500',
        description: `This diploma is designed to provide a solid foundation in conventional computer networking, network security, cloud computing concepts, and database skills.`,
        job_opportunities: ["Graduates typically find entry-level employment in positions of computer network support, network installation, network administration, network design, and integration."],
        requirements: [`Degree/Diploma with WES evaluation for equivalency of college or university degree with ILETS 5.5 or higher.`],
        notes: [`- Do NOT recommend to somone without a Degree`,
                `- IELTS is mandatory, No Wonderlic`,
                `- The school needs to send the curiculum and applicant grades to WES in English`],
        link: `https://www.evergreencollege.com/programs/cyber-security-and-cloud-computing/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_Cyber+Security+%26+Cloud+Computing.jpg',
        duration: '97 weeks / 1,820 hours'
      },
      {
        name: `Network System Engineer`,
        cost_tuition: `6975`,
        cost_registration: '1500',
        description: `Evergreen University's Network Systems Engineering program will further prepare you to design, install, maintain, manage, and improve computer networks. Students are equipped with practical knowledge of local area networks (LANs); its application in wide area networks (WAN); and its interconnectivity to nodes, servers and other end-user devices. Students will also become familiar with the administration of web servers and their connectivity to LAN and WAN networks.`,
        job_opportunities: ["Graduates will be equipped with the skills and knowledge to obtain employment in computer network support, network installation, Linux management exchange server administration, system and network administration, network design, and integration."],
        requirements: ["Degree/Diploma with WES evaluation for equivalency of college or university degree with ILETS 5.5 or higher."],
        notes: [`- Do NOT recommend to somone without a Degree`,
                `- IELTS is mandatory, No Wonderlic`,
                `- The school needs to send the curiculum and applicant grades to WES in English`],
        link: `https://www.evergreencollege.com/programs/network-system-engineer/`,
        img: 'https://cv-media-files.s3-us-west-2.amazonaws.com/images/evergreen/img_networksystemsengineer.jpg',
        duration: '47 weeks / 880 hours'
      }
    ]
  }

  public courseFields = [
    {
      label: "Cost",
      value: "cost"
    },
    {
      label: "Description",
      value: "description"
    },
    {
      label: "Job Opportunities",
      value: "job_opportunities"
    },
    {
      label: "Requirements",
      value: "requirements"
    },
    {
      label: "Notes",
      value: "notes"
    },
    {
      label: "Link",
      value: "link"
    }
  ];
}
