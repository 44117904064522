import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PaymentNotificationsComponent} from './payment-notifications.component';
import {
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatIconModule,
  MatProgressSpinnerModule, MatTooltipModule
} from '@angular/material';
import { BubbleMessageComponent } from './bubble-message/bubble-message.component';
import { PaymentContentComponent } from './payment-content/payment-content.component';
import { PaymentWindowComponent } from './payment-window/payment-window.component';
import {ChatContentModule} from '../manager-chat/chat-content/chat-content.module';
import {MessagesListModule} from '../manager-chat/chat-content/messages-list/messages-list.module';
import { BubbleComponent } from './bubble-message/bubble/bubble.component';
import {NbButtonModule, NbCardModule, NbSpinnerModule} from '@nebular/theme';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [PaymentNotificationsComponent, BubbleMessageComponent, PaymentContentComponent, PaymentWindowComponent, BubbleComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    ChatContentModule,
    MessagesListModule,
    MatCardModule,
    NbCardModule,
    NbSpinnerModule,
    NbButtonModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    FormsModule,
    MatTooltipModule
  ],
  exports: [
      PaymentNotificationsComponent
  ]
})
export class PaymentNotificationsModule { }
