import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CallBarComponent } from './call-bar.component';
// import { CounterDirective } from '../../../directives/counter.directive';
import { MatIconModule } from '@angular/material/icon';
import {CounterDirectiveModule} from '../../../directives/counter.directive.module';
import {RegisterLeadModalComponent} from '../../../../modals/register-lead-modal/register-lead-modal.component';
import {RegisterLeadModalModule} from '../../../../modals/register-lead-modal/register-lead-modal.module';
import {CreateLeadModalComponent} from '../../../../general/create-lead-modal/create-lead-modal.component';
import {CreateLeadModalModule} from '../../../../general/create-lead-modal/create-lead-modal.module';

@NgModule({
  declarations: [
    CallBarComponent,
    // CounterDirective
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,
    CounterDirectiveModule,
    CreateLeadModalModule,
    RegisterLeadModalModule
  ],
  exports: [
    CallBarComponent,
    // CounterDirective
  ],
  entryComponents: [
    RegisterLeadModalComponent,
    CreateLeadModalComponent
  ]
})
export class CallBarModule {
}
