import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() notification;
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() readed: EventEmitter<any> = new EventEmitter<any>();

  public deleting: boolean;

  constructor() { }

  ngOnInit() {
  }

  public getUpdatedAtTime(notification) {
    if (['ticket_visa_respond', 'ticket_reply'].includes(notification.action)) {
      return notification.object_model.comments[0].updated_at;
    } else if(notification.action === 'visa_updates') {
      return notification.additional_info.updated_at;
    } else {
      return notification.object_model.updated_at;
    }
  }

  public onDelete(evt) {
    this.deleting = true;
    evt.stopPropagation();
    this.delete.emit();
  }

  public onReaded(evt) {
    evt.stopPropagation();
    this.readed.emit();
  }

}
