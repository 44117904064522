import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MilestonesModalComponent } from './milestones-modal.component';

import { ProgressLoaderModule } from '../../general/progress-loader/progress-loader.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
    MilestonesModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    ProgressLoaderModule,
    TranslateModule,

    NgCircleProgressModule.forRoot({

    })
  ],
  exports: [
    MilestonesModalComponent
  ],
  entryComponents: [
    MilestonesModalComponent
  ]
})
export class MilestonesModalModule {
}
