import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
      private api: ApiService
  ) { }

  public getMessageHistory(page: number = 1, offset: number = 50): Observable<any> {
    return this.api.get(`/messages/${page}/${offset}`, {});
  }

  public sendMessage(message): Observable<any> {
    return this.api.post('/messages', {
      body: message
    });
  }
  public getInfo(): Observable<any> {
    return this.api.get('/messages/info', {});
  }
}
