import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { QuizService } from 'src/app/services/quiz/quiz.service';
import { answersVariants } from '../constants/answersVariants';
import { selectorTypes } from '../constants/selectorTypes';
import { QuizRequestComponent } from '../quiz-request/quiz-request.component';

@Component({
  selector: 'app-quiz-result',
  templateUrl: './quiz-result.component.html',
  styleUrls: ['./quiz-result.component.scss',],
  encapsulation: ViewEncapsulation.None
})
export class QuizResultComponent implements OnInit {

  public loader: boolean = true;

  public passedVariants = {
    waiting: 0,
    passed: 1,
    failed: 2,
    timeOut: 3
  }
  public passed: number = this.passedVariants.waiting;
  public passedText = {
    0: 'Waiting for result',
    1: 'Quiz Passed',
    2: 'Quiz Failed',
    3: 'RUN OUT OF TIME'
  }

  public info: any|{ id: number } = {};

  public timeOut: boolean;

  public result = {
    score: 0,
    total_score: 0,
    scorePct: 0,
    //
    right_questions: 0,
    amount_questions: 0,
    notAllQuestions: false,
    
    passing_mark: 0,
    passingPct: 0
  };

  public resultData: any;
  
  public selectorTypes = selectorTypes;

  public answers: any[] = [];
  public questions: any[] = [];
  public correct: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matSnack: MatSnackBar,
    private self: MatDialogRef<QuizResultComponent>,
    private matDialog: MatDialog,
    // private quizService: QuizService,
  ) {
    this.info = data.info;
    // this.test();
    this.answers = data.answers;
    this.timeOut = data.timeOut;

    this.resultData = data.data;
  }

  ngOnInit(): void {
    if (this.resultData) {
      if (this.resultData.Result) {
        this.info.share_result = true;
        this.setShareResult(this.resultData);
      }
      if (this.resultData.FormFields) {
        this.info.show_correct_answers = true;
        this.setCorrectAnswers(this.resultData);
      }
    } else {
      this.self.close();
      this.onError({ error: 'Can\'t show result' });
    }

  }

  private setShareResult(res) { 
    const result = res.Result;
    // if (!result) {
    //   this.info.share_result = false;
    //   return; 
    // }
    this.result = Object.assign(this.result, result);
    this.result.scorePct = Math.round(result.score / (result.total_score || 1)* 100);
    this.result.notAllQuestions = result.right_questions !== result.amount_questions;
    this.result.passingPct = Math.round(result.passing_mark / (result.total_score || 1) * 100);
    //
    if (this.timeOut) {
      if (result.score >= result.passing_mark) {
        this.passed = this.passedVariants.passed;
      } else {
        this.passed = this.passedVariants.timeOut;
      }
    } else {
      if (result.score >= result.passing_mark) {
        this.passed = this.passedVariants.passed;
      } else {
        this.passed = this.passedVariants.failed;
      }
    }
    
  }

  public answersVariants = answersVariants;
  public noAnswers: any = {};


  private setCorrectAnswers(res) {
    const questions = res.FormFields || [];
    // if (!questions.length) { 
    //   this.info.show_correct_answers = false;
    //   return; 
    // }
    questions.forEach((question) => {
      if (!question.general_checked) {
        question.general_checked = new Array(Object.keys(question.options).length).fill(false);
      }
      const newOptions = [];
      const newWeights = [];
      for (const index in question.options) {
        newOptions[index] = question.options[index];
        newWeights[index] = question.weights[index];
      }
      question.options = newOptions;
      question.weights = newWeights;
    });
    this.questions = questions;
    // correct

    questions.forEach((question, qI) => {
      this.correct[qI] = new Array(question.options.length);
      question.options.forEach((option, oI) => {
        if (question.type === this.selectorTypes.text) {
          // text
          if (this.answers[qI]) {
            this.correct[qI][oI] = this.answersVariants.correct;
          } else {
            // this.correct[qI][oI] = this.answersVariants.incorrect;
            this.noAnswers[qI] = true;
          }
          // text
        } else if (question.type === this.selectorTypes.checkbox) {
          // checkbox
          if (!this.answers[qI].find(el => el)) {
            if (question.answer_type === 'general' && !question.general_checked.find(el => el)) {
              this.noAnswers[qI] = -1;
              return
            } else {
              this.noAnswers[qI] = true;
              return;
            }
          }
          //
          let checker: any;
          if (question.answer_type === 'each') {
            checker = question.weights[oI];
          } else if (question.answer_type === 'general') {
            checker = question.general_checked[oI];
          }
          //
          if (this.answers[qI][oI] && checker) {
            this.correct[qI][oI] = this.answersVariants.correct;
          } else if (this.answers[qI][oI] && !checker) {
            this.correct[qI][oI] = this.answersVariants.incorrect;
          } else if (!this.answers[qI][oI] && checker) {
            this.correct[qI][oI] = this.answersVariants.rightAnswer;
          } else {
            this.correct[qI][oI] = this.answersVariants.noAnswer;
          }
          // checkbox
        } else if (question.type === this.selectorTypes.radiobox) {
          const userChecked = this.answers[qI];
          // radiobox
          if (userChecked === null || userChecked === -1) {
            this.noAnswers[qI] = true;
            return;
          }
          //
          if (question.answer_type === 'each') {
            if (userChecked === oI) {
              if (question.weights[oI]) {
                this.correct[qI][oI] = this.answersVariants.correct;
              } else {
                this.correct[qI][oI] = this.answersVariants.incorrect;
              }
            } else {
              if (question.weights[oI]) {
                this.correct[qI][oI] = this.answersVariants.rightAnswer;
              } else {
                this.correct[qI][oI] = this.answersVariants.noAnswer;
              }
            }
          } else if (question.answer_type === 'general') {
            if (userChecked === oI) {
              if (userChecked === question.general_radio) {
                this.correct[qI][oI] = this.answersVariants.correct;
              } else {
                this.correct[qI][oI] = this.answersVariants.incorrect;
              }
            } else {
              if (question.general_radio === oI && userChecked !== question.general_radio) {
                this.correct[qI][oI] = this.answersVariants.rightAnswer;
              } else {
                this.correct[qI][oI] = this.answersVariants.noAnswer;
              }
            }
          }
          // radiobox
        }
      });
    });
  }


  public onFinish() {
    this.self.close();
  }

  public onRequest() {
    this.self.close();
    this.matDialog.open(QuizRequestComponent, {
      disableClose: true,
      data: {
        quiz_id: this.info.id
      }
    });
  }
  
  private onError(err) {
    this.loader = false;
    let msg;
    if (err.error) {
      msg = JSON.stringify(err.error)
    } else if (err.result) {
      msg = err.result;
    }
    this.matSnack.open(msg, 'Dismiss', {
      duration: 3000,
      panelClass: ['error-snackbar']
    });
  }
}
