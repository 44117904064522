import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Rx';

@Injectable({
    providedIn: 'root'
})
export class EditFieldsService {

    constructor(private api: ApiService) {

    }

    public massEditField(type, body, ids): Observable<any> {


        return this.api.post('/' + type + '/mass-edit', {
            body: {
                data: body,
                ids: ids,
            }
        });
    }

    public editField(type, id, spouseId, body): Observable<any> {
        if (type === 'spouse') {
            if (spouseId) {
                return this.editSpouse(spouseId, body);
            }
            return this.createSpouse(id, body);
        }
        return this.api.post('/' + type + '/' + id + '/' + 'info', {
            body: body
        });
    }

    public editFieldFromPopup(type, id, spouseId, body): Observable<any> {
        if (type === 'spouse') {
            if (spouseId) {
                return this.editSpouse(spouseId, body);
            }
            return this.createSpouse(id, body);
        }
        return this.api.post('/' + type + '/' + id + '/' + 'info/tech', {
            body: body
        });
    }

  public assistByPopup(code): Observable<any> {

    return this.api.post('/assist/', {
      body: code
    });
  }

    public create(body): Observable<any> {
        return this.api.post('/lead_spouses', {
            body: body
        });
    }

    public editSpouse(spouseId, body): Observable<any> {
        return this.api.post('/lead_spouses/' + spouseId, {
            body: Object.assign({
                id: spouseId,
            }, body)
        });
    }

    public createSpouse(id, body): Observable<any> {
        return this.api.post('/lead_spouses', {
            body: Object.assign({lead_id: id}, body)
        });
    }
}
