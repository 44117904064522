import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import SearchResponse from '../../../../response/search.response';
import { EventBusService } from '../../../../services/event-bus/event-bus.service';
import { TicketsService } from '../../../../services/tickets/tickets.service';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Input() items: Array<any> = null;
  @Input() expanded: boolean;

  public $countUnreadTickets: any = 0;
  public activeFolder = null;
  public tickets: SearchResponse = new SearchResponse();
  public ticketsData: any = {};

  public lang: string;
  public langs: string[];


  constructor(
    private eventBus: EventBusService,
    private ticketsService: TicketsService,
    private translateService: TranslateService
  ) {
    this.lang = translateService.currentLang;
    this.langs = translateService.langs;
  }

  ngOnInit(): void {
    const hasTicketItem = this.items.findIndex(i => i.type === 'tickets');
    if (hasTicketItem !== -1) {
      this.ticketsService
        .getCountTicketsBouble()
        .subscribe((response) => {
          this.$countUnreadTickets = response.count;
        });
    }

    this.eventBus.on('unreadTickets').subscribe((data) => {
      this.$countUnreadTickets = data;
    });
  }

  public langsOpened: boolean;
  public onOpenLangs() {
    this.langsOpened = !this.langsOpened;
  }
  public onSelectLang(lang) {
    // this.translateService.use(lang);
    localStorage.setItem('lang', lang)
    // this.lang = lang;
    // this.langsOpened = false;

    window.location.reload();
  }

}
