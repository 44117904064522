
import localStore from 'store';

let cPSWinSt;

export function canPersonalSettings(key: string): boolean {  
  if (!cPSWinSt) { 
    cPSWinSt = localStore.get('settings').personal; 
  };
  if (cPSWinSt && cPSWinSt[key]) {
    const setting = cPSWinSt[key] || {};
    switch (setting['type']) {
      case 'bool':
        return Boolean(setting['value'] && setting['value']['value']);
      default:
        console.warn('Not implemented functional for using personal settings "' + setting['type'] + '"');
        break;
    }
  }
  return false;
}