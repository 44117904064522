import {Action} from '@ngrx/store';
import {User} from '../services/users/user';

export  enum UserActionTypes {
    SET_USER = '[User] SetUser'
}

export  class  SetUser implements Action {
    readonly type = UserActionTypes.SET_USER;
    constructor(public payload: User) {}
}

export type UserActionUnion = SetUser;