import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class QuestionerService {

  constructor(
    private api: ApiService
  ) {}

  public update(lead_id: number, body: any): Observable<any> {
    return this.api.post(`/leads/${lead_id}/questionnaire`, { body });
  }

  public switchSpouseToMain(lead_id: number, body: any): Observable<any> {
    return this.api.post(`/leads/${lead_id}/questionnaire/switch`, { body });
  }

  public get(lead_id: number, type: string, id: number): Observable<any> {
    return this.api.get(`/leads/${lead_id}/questionnaire?type=${type}&id=${id}`, {});
  }

  public delete(id: number): Observable<any> {
    return this.api.delete('/lead_spouses/' + id, {});
  }

}
