import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';

import { Progress15Component } from './progtess-15.component';

@NgModule({
  declarations: [
    Progress15Component
  ],
  imports: [
    CommonModule,
    
    MatTooltipModule,
  ],
  exports: [
    Progress15Component
  ]
})
export class Progress15Module {
}
