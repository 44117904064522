import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import _findLast from 'lodash/findLast';

@Component({
  selector: 'app-monthly-goal-vouchers-modal',
  templateUrl: './monthly-goal-vouchers-modal.component.html',
  styleUrls: ['./monthly-goal-vouchers-modal.component.scss']
})
export class MonthlyGoalVouchersModalComponent implements OnInit {

  public selected = null;

  private lastLevel = 45000;

  public vouchers = [
    {
      img: '../../../../assets/img/header/voucher-modal/bronze.png',
      target: 30000,
      title: 'bronze'
    },
    {
      img: '../../../../assets/img/header/voucher-modal/silver.png',
      target: 40000,
      title: 'silver'
    },
    {
      img: '../../../../assets/img/header/voucher-modal/gold.png',
      target: this.lastLevel,
      title: 'gold'
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (this.data.commission) {
      this.selected = _findLast(this.vouchers, (item) => item.target <= this.data.commission.collected);
    }
  }

  public get progress(): number {
    if (this.data.commission.collected) {
      if (this.lastLevel < this.data.commission.collected) {
        return 100;
      }

      return 100 / (this.lastLevel / this.data.commission.collected);
    }

    return 0;
  }

  ngOnInit() {}

}
