import {Component, forwardRef, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors, Validator
} from '@angular/forms';
import {uniqueValues} from '../../validators/uniqueValues';
import {NOTIFICATION_TYPES} from '../../constants/notifications';

@Component({
  selector: 'app-schedule-notification',
  templateUrl: './schedule-notification.component.html',
  styleUrls: ['./schedule-notification.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ScheduleNotificationComponent
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ScheduleNotificationComponent),
      multi: true,
    }
  ]
})
export class ScheduleNotificationComponent implements OnInit, ControlValueAccessor, Validator {

  public readonly notificationTypes = NOTIFICATION_TYPES;

  public readonly LIMIT = 3;

  public control: FormArray = new FormArray([], [uniqueValues()]);

  public touched = false;
  public disabled = false;

  onChange = (value) => {};
  onTouched = () => {};
  onValidationChange: any = () => {};

  constructor() { }

  ngOnInit() {
    this.control.valueChanges.subscribe(value => {
      this.onChange(value);

      this.onValidationChange();
    });
  }

  public addNotification(): void {
    const count = this.control.value.length;
    const defaultIndex = count === 1 ? 3 : count === 2 ? 4 : 1;
    this.control.push(new FormControl(this.notificationTypes[defaultIndex].value));
  }

  public delete(index: number): void {
    this.control.removeAt(index);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.control.errors) {
      return this.control.errors;
    } else {
      return null;
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  writeValue(control: any): void {
    if (control) {
      if (this.control.controls.length) {
        while (this.control.controls.length > 0) {
          this.control.removeAt(0);
        }
      }

      control.forEach(i => {
        this.addNotification();
      });

      this.control.setValue(control);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
