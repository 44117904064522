import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material';
import {InstallmentService} from '../../../../services/installment/installment.service';
import {InstallmentModalComponent} from './installment-modal/installment-modal.component';
import {InstallmentNotificationService} from '../../../../services/installment/installmentNotification.service';

@Component({
  selector: 'app-installment-notification',
  templateUrl: './installment-notification.component.html',
  styleUrls: ['./installment-notification.component.scss']
})
export class InstallmentNotificationComponent implements OnInit {

  public count: number = null;

  constructor(
    private matDialog: MatDialog,
    private installmentService: InstallmentService,
    private installmentNotificationService: InstallmentNotificationService
  ) { }

  ngOnInit() {
    this.installmentNotificationService.currentCount.subscribe((count) => {
      this.count = count;
    });

    this.getData();
  }

  private getData(): void {
    this.installmentService.getInstallments({
      count: true
    }).subscribe((res) => {
      this.count = res.count || 0;

      this.installmentNotificationService.setCount(this.count);
    });
  }

  public openInstallmentModal(): void {
    this.matDialog.open(InstallmentModalComponent, {
      panelClass: 'installment-modal',
      width: '900px',
      autoFocus: false
    }).afterClosed().subscribe((result) => {
        this.getData();
      }
    );
  }

}
