import {
  PaymentLinkActionsType,
  PaymentLinksActionsUnion} from "../actions/payment_link.action";

const initialState = {
  error: null,
  response: null,
};

export function paymentLinkReducer(state: any = initialState, action: PaymentLinksActionsUnion) {
  switch (action.type) {
    case PaymentLinkActionsType.SUCCESS_SENT_PAYMENT_LINK: {
      return {
        ...state,
        error: null,
        response: action.payload,
      };
    }
    case PaymentLinkActionsType.ERROR_SENT_PAYMENT_LINK: {
      return {
        ...state,
        response: null,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
