import {ChatActionsUnion, ChatActionsType} from '../actions/chat.actions';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _mergeWith from 'lodash/mergeWith';
import _isArray from 'lodash/isArray';

const initiaState = {
    isOpen: false,
    activeAgent: null,
    assigners: [],
    bubbles: [],
    not_viewed: 0,
    messages: null,
}

function customizer(objValue, srcValue) {
    if (_isArray(objValue)) {
        return objValue.concat(srcValue);
    }
}

export function chatReducer(state: any = initiaState, action: ChatActionsUnion) {
    switch(action.type) {
        case ChatActionsType.TOGGLE_CHAT: {
            return {
                ...state,
                isOpen: typeof action.payload !== 'undefined' ? action.payload : !state.isOpen
            };
        }
        case ChatActionsType.SET_BUBBLE_MESSAGE: {
            return {
                ...state,
                bubbles: [...state.bubbles, action.payload]
            };
        }
        case ChatActionsType.REMOVE_BUBBLE_MESSAGE: {
            _remove(state.bubbles, (o) => o.message.id === action.payload)
            return {
                ...state,
                bubbles: [...state.bubbles]
            };
        }
        case ChatActionsType.SET_MESSAGE_HISTORY: {
            let newState =  {
              items: [],
              _meta: null
            };
            if (action.payload) {
              if (action.payload.meta.page === 1) {
                newState = action.payload;
              } else {
                newState = _mergeWith(state.messages, action.payload, customizer);
              }
            }

            return {
                ...state,
                messages: newState
            };
        }
        case ChatActionsType.SET_MESSAGE: {
            state.messages.items.unshift(action.payload);
            return {
                ...state,
                messages: Object.assign({}, state.messages)
            };
        }
        case ChatActionsType.SET_ASSIGNERS: {
          let newAssigners = [];
          Object.keys(action.payload).forEach((key) => {
            newAssigners.push(action.payload[key]);
          });

          return {
            ...state,
            assigners: [...state.assigners, ...newAssigners]
          };
        }
        case ChatActionsType.SET_NOT_VIEWED_MESSAGE: {
          return {
            ...state,
            not_viewed: action.payload
          };
        }
        case ChatActionsType.UPDATE_ASSIGNERS: {
            return {
                ...state,
                assigners: state.assigners.filter((agent) => {
                  return !_find(action.payload,  {full_name: agent.full_name});
                })
            };
        }
        default: {
            return state;
        }
    }
}
