import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-agent-notofication-modal',
    templateUrl: './agent-notofication-modal.component.html',
    styleUrls: ['./agent-notofication-modal.component.scss']
})
export class AgentNotoficationModalComponent implements OnInit, AfterViewInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private ref: ChangeDetectorRef,
                public self: MatDialogRef<AgentNotoficationModalComponent>) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.ref.detectChanges();
    }

}
