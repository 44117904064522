import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MatBottomSheetModule,
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule, MatListModule
} from '@angular/material';
import {NewAgentsSheetComponent} from './agents-sheet.component';

@NgModule({
  declarations: [NewAgentsSheetComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatBottomSheetModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatListModule,
  ],
  exports: [
    NewAgentsSheetComponent
  ],
  entryComponents: [
    NewAgentsSheetComponent
  ],
})
export class AgentsSheetModule {
}
