import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs/Rx';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';
import {map, startWith} from 'rxjs/internal/operators';

@Component({
    selector: 'app-multiple-selector',
    templateUrl: './multiple-selector.component.html',
    styleUrls: ['./multiple-selector.component.scss']
})
export class MultipleSelectorComponent implements OnInit {

    public selected: any = [];

    public selection: any = [];

    public autoComplete: FormControl = new FormControl();

    public filteredOptions: Observable<any[]>;

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
                private self: MatBottomSheetRef<MultipleSelectorComponent>) {
        if (this.data.selected) {
            this.selected = this.data.selected;
        }

        this.selection = this.data.selection;

    }

    ngOnInit() {
        this.filteredOptions = this.autoComplete.valueChanges.pipe(
            startWith(''),
            map((value) => {
                return this.selection.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));
            })
        );

        this.self.backdropClick().subscribe(() => {
            this.self.dismiss(this.selected);
        });
    }

    public clearFilter(): void {
        this.selected = [];
    }

    public onEnter(event) {
        event.preventDefault();
        if (event.keyCode === 13) {
            this.addItem();
        }
    }

    public addItem(): void {
        if (this.autoComplete.value < 0 && this.autoComplete.value !== '-1') {
            return;
        }

        if (this.autoComplete.value === '') {
            return;
        }

        let item = {
            label: this.autoComplete.value,
            value: (this.autoComplete.value || '').toLowerCase(),
        };
        let update = true;

        if (Object.keys(this.selection).length > 0) {
            item = this.selection.filter(option => option.value.toLowerCase() === this.autoComplete.value.toLowerCase()).pop();

            update = item && this.selected.filter((val) => {
                return val.value.toLowerCase() === item.value.toLowerCase();
            }).length <= 0;
        }

        if (update) {
            this.selected.push(item);
        }
        this.autoComplete.patchValue('');
    }

    public deleteItem(index: number) {
        this.selected.splice(index, 1);
    }

  onInputChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.autoComplete.patchValue(inputValue);
    this.selection.push({label: inputValue, value: inputValue});
  }

}
