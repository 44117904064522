import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './components/login/login.module';
import { ThemeModule } from './components/@theme/theme.module';
import { CoreModule } from './components/@core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MainComponent } from './layouts/main/main.component';
import { DefaultComponent } from './layouts/default/default.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { UnlockScreenModule } from './components/general/unlock-screen/unlock-screen.module';

import { PushNotificationsModule } from 'ng-push';
import { CallLayoutModule } from './layouts/call-layout/call-layout.module';
import { DefaultGuardModule } from './components/general/default-guard/default-guard.module';
import { GestureConfig, MatDialogModule, MatIconModule, MatNativeDateModule } from '@angular/material';

import { PaymentNotificationsModule } from './components/general/payment-notifications/payment-notifications.module';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export class MissingTranslationService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    // return `WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`;
    console.log(`'${params.key}' is missing in '${params.translateService.currentLang}' locale`)
    //  - '${params.translateService.currentLang}'
    return `'${params.key}'`;
  }
}

import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs)
import { LocaleProvider } from './LocaleProvider';
import {Unsubscribe} from './helpers/unsubscribe';
import {PermissionsHelper} from './helpers/Permission';
import { CreateLeadModalModule } from './components/general/create-lead-modal/create-lead-modal.module';
import { CreateLeadModalComponent } from './components/general/create-lead-modal/create-lead-modal.component';
import { ConfirmModalModule } from './components/general/confirm-modal/confirm-modal.module';
import { ConfirmModalComponent } from './components/general/confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DefaultComponent,
    Unsubscribe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoginModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 5
    }),
    NgbModule.forRoot(),
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule,
    NgxSelectModule,

    UnlockScreenModule,

    PushNotificationsModule,
    CallLayoutModule,
    DefaultGuardModule,
    MatNativeDateModule,
    CreateLeadModalModule,
    ConfirmModalModule,
    MatIconModule,
    PaymentNotificationsModule,
    RichTextEditorAllModule,
    MatDialogModule,

    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationService
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
  ],
  providers: [
    DatePipe,
    PermissionsHelper,
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    ...LocaleProvider
  ],
  entryComponents: [
    CreateLeadModalComponent,
    ConfirmModalComponent
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
}
