import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckInManageComponent } from './check-in-manage.component';
import { MatButtonModule } from '@angular/material';
import { CheckInModalModule } from '../check-in-modal/check-in-modal.module';
import { CheckInModalComponent } from '../check-in-modal/check-in-modal.component';
import { PieLoaderModule } from '../pie-loader/pie-loader.module';
import { SmallLoaderModule } from '../small-loader/small-loader.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [CheckInManageComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        CheckInModalModule,
        PieLoaderModule,
        SmallLoaderModule,
        TranslateModule
    ],
    exports: [CheckInManageComponent, CheckInModalComponent],
    entryComponents: [
        CheckInModalComponent,
    ]
})
export class CheckInManageModule {
}
