import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-success-payment-modal',
  templateUrl: './success-payment-modal.component.html',
  styleUrls: ['./success-payment-modal.component.scss']
})
export class SuccessPaymentModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<SuccessPaymentModalComponent>
  ) {}

  ngOnInit() {}

  public redirect(): void {
    this.self.close(true);

    window.open('/application/leads/' + this.data.lead_id);
  }

}
