import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import _map from 'lodash/map';
import _get from 'lodash/get';

import es from 'src/assets/i18n/es.json';

@Pipe({
  name: 'arrayTranslate'
})
export class ArrayTranslatePipe implements PipeTransform {

  constructor (
    private translateService: TranslateService
  ) {}

  transform(arr: any[], block: string): any[] {
    if (arr instanceof Array && block) {
      if (this.translateService.currentLang === 'es') {
        return _map(arr, (i) => ({
          ...i,
          label: _get(es, [block, i.label], i.label)
        }));
      } else {
        return arr;
      }
    }

    return arr;
  }

}
