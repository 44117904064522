import {Component, NgZone, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as PaymentNotificationActions from '../../../../actions/payment_notification.action';

@Component({
    selector: 'app-bubble-message',
    templateUrl: './bubble-message.component.html',
    styleUrls: ['./bubble-message.component.scss']
})
export class BubbleMessageComponent implements OnInit {

    public messages = [];


    constructor(
        private ngZone: NgZone,
        private store: Store<any>,
    ) {
        this.store.pipe(select('paymentNotification')).subscribe(value => {
            if (value.bubbles.length > 3) {
                this.store.dispatch(new PaymentNotificationActions.RemoveBubbleMessage(value.bubbles[0].id));
            }

            if (!value.isOpen) {
                this.ngZone.run(() => {
                    this.messages = value.bubbles;
                });
            } else if (value.bubbles.length > 0 && value.isOpen) {
                this.store.dispatch(new PaymentNotificationActions.RemoveBubbleMessage(value.bubbles[0].id));
                this.store.dispatch(new PaymentNotificationActions.SetNotViewedNotifications(0));
            }
        });
    }

    ngOnInit() {
    }


}
