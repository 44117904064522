import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { CentrifugeService } from 'src/app/services/centrifuge/centrifuge.service';
import { NotificationSystemService } from 'src/app/services/notification-system/notification-system.service';
import storage from 'store';

@Component({
  selector: 'app-notifications-system-modal',
  templateUrl: './notifications-system-modal.component.html',
  styleUrls: ['./notifications-system-modal.component.scss']
})
export class NotificationsSystemModalComponent implements OnInit, OnDestroy {

  public isOpen = false;

  public subscribes: any = {};

  private agent;

  public unreadMessagesCount = 0;
  public newMessagesCount = 0;

  public notificationsCount;

  constructor(
    private snack: MatSnackBar,
    private centrifugeService: CentrifugeService,
    private notificationSystemService: NotificationSystemService
  ) { }

  ngOnInit() {
    this.agent = storage.get('user');

    this.getNotifications();
    setTimeout(() => {
      this.subscribes.connect = this.centrifugeService.connect().subscribe(() => {
        this.initCentrifuge();
      });
    }, 3000);
  }

  ngOnDestroy(): void {
    for (const key of this.subscribes) {
       if (this.subscribes[key] && this.subscribes[key].unsubscribe) {
        this.subscribes[key].unsubscribe();
       }
    }
  }

  public getNotifications() {
    this.subscribes.getNotif = this.notificationSystemService.getNotifications(
      {}, this.agent.crm_id
    ).subscribe((res) => {
      this.unreadMessagesCount = res.unread_events;
      this.notificationsCount = res.all_events;
    }, (err) => {
      this.onError(err);
    });
  }

  public onError(err) {
    this.snack.open(err.error, 'Ok', {
      duration: 3000
    });
  }

  public opentNotifications() {
    this.isOpen = !this.isOpen;

    if (this.isOpen === true) {
      this.newMessagesCount = 0;
    }
  }

  private initCentrifuge(): void {
    this.subscribes.events = this.centrifugeService.listen(
      `events-${this.agent.crm_id}`
    ).subscribe(({data}) => {
      // console.log('--- notifications_${this.agent.id} chanel----', data);

      if (data) {
        this.unreadMessagesCount += 1;
        this.newMessagesCount += 1;
      }
    });
  }
}
