import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RightSidebarComponent} from './right-sidebar.component';
import {ActionNotificationModule} from '../../action-notification/action-notification.module';
import {NbTabsetModule} from '@nebular/theme';
// import {ChatModule} from '../chat/chat.module';
import {MatIconModule, MatTooltipModule, MatTabsModule} from '@angular/material';

@NgModule({
    declarations: [
        RightSidebarComponent
    ],
    imports: [
        CommonModule,
        ActionNotificationModule,
        NbTabsetModule,
        // ChatModule,
        MatIconModule,
        MatTooltipModule,
        MatTabsModule,
    ],
    exports: [
        RightSidebarComponent
    ]
})
export class RightSidebarModule {
}
