import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatPaginator} from '@angular/material';
import {WizardsService} from '../../../services/wizards/wizards.service';
import SearchResponse from '../../../response/search.response';
import {Router} from '@angular/router';
import _forEach from 'lodash/forEach';
import {SelectionModel} from '@angular/cdk/collections';
import storage from 'store';

@Component({
    selector: 'app-wizard-modal',
    templateUrl: './wizard-modal.component.html',
    styleUrls: ['./wizard-modal.component.scss']
})
export class WizardModalComponent implements OnInit {

    public wizards: SearchResponse = new SearchResponse({
        page_size: 10,
    });

    public allWizards: SearchResponse = new SearchResponse();

    public displayedColumns = ['name', 'account_number', 'call_reason', 'called_today', 'action'];

    public activeWizardType = '';

    public selection = new SelectionModel<any>(true, []);

    @ViewChild(MatPaginator) paginator: MatPaginator;

    public loader = false;

    public wizardTypes = {
        leads: [
            {
                label: 'Fresh',
                value: 'fresh',
            },
            {
                label: 'Contact Me',
                value: 'contact_me'
            },
            {
                label: 'Active',
                value: 'active',
            },
        ],
        applications: [
            {
                label: 'Fresh',
                value: 'fresh',
            },
            {
                label: 'Contact Me',
                value: 'contact_me'
            },
            {
                label: 'Collection',
                value: 'collection'
            },
            {
                label: 'Active',
                value: 'active',
            },
        ]
    };


    constructor(
        private self: MatDialogRef<WizardModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private wizardsService: WizardsService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        storage.set('wizard_counts_fresh', this.data.countByType.fresh);
        storage.set('wizard_counts_active', this.data.countByType.active);
        storage.set('wizard_counts_contact_me', this.data.countByType.contact_me);
        storage.set('wizard_counts_collection', this.data.countByType.collection);
    }

    public getWizards(wizardType: string, fromPaginator = false) {

        if (!fromPaginator) {
            this.paginator.firstPage();
        }

        this.activeWizardType = wizardType;
        this.loader = true;
        this.wizardsService.getWizardsByType(this.wizards.getReqData(), wizardType).subscribe(res => {
            this.wizards = new SearchResponse();
            this.wizards = Object.assign(this.wizards, res[wizardType]);
            this.loader = false;
        });
    }

    public redirect(row, wizardType: string): void {
        this.allWizards.page_size = this.data.countByType[wizardType].count;
        this.wizardsService.getWizardsByType(this.allWizards.getReqData(), wizardType).subscribe(res => {
            storage.set(wizardType, res[wizardType].data);
            this.router.navigate(['/details/' + this.data.wizardEntity + '/' + row.id + '/' + wizardType + '/wizard']);
            this.self.close();
        });
    }

    public getPercentage() {
        if (this.data.count === 0) {
            return '0.00';
        }

        return ((this.data.counts[0].called / this.data.counts[0].count) * 100).toFixed(2);
    }

    public getPercentageByType(called: number, count: number): string {
        if (count === 0) {
            return '0.00';
        }
        return ((called / count) * 100).toFixed(2);
    }

    public getPercentageByTypeNumber(called: number, count: number): number {
        if (count === 0) {
            return 0;
        }

        return ((called / count) * 100);
    }

    public getReason(row): string {
        let reason = '';
        _forEach(row, (el, index) => {
            if (index.includes('view')) {
                if (el) {
                    reason = row['reason' + index.substr(index.indexOf('_'))];
                }
            }
        });

        return reason;
    }

    public changePage(event: any, wizardType: string): void {
        this.selection.clear();
        this.wizards.page = event.pageIndex;
        this.wizards.page_size = event.pageSize;
        this.getWizards(wizardType, true);
    }
}
