import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import { BubbleComponent } from './bubble.component';

@NgModule({
    declarations: [
        BubbleComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule
    ],
    exports: [
        BubbleComponent
    ]
})
export class BubbleModule {}
