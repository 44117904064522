import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatContentComponent} from './chat-content.component';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MessagesListModule} from './messages-list/messages-list.module';

@NgModule({
    declarations: [
        ChatContentComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        MatProgressSpinnerModule,
        MessagesListModule
    ],
    exports: [
        ChatContentComponent
    ]
})
export class ChatContentModule { }
