import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentOfNumber'
})
export class PercentOfNumberPipe implements PipeTransform {

  transform(num: number, total: number = 1): number {
    const res = Math.round(num * total / 100);
    return res;
  }

}
