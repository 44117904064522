import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}


// if (window && !isDevMode()) {
//   window.console.log = () => {};
//   window.console.warn = () => {};
//   window.console.error = () => {};
//   window.console.info = () => {};
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
