import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/index';

// import {EventEmitter} from '@angular/core';
import { Cookies } from '../../helpers/Cookies';
import { ApiService } from '../api/api.service';

declare var Centrifuge: any;

@Injectable({
  providedIn: 'root'
})
export class CentrifugeService {

  constructor(
    private api: ApiService,
    private ngZone: NgZone
  ) {
  }

  private Centrifuge: any;
  private debug: boolean;
  public connected = false;
  private channels = {};

  private list = {};

  // stateEmitter = new EventEmitter<any>();

  public apiRefresh(): Observable<any> {
    return this.api.get('cent/refresh', {});
  }

  public connect(): Observable<any> {
    return new Observable((observer) => {
      if (this.connected) {
        observer.next();
        return {
          unsubscribe: () => {
            observer.complete();
          }
        };
      }

      if (this.Centrifuge) {
        return {
          unsubscribe: () => {
            observer.complete();
          }
        };
      }

      this.Centrifuge = new Centrifuge(
        environment.centURL,
        {
          resubscribe: false,
          onRefresh: (ctx, cb) => {
            this.apiRefresh().subscribe((data) => {
              cb({ status: 200, data });
            });
          }
        }
      );

      const token = Cookies.get('centrifugo');
      this.Centrifuge.setToken(token);

      this.debug = !environment.production;

      this.Centrifuge.on('connect', (data) => {
        if (this.debug) { console.log('CONNECT to Centrifugo', data); }
        this.connected = true;
        observer.next();
      });
      this.Centrifuge.on('disconnect', (data) => {
        this.connected = false;
        this.dissconnectAllSubscribes();
        if (this.debug) { console.log('DISCONNECT from Centrifugo', data); }
      })
      this.Centrifuge.on('error', (error) => {
        if (this.debug) { console.warn('Error Centrifugo :', error); }
      });

      this.Centrifuge.connect();

      return {
        unsubscribe: () => {
          observer.complete();
        }
      };
    });
  }

  public dissconnectAllSubscribes() {
    for (const channel in this.list) {
      if (this.list[channel]) {
        this.list[channel].forEach((obs) => {
          obs.complete()
        });
        delete this.list[channel];
      }
    }
  }


  public disconnectChannel(channel: string): void {
    this.dissconnectChannel(channel)
  }

  public dissconnectChannel(channel: string): void {

    if (this.list[channel]) {
      this.list[channel].forEach((obs) => {
        obs.complete();
      });

      delete this.list[channel];
    }

    if (this.channels[channel]) {
      this.channels[channel].unsubscribe();

      delete this.channels[channel];
    }
  }

  public listen(channel: string): Observable<any> {
    return new Observable((observer) => {
      setTimeout(() => {
        this.connect().subscribe(() => {
          if (!this.connected) { return; }

          if (!this.list[channel]) {
            this.list[channel] = [];
          }
          this.list[channel].push(observer);

          if (!this.channels[channel]) {
            if (!this.connected && this.Centrifuge) { return; }

            const ch = this.Centrifuge.subscribe(
              channel
            ).on('publish', message => {
              try {
                message.data = JSON.parse(new TextDecoder('utf-8').decode(message.data));
                if (this.list[channel]) {
                  this.list[channel].forEach(obs => obs.next(message));
                }
              } catch (e) {}
              if (this.debug) { console.log('Published to \'' + channel + '\' :', message); }
            }).on('subscribe', (data) => {
              if (this.debug) { console.log('Subscribed to \'' + channel + '\' :', data); }
            }).on('unsubscribe', (data) => {
              if (this.debug) { console.log('Unsubscribed from \'' + channel + '\' :', data); }
            }).on('error', (error) => {
              if (this.debug) { console.log('Centrifugo Subscribe error :', error); }
              if (this.list[channel] && this.list[channel].length) {
                // this.list[channel].forEach(obs => obs.error(error));
                this.list[channel].forEach(obs => obs.complete());
              }
            });
            this.channels[channel] = ch;
          } else {
            this.channels[channel].subscribe();
          }

        });
      }, 4000);

      return {
        unsubscribe: () => {
          if (this.list[channel]) {
            this.list[channel].forEach(obs => obs.complete());
            delete this.list[channel];
          }
          observer.complete();
        }
      };
    });
  }

  public disconnect(): void {
    this.Centrifuge.disconnect();
  }

  public async getActiveTabs(): Promise<any> {
    return await this.Centrifuge.subscribe('agents', () => { }).presence();
  }
}
