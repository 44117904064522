import {Component, OnInit, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {EditFieldsService} from '../../../services/edit-fields/edit-fields.service';
import {UserService} from '../../../services/users/user.service';

@Component({
  selector: 'app-call-occupation-modal',
  templateUrl: './call-occupation-modal.component.html',
  styleUrls: ['./call-occupation-modal.component.scss']
})
export class CallOccupationModalComponent implements OnInit {

  public occupationForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<CallOccupationModalComponent>,
    private editFieldsService: EditFieldsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.occupationForm = new FormGroup({
      quote: new FormControl(String(this.data.lead['quote']), [
        Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]),
      occupation: new FormControl(String(this.data.lead['occupation']), [
        Validators.required
      ])
    });
  }

  public submit(): void {
    if (this.occupationForm.valid) {
      this.editFieldsService.editField('leads', this.data.lead.id, null, this.occupationForm.value).subscribe(res => {
        this.userService.unblock(this.data.userId).subscribe(data => {
          this.self.close(true);
        });
      });
    }
  }

}
