import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LevelsService {

  constructor(private api: ApiService) {
  }

  public get(level_id, params?): Observable<any> {
    return this.api.get(`/levels/${level_id}`, { params });
  }

}
