import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NbLayoutModule, NbMenuModule, NbSidebarModule, NbThemeModule } from '@nebular/theme';

import { HeaderComponent } from './components/header/header.component';
import { SwitcherComponent } from './components/switcher/switcher.component';

import { DEFAULT_THEME } from './styles/theme.default';
import { DefaultLayoutComponent } from './layouts/default/default.layout';
import { RightSidebarModule } from '../general/right-sidebar/right-sidebar.module';
import {
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatMenuModule, MatProgressSpinnerModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import { LevelModalModule } from '../general/level-modal/level-modal.module';
import { CallStatisticsDurationModule } from '../statistics/call-statistics-duration/call-statistics-duration.module';
import { CurrentUserModule } from '../current-user/current-user.module';
import { CheckInModalModule } from '../general/check-in-modal/check-in-modal.module';
import { CheckInModalComponent } from '../general/check-in-modal/check-in-modal.component';
import { AgentNotoficationModalComponent } from '../general/agent-notofication-modal/agent-notofication-modal.component';
import { AgentNotoficationModalModule } from '../general/agent-notofication-modal/agent-notofication-modal.module';

import { CallOccupationModalComponent } from '../modals/call-occupation-modal/call-occupation-modal.component';
import { CallOccupationModalModule } from '../modals/call-occupation-modal/call-occupation-modal.module';
// import { WizardModule } from '../wizard/wizard.module';
import { SideNavComponent } from './layouts/side-nav/side-nav.component';
import { RouterModule } from '@angular/router';
import { NotificationsSystemModalModule } from '../notifications-system-modal/notifications-system-modal.module';
import { CheckInManageComponent } from '../general/check-in-manage/check-in-manage.component';
import { CheckInManageModule } from '../general/check-in-manage/check-in-manage.module';
import { HeaderBarsModule } from './components/header-bars/header-bars.module';
import { HeaderUserModule } from './components/header/header-user/header-user.module';
import { ChatManagerModule } from '../general/manager-chat/chat.module';
import { SalaryProjectionBarModule } from './components/salary-projection-bar/salary-projection-bar.module';
import { UsersLoginPopupModule } from './components/header/users-login-pop/users-login-popup.module';
import { CallBarModule } from './components/header/call-bar/call-bar.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderUnderModule } from './components/header-under/header-under.module';
import {AnnouncementsModule} from '../general/announcements/announcements.module';
import { ReferralsModule } from '../general/referrals/referrals.module';
import { TranslateModule } from '@ngx-translate/core';
import {InstallmentNotificationModule} from './components/installment-notification/installment-notification.module';
// import {CalendarNotificationModule} from '../calendar-notification/calendar-notification.module';
import {NotificationsModule} from '../notifications/notifications.module';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const NB_MODULES = [
  NbLayoutModule,
  NbSidebarModule,

  MatButtonModule,
  MatMenuModule,
  MatTooltipModule,
  MatListModule,
  MatIconModule,
  MatSidenavModule,
  MatToolbarModule,
  UsersLoginPopupModule,
  RightSidebarModule,
  LevelModalModule,
  CheckInModalModule,
  CheckInManageModule,
  // CommissionV3ModalModule,
  ReferralsModule,
  CallStatisticsDurationModule,
  CurrentUserModule,
  AgentNotoficationModalModule,
  CallOccupationModalModule,
  RouterModule,
  NotificationsSystemModalModule,
  ChatManagerModule,
  MatDialogModule,

  HeaderBarsModule,
  HeaderUserModule,

  CallBarModule,

  SalaryProjectionBarModule,

  HeaderUnderModule,
  TranslateModule,
];

const COMPONENTS = [
  SwitcherComponent,
  HeaderComponent,
  FooterComponent,
  // HeaderV3Component,
  DefaultLayoutComponent,
  SideNavComponent,
];

const ENTRY_COMPONENTS = [
  CheckInModalComponent,
  AgentNotoficationModalComponent,
  CallOccupationModalComponent,
  CheckInManageComponent,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default',
    },
    [DEFAULT_THEME],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, MatProgressSpinnerModule, AnnouncementsModule, InstallmentNotificationModule, NotificationsModule, /*GroupGoalModule, PersonalGoalModule, PersonalDailyCommissionModule*/],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS],
  declarations: [...COMPONENTS],
  entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
