import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { MatIconModule, MatTooltipModule } from '@angular/material';
import { BubbleLoaderModule } from '../../general/bubble-loader/bubble-loader.module';
import { NotificationComponent } from './notification/notification.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    BubbleLoaderModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [
    NotificationsComponent
  ],
  entryComponents: [
    NotificationComponent
  ]
})
export class NotificationsModule {
}
