import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { SalaryProjectionBarComponent } from './salary-projection-bar.component';
import { SalaryProjectionBarModalModule } from './salary-projection-bar-modal/salary-projection-bar-modal.module';

@NgModule({
  declarations: [
    SalaryProjectionBarComponent,
  ],
  imports: [
    CommonModule,

    MatDialogModule,
    MatIconModule,

    SalaryProjectionBarModalModule,
  ],
  exports: [
    SalaryProjectionBarComponent,
  ]
})
export class SalaryProjectionBarModule {
}
