import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReferralsService {

    constructor(private api: ApiService) {
    }

    public sendInvitation(body): Observable<any> {
        return this.api.post('/referrals/send-email', {
            body
        });
    }
}
