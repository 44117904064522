import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Rx';
import SearchResponse from '../../response/search.response';

@Injectable({
    providedIn: 'root'
})
export class ApplicationsService {

    constructor(private api: ApiService) {
    }

  public sendRetainerNotification(id, method): Observable<any> {
    return this.api.post('/applications/send-retainer/' + id + '/' + method, {
    });
  }

  public setOnHold(id, data) : Observable<any> {
    return this.api.put('/applications/' + id + '/hold_status', {
      body: data
    });
  }

  public setEOIStatus(id, data): Observable<any> {
    return this.api.put('/applications/' + id + '/eoi_status', {
      body: data,
    });
  }

  public createEjobboAccount(id): Observable<any> {
    return this.api.post('/applications/create-ejobbo/' + id, {
    });
  }

    public setStatus(id, data): Observable<any> {
        return this.api.post('/applications/' + id + '/status', {
            body: data
        });
    }

    public getList(params): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }

        return this.api.get('/applications/v2', {
            params: params
        });
    }

    public getAllList(params): Observable<any> {
      if (params instanceof SearchResponse) {
          params = params.getReqData();
      }
      return this.api.get('/applications/tech', {
          params: params
      });
    }

    public getListRisks(params): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }

        return this.api.get('/applications/risks/v2', {
          params: params
        });
    }

    public getListStudents(params): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }
        return this.api.get('/applications/students', {
            params: params
        });
    }

    public getListCsr(params): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }
        return this.api.get('/applications/csr', {
            params: params
        });
    }

    public getOpportunitiesList(params): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }
        return this.api.get('/applications/opportunities/search', {
            params: params
        });
    }

    public getAdditionalInfo(id): Observable<any> {
        return;
    }

    public deleteApplications(ids): Observable<any> {
        return this.api.post('/applications/delete', {
            body: {
                applications_ids: ids,
            }
        });
    }

    public downloadApplication(params, ids = [], type = {result: 0, password: 0}): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }
        params = Object.assign(params, {password: type.password});
        if (ids.length > 0 && type.result === -1) {
            return this.api.get('/applications?excel=all&excel_users=' + ids, {
                params: params,
                responseType: 'text'
            });
        } else if (type.result === 1) {
            return this.api.get('/applications?excel=all', {
                params: params,
                responseType: 'text'
            });
        } else if (type.result === 2) {
            return this.api.get('/applications?excel=' + params.page_size, {
                params: params,
                responseType: 'text'
            });
        }
    }
}
