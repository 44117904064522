import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {AvgModalComponent} from '../avg-modal/avg-modal.component';
import {User} from '../../../services/users/user';

interface AVG {
  'ranking': string;
  'color': string;
  'reward': number;
  'reached': number;
  'info': {
    'uuid': string,
    'commission_id': string,
    'reward_type': number,
    'target_type': number,
    'details': {
      'number_of_periods': number,
      'type_of_period': number,
      'slices': Array<{
        'ranking': string,
        'value': number,
        'reward': number,
        'color': string
      }>
    }
  };
}

@Component({
  selector: 'app-avg',
  templateUrl: './avg.component.html',
  styleUrls: ['./avg.component.scss', '../commissions-v3.component.scss']
})
export class AvgComponent implements OnInit {

  @Input() public avg: AVG = null;

  public currencyRatio = User.CURRENSY;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {}

  public openAVGModal(): void {
    this.dialog.open(AvgModalComponent, {
      width: '600px',
      data: {
        avg: this.avg
      }
    });
  }

}
