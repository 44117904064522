export const deepClone = (obj) => {
  let now;
  if (typeof obj === 'object' && obj) {
    if (Array.isArray(obj)) {
      now = Object.assign([], obj);
    } else {
      now = Object.assign({}, obj);
    }
    for (const key in now) {
      now[key] = deepClone(now[key]);
    }
  } else {
    now = obj;
  }
  return now;
}