import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {MatBottomSheet} from '@angular/material';
import {NewAgentsSheetComponent} from '../agents-sheet/agents-sheet.component';
import {getAvatar} from '../../../helpers/Avagar';
import _filter from 'lodash/filter';

@Component({
  selector: 'app-assigned-to2',
  templateUrl: './assigned-to.component.html',
  styleUrls: ['./assigned-to.component.scss']
})

export class AssignedToComponent implements OnInit, OnChanges {

  public getAvatar = getAvatar;

  public agents: any;
  public selectedAgents: any;
  public allAgents: any = false;

  @Input() model: any = null;
  @Input() agentsFromParent = [];

  @Input() multiple = true;
  @Input() disabled: boolean;
  @Output() modelChange = new EventEmitter<any>();
  @Input() public field: string;

  @Input() disableId = 0;


  // @Input() isDefaultUser: Boolean

  constructor(
    private store: Store<any>,
    private bottomSheet: MatBottomSheet
  ) {
  }

  ngOnInit() {

    if (this.agentsFromParent.length > 0) {
      this.agents = this.agentsFromParent;
    } else {
      this.store.pipe(select((state) => {
        return state.users;
      })).subscribe((update) => {
        this.agents = update.all;
      });
      if (this.field === 'csr_assign_to' || this.field === 'csr_previous_assign_to') {
        this.agents = _filter(this.agents, el => el.role === 'csr-agent' || el.role === 'csr_manager');
      }
      if (this.field === 'mdc_assign_to') {
        this.agents = _filter(this.agents, el => el.role === 'mdc_dep' || el.role === 'backoffice_mdc');
      }
      if (this.field === 'mdc_evaluation_to') {
        this.agents = _filter(this.agents, el => el.role === 'mdc_dep');
      }
      if (this.field === 'mdc_backoffice_assign_to') {
        this.agents = _filter(this.agents, el => el.role === 'backoffice_mdc');
      }
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    //only for templates; ngOnChanges works only is object changes
    if(this.model && this.model.model && this.model.model === 'all') {
      this.allAgents = true;
      this.selectedAgents = [0];
    } else {

      if (this.model !== 'all') {
        this.selectedAgents = Object.assign([], this.model);
        this.allAgents = false;
      }
      if (changes.model && changes.model.currentValue) {
        if (this.model !== 'all') {
          this.selectedAgents = Object.assign([], this.model);
        } else {
          this.allAgents = true;
          this.selectedAgents = [0];
        }
      }
    }
  }

  public setAgents() {
    if (this.disabled) {
      return;
    }

    const bottomSheet = this.bottomSheet.open(NewAgentsSheetComponent, {
      data: {
        agents: Object.assign([], this.agents),
        selected: Object.assign([], this.selectedAgents),
        multiple: this.multiple,
        all: this.allAgents,
        disableId: this.disableId
      }
    });

    bottomSheet.afterDismissed().subscribe((result) => {

      if (result && result.all && result.selected.length) {
        this.allAgents = true;
        this.selectedAgents = Object.assign([], result.selected);
        this.modelChange.emit(this.selectedAgents);
      } else {
        this.allAgents = false;
        this.selectedAgents = Object.assign([], result.selected);
        this.modelChange.emit(this.selectedAgents);
      }
    });
  }

}
