import { NgModule } from '@angular/core';
import { PercentOfNumberPipe } from './percent-of-number.pipe';

@NgModule({
  declarations: [PercentOfNumberPipe],
  exports: [
    PercentOfNumberPipe
  ]
})
export class PercentOfNumberPipeModule { }
