import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesListComponent } from './messages-list.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        MessagesListComponent
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        // BrowserAnimationsModule
    ],
    exports: [
        MessagesListComponent
    ]
})
export class MessagesListModule { }
