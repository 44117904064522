import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../../../services/users/user.service';
import { environment } from '../../../../../../environments/environment';
import storage from 'store';
import { select, Store } from '@ngrx/store';
import {DateConverter} from '../../../../../helpers/DateConverter';
import SearchResponse from '../../../../../response/search.response';


@Component({
  selector: 'app-users-login-popup',
  styleUrls: ['./users-login-popup.component.scss'],
  templateUrl: './users-login-popup.component.html',
})
export class UsersLoginPopupComponent implements OnInit {
  public selectedAgents = [];

  public agents: SearchResponse = new SearchResponse();

  public loader: any = true;

  public manager: any;

  private timeout: any;

  public onlineAgents: any = {};

  public displayedColumns: any[] = ['full_name', 'config'];

  constructor(
    private userService: UserService,
    private store: Store<any>,

) {

  }

  public options: any = [
    {
      icon: 'login',
      label: 'Login',
      fn: (option, row) => {
        this.agentLogin(row.username, row.id);

      },
      showBtn: (row) => {
        return true;
      },
    },
  ];

  ngOnInit() {

    this.agents.page_size = 50;

    this.getAgentsList({});

    this.manager = storage.get('user');

    this.store.pipe(select((state) => {
      return state.live;
    })).subscribe((update) => {
      //this.onlineAgents = update.online;
    });
  }

  public select(option, element) {
    option.fn(option, element);
  }

  private getAgentsList(params) {
    if (params && params.filter) {
      const { field, value } = params.filter;
      this.agents.addFilter(field, value);
    }

    this.userService.getUsersForLogin(this.agents.getCustomReqData()).toPromise().then((res) => {
      for (const agent of res.data) {
        for (const name of ['commission_from', 'commission_to']) {
          if (agent[name]) {
            agent[name] = DateConverter.convertFromUTC(agent[name]);
          }
        }
      }
      this.agents = Object.assign(this.agents, res);
      this.toggleLoader();
    });
  }

  private prevSelectedAgents = '[]';
  public getSelectedAgents(e) {
    if (e.length) {
      const agentsToFilter = e.map(agent => agent.crm_id);
      if (this.prevSelectedAgents === JSON.stringify(agentsToFilter)) { return; }
      this.prevSelectedAgents = JSON.stringify(agentsToFilter);
      this.applyFilter('crm_id', agentsToFilter);
    } else {
      if (this.prevSelectedAgents === '[]') { return; }
      this.prevSelectedAgents = '[]';
    }
  }

  public clearFilter(event, formControl, field) {
    event.stopPropagation();
    this.applyFilter(field, '');
    this[formControl].setValue('');
  }

  public applyFilter(field, value) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.toggleLoader();
      this.getAgentsList({
        filter: {
          field, value
        }
      });
    }, 500);
  }

  public addSort(name, value) {
    this.clearEntity('sort');
    this.addEntity(name, value, 'sort');
  }

  public addFilter(name, value) {
    this.addEntity(name, value, 'filter');
  }

  private clearEntity(entity: string) {
    this[entity] = {};
  }

  private addEntity(name: string, value: string | number, entity: string): void {

    const entityRef = this[entity];

    if (!entityRef) {
      return;
    }

    entityRef[name] = value;
  }

  public changePage(event: any): void {
    this.agents.page = event.pageIndex;
    this.agents.page_size = event.pageSize;
    this.toggleLoader();
    this.getAgentsList({});
  }

  public toggleLoader(params?: any) {
    this.loader = !this.loader;
  }

  public agentLogin(username, userId) {

    this.toggleLoader();

    this.userService.agent_login({
      body: Object.assign({
        username,
        user_id: userId
      })
    }).toPromise().then((response) => {
      this.toggleLoader();
      window.open( '/login?agent_token=' + response.token + '&manager_id=' + this.manager.id, '_self');
    });
  }

}
