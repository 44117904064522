import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneInputComponent } from './phone-input.component';
import {MatFormFieldModule, MatInputModule} from '@angular/material';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [PhoneInputComponent],
  exports: [
    PhoneInputComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    Ng2TelInputModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class PhoneInputModule { }
