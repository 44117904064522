import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallLayoutComponent} from './call-layout.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [CallLayoutComponent],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [
        CallLayoutComponent
    ]
})
export class CallLayoutModule {
}
