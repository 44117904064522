import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommissionsApiService } from './commisions.api.service';

@Injectable({
  providedIn: 'root'
})
export class CommissionsService {

  constructor(private api: CommissionsApiService) {
  }

  public getCommissionsAgent(params?): Observable<any> {
    return this.api.get(`/api/agent/commissions`, { params });
  }
  public getCommissionsAdmin(user_id, params?): Observable<any> {
    return this.api.get(`/api/admin/commissions?user_id=${user_id}`, { params });
  }

  public getAchievedProductCommission(user_id, role_id, achieved, params): Observable<any> {
    return this.api.get(`/api/agent/commissions/product-activity`, {
      params: {
        user_id,
        role_id,
        achieved,
        ...params
      }
    });
  }


}
