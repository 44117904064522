import {Cookies} from '../../helpers/Cookies';
import {FormGroup} from '@angular/forms';

class BaseComponent {

    public loader = false;

    public showLoader() {
        this.loader = true;
    }

    public hideLoader() {
        this.loader = false;
    }

    public toggleLoader(params?: any) {
        this.loader = !this.loader;
    }


    public isAuthenticated() {
        return !!Cookies.get('token');
    }

    public getBackendError(form: FormGroup, control: string) {
        if (form && form.get(control) && form.get(control).errors) {
            return form.get(control).errors['backend'];
        }
        return null;
    }

    public getDefaultValue(data: any, key): any {
        if (!data) {
            return '';
        }

        if (!data[key]) {
            return '';
        }

        return data[key];
    }
}

export default BaseComponent;