import {Directive, Input, Output, EventEmitter, OnDestroy, OnInit} from '@angular/core';

import {Subject, Subscription, timer} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';
import _memoize from 'lodash/memoize';
import _toString from 'lodash/toString';
import _toInteger from 'lodash/toInteger';

const timeStr = _memoize((time: number): string => time < 10 ? '0' + _toString(_toInteger(time)) : _toString(_toInteger(time)));

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[counter]'
})
export class CounterDirective implements OnInit, OnDestroy {

  private _counterSource$ = new Subject<any>();
  private _subscription = Subscription.EMPTY;

  @Input() counter: number;
  @Input() interval: number;
  @Input() dueDate: number;
  @Output() value = new EventEmitter<number>();


  @Output() seconds = new EventEmitter<string>();
  @Output() minutes = new EventEmitter<string>();
  @Output() hours = new EventEmitter<string>();

  constructor() {
    this._subscription = this._counterSource$.pipe(
      switchMap(({interval, count}) =>
        timer(0, interval).pipe(
          take(count - this.dueDate),
          tap(
            () => {
              this.value.emit(--count);
              this.seconds.emit(timeStr((this.counter - count) % 60));
              this.minutes.emit(timeStr((this.counter - count) / 60 % 60));
              this.hours.emit(timeStr((this.counter - count) / 3600));
            }
          )
        )
      )
    ).subscribe();
  }

  ngOnInit() {
    this._counterSource$.next({count: this.counter - parseInt(String(this.dueDate / 1000), 0), interval: this.interval});
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

}
