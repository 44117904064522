import { Component, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as ChatActions from '../../../../actions/chat.actions'

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.less']
})
export class ChatWindowComponent implements OnInit {

  public activeAgent: any;

  constructor(
      private store: Store<any>
  ) {
    this.store.pipe(select('chat')).subscribe((value) => {
      this.activeAgent = value.activeAgent;
    });
  }

  ngOnInit() {
  }

  public closeChat(): void {
    this.store.dispatch(new ChatActions.ToggleChat(false));
  }

}
