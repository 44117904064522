import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChatComponent} from './chat.component';
import {ChatWindowModule} from './chat-window/chat-window.module';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import { BubbleMessageModule} from './bubble-message/bubble-message.module';

@NgModule({
    declarations: [
        ChatComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        ChatWindowModule,
        MatBadgeModule,
        BubbleMessageModule
    ],
    exports: [
        ChatComponent
    ]
})
export class ChatManagerModule {
}
