import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiWizard2 extends ApiService {
  public urls = [
    environment.wizard1,
    environment.wizard
  ];

  protected set baseURL(val) {}

  protected get baseURL(): string {
    return this.urls[Math.round(Math.random())];
  }
}
