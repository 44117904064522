import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";
import {Observable} from "rxjs/Rx";

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  constructor(private api: ApiService) { }

  public getProducts(leadNumber: string): Observable<any> {
    return this.api.get('/collection/opportunities/' + leadNumber, {});
  }

  public setDiscount(body): Observable<any> {
    return this.api.post('/collection/discount', {
      body: body,
    });
  }
}
