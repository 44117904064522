import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsApiService extends ApiService {
  protected baseURL: string = environment.notificationURL;
}
