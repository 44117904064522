import {Component} from '@angular/core';
import {MenuHelper} from '../../../helpers/MenuHelper';
import {Router} from '@angular/router';
import {PermissionsHelper} from '../../../helpers/Permission';

@Component({
    selector: 'app-default-guard',
    templateUrl: './default-guard.component.html',
    styleUrls: ['./default-guard.component.scss']
})
export class DefaultGuardComponent {

    readonly url: any = '/dashboard/leads';

    private defaultMenuItems: any = [...MenuHelper.defaultMenuItems];

    constructor(
        private router: Router,
        public permission: PermissionsHelper
    ) {
        for (const item of this.defaultMenuItems) {
            if (this.permission.boardSettings(item.type, 'is_default')) {
                this.url = item.link;
                break;
            }

        }

        this.router.navigate([this.url]);
    }
}
