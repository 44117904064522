import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-commission-groupgoal',
  templateUrl: './commission-groupgoal.component.html',
  styleUrls: ['../commissions.scss',]
})
export class CommissionGroupgoalComponent implements OnInit {
  @Input() data: any;
  @Input() role: string = '';

  public first: any;
  public second: any;

  public group_goal_type: number;

  public shift_main_target: number;
  public shift_target_type: number;

  constructor() { }

  ngOnInit(): void {
    const is_manager = this.role.includes('manager');
    // this.first = this.data;
    const reward_settings = this.data.reward_settings;
    const details = reward_settings.details.details || {};
    this.group_goal_type = reward_settings.details.group_goal_type ? +reward_settings.details.group_goal_type : undefined;

    if (!(+this.group_goal_type === 3 && is_manager)) {
      this.first = {
        commission_name: '',
        reached_by_user: is_manager ? this.data.reached_by_group : this.data.reached_by_user,
        reward_settings: {
          details: {
            for_each: details.for_each,
            for_each_value: details.for_each_value,
            target: is_manager ? details.shift_main_target : details.each_agent_target,
          }
        },
        type: this.data.type
      }
    }


    const second = {
      commission_name: '',
      reached_by_user: this.data.reached_by_group,
      reward_settings: {
        details: {
          for_each: details.for_each,
          for_each_value: details.for_each_value,
          target: details.each_agent_target,
          targets: details.targets,
          slices: details.slices,
        }
      },
      type: this.data.type
    }
    if (this.group_goal_type === 1) { 
      second.reward_settings.details.target = details.group_target;
      // second.reached_by_user = this.data.reached_by_group;
    }
    if (this.group_goal_type === 3) {
      // second.reached_by_user = this.data.reached_by_group;
    }
    if (this.group_goal_type === 4) { 
      if (+details.shift_target_type === 2) {
        this.shift_target_type = 2;
        second.reward_settings.details.targets = details.target_parts;
        second.reward_settings.details.target = details.shift_main_target;
      }
    }
    this.second = second;

    if (details.shift_target_type) {
      this.shift_target_type = +details.shift_target_type
    }
    if (details.shift_main_target) {
      this.shift_main_target = details.shift_main_target;
    }


  }

}
