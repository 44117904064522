import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PieLoaderComponent} from './pie-loader.component';

@NgModule({
    declarations: [PieLoaderComponent],
    imports: [
        CommonModule
    ],
    exports: [PieLoaderComponent]
})
export class PieLoaderModule {
}
