import {PermissionsActionTypes, PermissionsActionUnion} from '../actions/permissions.action';

const initialState = {};

export function permissionsReducer(state: any = initialState, action: PermissionsActionUnion) {
    switch (action.type) {
        case PermissionsActionTypes.SET_PERMISSIONS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}