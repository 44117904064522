export const NOTIFICATION = {
  INSTALMENT: 2,
  SCHEDULE: 1,
  CALLBACK: 3
};

export const DEFAULT_EVENT_TIME = 1800000;

export const NOTIFICATION_TYPE = {
  1: 'schedule',
  2: 'installment',
  3: 'callback',
};

export const NOTIFICATION_STATUS = {
  DECLINE: 2,
  APPROVE: 1,
  PENDING: 0,
  EXPIRE: 3,
};

export const NOTIFICATION_TYPES = [
  {
    label: '5 minutes',
    value: {
      count: 5,
      type: 'minutes'
    }
  },
  {
    label: '10 minutes',
    value: {
      count: 10,
      type: 'minutes'
    }
  },
  {
    label: '15 minutes',
    value: {
      count: 15,
      type: 'minutes'
    }
  },
  {
    label: '30 minutes',
    value: {
      count: 30,
      type: 'minutes'
    }
  },
  {
    label: '1 hour',
    value: {
      count: 1,
      type: 'hours'
    }
  },
  {
    label: '1 day',
    value: {
      count: 1,
      type: 'days'
    }
  },
  {
    label: 'Custom',
    value: 'custom'
  },
];

// export const ACCEPTED_STATUS = {
//   APPROVE: 1,
//   DECLINE: 2,
//   PENDING: 0,
// };
