import {UsersActionTypes, UsersActionUnion} from '../actions/users.action';

const initialState = {
    all: [],
    map: {}
};

export function usersReducer(state: any = initialState, action: UsersActionUnion) {
    switch (action.type) {
        case UsersActionTypes.SET_USERS: {
            return {
                ...state,
                all: action.payload
            };
        }
        case UsersActionTypes.SET_USERS_MAP: {
            return {
                ...state,
                map: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
