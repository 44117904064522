import {FieldsActionTypes, FieldsActionUnion} from '../actions/fields.action';

const initialState = {};

export function fieldsReducer(state: any = initialState, action: FieldsActionUnion) {
    switch (action.type) {
        case FieldsActionTypes.SET_FIELDS: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}
