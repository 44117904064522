import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardModalComponent } from './wizard-modal/wizard-modal.component';
import {
    MatButtonModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatTableModule,
    MatTooltipModule
} from '@angular/material';
import { PieLoaderModule } from '../general/pie-loader/pie-loader.module';
import { LoaderModule } from '../general/loader/loader.module';
import { NbCardModule, NbSpinnerModule } from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { WizardV3Component } from './wizard-v3.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        WizardV3Component,
        WizardModalComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatTableModule,
        MatExpansionModule,
        MatButtonModule,
        PieLoaderModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatProgressBarModule,
        LoaderModule,
        NbCardModule,
        NbSpinnerModule,
        TranslateModule,
    ],
    exports: [
        WizardV3Component
    ],
    entryComponents: [
        WizardModalComponent,
    ],
})
export class WizardV3Module {
}
