import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import SearchResponse from 'src/app/response/search.response';
import { PointsService } from 'src/app/services/points/points.service';

@Component({
  selector: 'app-header-points-modal',
  templateUrl: './header-points-modal.component.html',
  styleUrls: ['./header-points-modal.component.scss',]
})
export class HeaderPointsModalComponent implements OnInit {

  public loadingPoints: boolean = true;
  public loadingHistory: boolean = false;
  public blockMessage = "";

  public show_pending_points: boolean;

  public historyPaginator: SearchResponse = new SearchResponse({
    page_size: 10
  });

  public data: any;

  public info = {
    big_sale: '',
    per_product: '',
    refund: '',
    sale: '',
    sales_goal: '',
    monthly_group_goal: '',
    full_payment: '',
  };

  public chartInited = false;

  public progressTable: any[];
  public progressTableColumns: string[] = ['activity', 'progress', 'reward', 'info'];

  public historyTable: any[];
  public historyTableColumns: string[] = ['created_at', 'content', 'points', 'status'];

  public personalGoal = {
    current: 0,
    goal: 0,
    progress: 0,
    prefix: ''
  }
  public groupGoal = {
    current: 0,
    goal: 0,
    progress: 0,
    prefix: ''
  }

  public am4Core: any = (window as any).am4core;
  
  public am4Charts: any = (window as any).am4charts;

  public am4ThemesAnimated: any = (window as any).am4themes_animated;

  constructor(
    public self: MatDialogRef<HeaderPointsModalComponent>,
    public pointsService: PointsService
  ) {
    this.self.addPanelClass('header-points-modal-panel');
  }

  ngOnInit(): void {
    this.pointsService.getV2().toPromise().then((res) => {
      this.loadingPoints = false;
      this.data = res;
      this.show_pending_points = res.show_pending_points;
      if (res.goals) {
        this.setPersonalGoal(res.goals);
        this.setGrouplGoal(res.goals);
      }
      if (res.progress) {
        this.setProgressTableInfo(res.progress);
        this.setProgressTable(res.progress);
      }
      if (res.general_info) {
        this.initDonutChart(res.general_info);
      }
      if (res.blocked_message) {
        this.blockMessage = res.blocked_message;
      }
    }, (err) => {
      this.loadingPoints = false;
    });
  }

  public setPersonalGoal(goals) {
    if (!goals.personal_goal) { return; }
    if (goals.personal_goal.goal / 1000 >= 1 || goals.personal_goal.current / 1000 >= 1) {
      this.personalGoal.goal = goals.personal_goal.goal / 1000;
      this.personalGoal.current = goals.personal_goal.current / 1000;
      this.personalGoal.prefix = 'k'
    } else {
      this.personalGoal.goal = goals.personal_goal.goal;
      this.personalGoal.current = goals.personal_goal.current;
    }
    this.personalGoal.progress = Math.round(goals.personal_goal.current / (goals.personal_goal.goal || 1) * 100);
  }
  public setGrouplGoal(goals) {
    if (!goals.group_goal) { return; }
    if (goals.group_goal.goal / 1000 >= 1 || goals.group_goal.current / 1000 >= 1) {
      this.groupGoal.goal = goals.group_goal.goal / 1000;
      this.groupGoal.current = goals.group_goal.current / 1000;
      this.groupGoal.prefix = 'k'
    } else {
      this.groupGoal.goal = goals.group_goal.goal;
      this.groupGoal.current = goals.group_goal.current;
    }
    this.groupGoal.progress = Math.round(goals.group_goal.current / (goals.group_goal.goal || 1) * 100);
  }


  public setProgressTableInfo(progress) {
    if (progress.big_sale) {
      let str = `Dear account manager, \nyou will get points if sales amount during one day for the same client is:`;
      const info = progress.big_sale.info;
      for (let i = 0; i < info.length; i++) {
        const ci = i;
        str += `\n>= ${info[ci].goal} $ - ${info[ci].point} points`;
      }
      this.info.big_sale = str;
    }
    if (progress.per_product) {
      let str = `Dear account manager, \nyou will get your points for selling (ONLY in full payment) the following products:`;
      const info = progress.per_product.info;
      for (let i = 0; i < info.length; i++) {
        const ci = i;
        str += `\n- ${info[ci].product} `
        for (let j = 0; j < info[ci].steps.length; j++) {
          const cj = j;
          str += `\n>= ${info[ci].steps[cj].goal} - ${info[ci].steps[cj].point} points`;
        }
      }
      this.info.per_product = str;
    }
    if (progress.full_payment) {
      let str = `Dear account manager, \nyou will get your points for selling (ONLY in full payment) the following products:`;
      const info = progress.full_payment.info;
      for (let i = 0; i < info.length; i++) {
        if (info[i].steps && info[i].steps.length <= 0) {
          continue;
        }
        const ci = i;
        str += `\n- ${info[ci].product}`;
        for (let j = 0; j < info[ci].steps.length; j++) {
          const cj = j;
          str += `\n<= ${info[ci].steps[cj].goal} - ${info[ci].steps[cj].point} points`;
        }
      }
      this.info.full_payment = str;
    }
    if (progress.refund) {
      let str = `Dear account manager, \nyou will loose your points for the following actions:`;
      const info = progress.refund.info;
      for (let i = 0; i < info.length; i++) {
        const ci = i;
        if (ci === info.length - 1) {
          str += `\n- sales amount above ${info[ci].goal} $ - ${info[ci].point} points`;
        } else {
          str += `\n- sales amount in range from ${info[ci].goal} $ to ${info[ci + 1].goal} $ - ${info[ci].point} points`;
        }
      }
      this.info.refund = str;
    }
    if (progress.sale) {
      let str = `Dear account manager, \nyou will get your points for the following actions:`;
      const info = progress.sale.info;
      for (let i = 0; i < info.length; i++) {
        const ci = i;
        if (ci === info.length - 1) {
          str += `\n- sales amount above ${info[ci].goal} $ - ${info[ci].point} points`;
        } else {
          str += `\n- sales amount in range from ${info[ci].goal} $ to ${info[ci + 1].goal} $ - ${info[ci].point} points`;
        }
      }
      this.info.sale = str;
    }
    if (progress.sales_goal) {
      let point = 0;
      if (this.data.progress.sales_goal && this.data.progress.sales_goal.info && this.data.progress.sales_goal.info[0] && this.data.progress.sales_goal.info[0].point) {
        point = this.data.progress.sales_goal.info[0].point;
      }
      let str = `Dear account manager, \nyou will get ${point} points once you achieve \nyour monthly goal - ${this.data.goals.personal_goal.goal} $.`;
      this.info.sales_goal = str;
    }
    if (progress.monthly_group_goal) {
      let point = 0;
      if (this.data.progress.monthly_group_goal && this.data.progress.monthly_group_goal.info && this.data.progress.monthly_group_goal.info[0] && this.data.progress.monthly_group_goal.info[0].point) {
        point = this.data.progress.monthly_group_goal.info[0].point;
      }
      let str = `Dear account manager, \nyou will get ${point} points once the group achieve \n monthly goal - ${this.data.goals.group_goal.goal} $.`;
      this.info.monthly_group_goal = str;
    }
  }
  public setProgressTable(progress) {
    let arr = [];
    for (const key in progress) {
      arr.push(Object.assign({ key }, progress[key]));
    }
    this.progressTable = arr;
  }


  public initDonutChart(general_info) {
    if (!document.getElementById('hpm-donut')) { return; }
    // Themes begin
    this.am4Core.useTheme(this.am4ThemesAnimated);
    this.am4Core.addLicense("ch-custom-attribution");
    // Themes end
    // Create chart instance
    let chart = this.am4Core.create("hpm-donut", this.am4Charts.PieChart);
    // Add data
    if (this.show_pending_points) {
      chart.data = [ {
        "Type": "Active",
        "Points": general_info.active_points,
        "color": this.am4Core.color("#009A15")
      }, {
        "Type": "Pending",
        "Points": general_info.pending,
        "color": this.am4Core.color("#7C8896")
      }];
    } else {
      chart.data = [ {
        "Type": "Active",
        "Points": general_info.active_points,
        "color": this.am4Core.color("#009A15")
      }];
    }
    // Set inner radius
    chart.innerRadius = this.am4Core.percent(50);

    // Add and configure Series
    let pieSeries = chart.series.push(new this.am4Charts.PieSeries());
    pieSeries.dataFields.value = "Points";
    pieSeries.dataFields.category = "Type";
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.stroke = this.am4Core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    this.chartInited = true;
  }



  //
  //
  //
  public onSelectedTabChange(evt) {
    if (this.loadingHistory || this.historyTable) { return; }
    if (evt.index === 1) {
      this.getHistory();
    }
    if (evt.index === 0) {
      if (!this.chartInited) {
        this.initDonutChart(this.data.general_info)
      }
    }
  }
  public setHistoryTable(data) {
    this.historyTable = data;
  }

  public getHistory() {
    this.loadingHistory = true;

    this.pointsService.historyV2(
      this.historyPaginator.getReqData()
    ).toPromise().then((res) => {

      this.historyPaginator = Object.assign(this.historyPaginator, res);
      this.loadingHistory = false;
      this.setHistoryTable(res.data)
    }, (err) => {
      this.loadingHistory = false;
    })
  }


  public changePage(event: any): void {
    this.historyPaginator.page = event.pageIndex;
    this.historyPaginator.page_size = event.pageSize;
    this.getHistory();
  }
}
