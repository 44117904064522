export function encodeObj(obj: Object): string {
  if (!obj || Object.keys(obj).length <= 0) {
    return '';
  }

  const req =  Object.keys(obj).map(k => {
    if (typeof obj[k] === 'object') {
      return `${encodeURIComponent(k)}=${JSON.stringify(obj[k])}`;
    }
    return `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
  }).join('&');

  return encodeURI(req);
}
