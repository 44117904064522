import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CommissionBasicComponent } from './commission-basic/commission-basic.component';
import { CommissionSplitComponent } from './commission-split/commission-split.component';
import { CommissionPeriodComponent } from './commission-period/commission-period.component';
import { CommissionGroupgoalComponent } from './commission-groupgoal/commission-groupgoal.component';
import { PercentOfNumberPipeModule } from './commission-period/percent-of-number-pipe/percent-of-number.pipe.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomTranslatePipeModule } from 'src/app/pipes/customTranslate/customTranslate.pipe.module';
import {ToCurrencyPipeModule} from '../../../../pipes/to-currency/to-currency.pipe.module';

@NgModule({
  declarations: [
    CommissionBasicComponent,
    CommissionSplitComponent,
    CommissionPeriodComponent,
    CommissionGroupgoalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    CustomTranslatePipeModule,

    PercentOfNumberPipeModule,
    ToCurrencyPipeModule,
  ],
  exports: [
    CommissionBasicComponent,
    CommissionSplitComponent,
    CommissionPeriodComponent,
    CommissionGroupgoalComponent,
  ]
})
export class CommissionsModule {
}
