import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpentokService {

  constructor(private api: ApiService) { }

  public checkApplication(leadId): Observable<any> {
    return this.api.get('/opentok/check-application/' + leadId, {});
  }

  public end(body): Observable<any> {
    return this.api.post('/leads/opentok/end', {body});
  }

  public decline(body): Observable<any> {
    return this.api.post('/leads/opentok/decline', {body});
  }

}
