import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { WizardV3Module } from 'src/app/components/wizard-v3/wizard-v3.module';
import { CommissionsV3Module } from 'src/app/components/commissions-v3/commissions-v3.module';

import { HeaderBarsComponent } from './header-bars.component';
import { HeaderPointsModule } from 'src/app/components/commissions-v3/header-points/header-points.module';
import { ProductCommissionModule } from 'src/app/components/commissions-v3/product-commission/product-commission.module';

@NgModule({
  declarations: [
    HeaderBarsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    WizardV3Module,
    HeaderPointsModule,
    CommissionsV3Module,
    
    ProductCommissionModule,
  ],
  exports: [
    HeaderBarsComponent
  ]
})
export class HeaderBarsModule {
}
