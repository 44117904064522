import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import localStore from 'store';
import { QuizResultComponent } from './quiz-result/quiz-result.component';
import { selectorTypes } from './constants/selectorTypes';
import { QuizRequestComponent } from './quiz-request/quiz-request.component';
import { quizTypes } from 'src/app/constants/quizzes';
import { quizEndStatus } from './constants/quizEndStatus';
import jwt_decode from "jwt-decode";
import { answersVariants } from './constants/answersVariants';
import { deepClone } from 'src/app/helpers/deepClone';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-quiz-popup',
  templateUrl: './quiz-popup.component.html',
  styleUrls: ['./quiz-popup.component.scss',],
  encapsulation: ViewEncapsulation.None
})
export class QuizPopupComponent implements OnInit {

  // private JwtPrivateKey = "CAC8379FBC6BEE5AF5FE6347062AFA0B";
  // private JwtPrivateKey = "CHJCQcjhcgjcfg568duCJTR%yt5yt6";

  private enableSave: boolean = true;


  public loader = false;
  // public viewResultBtn: string;

  public showSection = 0;
  public sections = {
    start: 0,
    questions: 1,
    timeOver: 4,
    continue: 8,
    over: 9,
  }

  public quizOn: string;

  public showRequestBtn: boolean;

  public questions: any[] = [];
  public questionsU: any[] = [];
  public questionsLayout: any[] = [];
  public answers: any[] = [];


  // timer
  public timerStart: boolean;
  public timerStop: boolean;

  public info = {
    id: 0,
    group_id: 0,
    name: 'Quiz Form Name',
    description: 'Short description of the quiz',
    partial_save: false,
    duration: 0,
    show_progress: false,
    //
    // share_result: false,
    // show_correct_answers: false,
    each_correct_answer: false,
    //
    encoded_form_fields: null,
    decoded: null
  }

  public progress = {
    current: 0,
    total: 0,
  }

  private timeLeft: number = 0;

  public showStepIncomplete: boolean;

  public noAnswersLength: number = 0;

  public selectorTypes = selectorTypes;

  public showStepError: boolean;
  public showMessageError: boolean;
  public test = this.sanitizer.bypassSecurityTrustHtml('Please watch video and answer the questions. <iframe width="907" height="510" src="https://www.youtube.com/embed/oD6SB1wgR8U" title="Наука Залужного. Що зробила команда міносвіти. Велике дослідження SavEd. Освіта #5" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>')

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private self: MatDialogRef<QuizPopupComponent>,
    private dialog: MatDialog,
    private matSnack: MatSnackBar,
    private quizService: QuizService,
    private sanitizer: DomSanitizer,

  ) {
    self.addPanelClass('quiz-popup-panel');
    if (data.saved) {
      this.onContinue();
    } else {
      if (data.quizOn) { this.quizOn = data.quizOn; }
      if (data.quiz) { this.setData(data.quiz); }
    }
  }

  ngOnInit(): void {}

  private onContinue(): void {
    const quizSave = localStore.get('quizSave');
    if (!quizSave) { return; }
    //
    this.setData(quizSave.quiz);
    this.quizOn = quizSave.quizOn;
    this.answers = quizSave.answers;
    this.info.duration = quizSave.timeLeft || 0;
    //
    // this.info.share_result = quizSave.quiz.share_result;
    // this.info.show_correct_answers = quizSave.quiz.show_correct_answers;
    this.info.show_progress = quizSave.quiz.show_progress;
    this.info.partial_save = quizSave.quiz.partial_save;
    this.info.each_correct_answer = quizSave.quiz.each_correct_answer;
    //
    let eachCorrectCurrent = 0;
    if (quizSave.quiz.each_correct_answer) {
      this.info.encoded_form_fields = quizSave.encoded_form_fields;
      this.info.decoded = this.decodeAnswers(quizSave.encoded_form_fields);
      //
      for (const sn in quizSave.disabled) {
        if (quizSave.disabled[sn]) {
          this.setCorrectAnswers(sn);
          eachCorrectCurrent++;
        }
      }
      //
      this.understand.disabled = quizSave.disabled;
      this.understand.disabled = deepClone(quizSave.disabled);
      this.understand.allowGoNext = deepClone(quizSave.disabled);
      this.understand.isCorrect = deepClone(quizSave.disabled);
    }
    //

    if (quizSave.quiz.each_correct_answer) {
      if (eachCorrectCurrent > (this.answers.length - 1)) { eachCorrectCurrent = this.answers.length - 1; }
      this.progress.current = eachCorrectCurrent;
    } else {
      let current = this.answers.findIndex((answer, aI) => {
        if (this.questions[aI].type === this.selectorTypes.checkbox) {
          return !answer.find(el => el);
        } else if (this.questions[aI].type === this.selectorTypes.radiobox) {
          return !(answer || answer === 0);
        } else if (this.questions[aI].type === this.selectorTypes.text) {
          return !((answer || '').trim());
        }
      });
      if (current === -1) { current = 0; }
      if (current > (this.answers.length - 1)) { current = this.answers.length - 1; }
      this.progress.current = current;
    }


    this.showSection = this.sections.continue;

    const qTimerDisable = quizSave.qTimerDisable;
    qTimerDisable.forEach((qTD) => {
      if (qTD.timeLeft < qTD.time_duration) {
        qTD.time_duration = qTD.timeLeft > 0 ? qTD.timeLeft : 5;
      }
    });
    this.qTimerDisable = qTimerDisable;

  }

  private saveQuizTimeout: any;
  private saveQuiz(timeOut: number = 100): void {
    clearTimeout(this.saveQuizTimeout);
    this.saveQuizTimeout = setTimeout(() => {
      localStore.set('quizSave', {
        quiz: { ...this.info, form_fields: this.questions },
        answers: this.answers,
        timeLeft: this.timeLeft,
        encoded_form_fields: this.info.encoded_form_fields,
        disabled: this.understand.disabled,
        qTimerDisable: this.qTimerDisable,
        quizOn: this.quizOn,
      });
    }, timeOut);
  }

  private clearQuizSave(): void {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
    localStore.remove('quizSave');
    setTimeout(() => {
      localStore.remove('quizSave');
    }, 500)
    setTimeout(() => {
      localStore.remove('quizSave');
    }, 1000)
  }

  public onTimerTick(timeLeft: number): void {
    this.timeLeft = timeLeft;
  }

  public onCheck(): void {
    if (this.enableSave) {
      this.saveQuiz(500);
    }
    this.showStepError = false;
  }



  public qTimerDisable: {hasTimer:boolean,time_duration:number,timeLeft:number,disabled:boolean}[] = [];
  public onQTimeEnd(qI, timeLeft) {
    if (!this.qTimerDisable[qI].time_duration) { return; }
    if (timeLeft) { return; }
    this.qTimerDisable[qI].disabled = true;
    this.qTimerDisable[qI].time_duration = timeLeft;
    this.onStepNext(true);
  }
  public onQTimeTick(qI, timeLeft) {
    if (timeLeft < 0) { return; }
    this.qTimerDisable[qI].timeLeft = timeLeft;
  }


  private setData(res: any): void {
    if (!res) { return; }
    const settings = res.settings || {};
    this.info = {
      id: res.id,
      group_id: res.group_id || 0,
      name: res.name,
      description: res.description,
      partial_save: res.partial_save,
      duration: settings.duration,
      show_progress: settings.show_progress,
      //
      // share_result: settings.share_result,
      // show_correct_answers: settings.show_correct_answers,
      each_correct_answer: settings.each_correct_answer,
      encoded_form_fields: res.encoded_form_fields,
      decoded: this.decodeAnswers(res.encoded_form_fields)
    };
    this.questions = res.form_fields || [];

    const questionsU = deepClone(res.form_fields || []);
    questionsU.forEach((question) => {
      if (!question.general_checked) {
        question.general_checked = new Array(Object.keys(question.options).length).fill(false);
      }
      const newOptions = [];
      for (const index in question.options) {
        newOptions[index] = question.options[index];
      }
      question.options = newOptions;
    });
    this.questionsU = questionsU;

    this.qTimerDisable = this.questions.map((question) => {
      return {
        hasTimer: !!question.time_duration,
        time_duration: question.time_duration,
        timeLeft: question.time_duration,
        disabled: false,
      }
    });

    this.correct = this.questions.map((question) => (
      new Array(Object.keys(question.options).length).fill(answersVariants.nothing)
    ));

    this.questionsLayout = this.questions.map((question) => (
      { options: new Array(Object.keys(question.options).length).fill(true) }
    ));
    this.answers = this.questions.map((question) => {
      switch (question.type) {
        case this.selectorTypes.text: return '';
        case this.selectorTypes.radiobox: return null;
        case this.selectorTypes.checkbox: return new Array(Object.keys(question.options).length).fill(false);
        default: return null;
      }
    });
    this.progress.total = this.answers.length;
  }

  private decodeAnswers(encoded_form_fields) {
    if (!encoded_form_fields) { return {} }
    let decoded: any = jwt_decode(encoded_form_fields) || {};
    decoded = decoded ? decoded.RightAnswer : {};
    for (const qId in decoded) {
      if (!Array.isArray(decoded[qId]) && typeof decoded[qId] === 'object') {
        let tmp = [];
        for (const oI in decoded[qId]) {
          tmp[oI] = decoded[qId][oI];
        }
        decoded[qId] = tmp;
      }
    }
    return decoded;
  }

  private autoSaveInterval: any;
  public onStart(): void {
    this.showSection = this.sections.questions;
    this.timerStart = true;
    // autosave
    if (this.enableSave) {
      this.autoSaveInterval = setInterval(() => {
        this.saveQuiz();
      }, 10000);
    }
  }

  public onFinish(): void {
    // onFinish
    this.self.close();
    this.afterClose();
  }

  private timeOut: boolean;
  public onTimerEnd(): void {
    this.timeOut = true;
    this.showSection = this.sections.over;
    this.beforeSubmit(true)
  }

  public onStepBack(): void {
    if (this.progress.current > 0) {
      this.progress.current--;
      this.showMessageError = false;
      this.showStepError = false;
      this.showNoAnswerError = false;
    }
  }

  public showNoAnswerError: boolean;
  public onStepNext(qTimeEnd: boolean): void {
    const qI = this.progress.current;

    const gotoNext = () => {
      if (this.info.partial_save) {
        this.onSubmitPartial();
      } else {
        this.progress.current++;
      }
      this.showStepError = false;
      this.showMessageError = false;
      this.showNoAnswerError = false;
      this.saveQuiz();
    }

    const onNext = () => {
      if (this.qTimerDisable[qI].hasTimer && this.qTimerDisable[qI].timeLeft === 0) {
        this.qTimerDisable[qI].disabled = true;
      }
      if (this.qTimerDisable[qI].hasTimer && this.info.each_correct_answer) {
        this.qTimerDisable[qI].disabled = true;
      }
      if (this.info.each_correct_answer) {
        this.setCorrectAnswers(qI);
        if (this.understand.allowGoNext[qI]) {
          if (this.progress.current + 1 < this.progress.total) {
            this.understand.isCorrect[qI] = true;
            gotoNext();
          } else {
            if (this.qTimerDisable[qI].hasTimer && qI === this.qTimerDisable.length - 1) {
              if (qTimeEnd) { return; }
            }
            this.beforeSubmit();
          }
        } else {
          this.understand.allowGoNext[qI] = true;
          this.saveQuiz();
        }
        this.understand.disabled[qI] = true;
      } else {
        if (this.progress.current + 1 < this.progress.total) {
          gotoNext();
        } else {
          if (this.qTimerDisable[qI].hasTimer && qI === this.qTimerDisable.length - 1) {
            if (qTimeEnd) { return; }
          }
          this.beforeSubmit();
        }
      }
    }



    // if (this.info.partial_save && !this.info.each_correct_answer) {
    //   onNext();
    // } else {
      if (this.questions[qI].type === this.selectorTypes.text) {
        if ((this.answers[qI] || '').trim()) {
          onNext();
          return;
        } else if (this.info.each_correct_answer && !this.showNoAnswerError) {
          this.showNoAnswerError = true;
        }
      } else if (this.questions[qI].type === this.selectorTypes.checkbox) {
        if (this.answers[qI].find(el => el)) {
          onNext();
          return;
        } else if (this.info.each_correct_answer && !this.showNoAnswerError) {
          this.showNoAnswerError = true;
        }
      } else if (this.questions[qI].type === this.selectorTypes.radiobox) {
        if (this.answers[qI] || this.answers[qI] === 0) {
          onNext();
          return;
        } else if (this.info.each_correct_answer && !this.showNoAnswerError) {
          this.showNoAnswerError = true;
        }
      }
      // error
      if (this.understand.disabled[this.progress.current]) {
        onNext();
      } else if (this.qTimerDisable[qI].disabled || qTimeEnd) {
        onNext();
      } else {
        this.showStepError = true;
      }
    // }
  }

  private beforeSubmit(timeOver?): void {
    this.clearQuizSave();
    // let noAnswers = [];
    // this.answers.forEach((el, i) => {
    //   if (!el && el !== 0) {
    //     noAnswers.push(i);
    //   } else if (Array.isArray(el)) {
    //     if (!el.find(answr => answr)) {
    //       noAnswers.push(i);
    //     }
    //   }
    // });
    // this.noAnswersLength = noAnswers.length;
    // if (this.info.partial_save && !this.info.each_correct_answer) {
      // if (noAnswers.length) {
        // this.onSubmitPartial();
      // } else {
        this.onSubmit();
      // }
    // } else {
      // if (!(noAnswers.length && !timeOver)) {
        // this.onSubmit();
      // } else {
        // this.onSubmit();
        // back to not filled answers
        // this.dialog.open(QuizNoAnswersComponent, {
        //   data: { noAnswers: noAnswers.map((index) => {
        //     const question = this.questions[index];
        //     return { index: index + 1, name: question.name }
        //   })}
        // }).afterClosed().toPromise().then(() => {
        //   this.progress.current = noAnswers[0];
        // });
        //
      // }
    // }
  }

  private getReqAnswers(): any[] {
    const progress = [];
    this.questions.forEach((question, index) => {
      let form_field: any = {
        form_field: {
          id: question.id,
          name: question.name,
          type: question.type,
          answer_type: question.answer_type,
          order_position: question.order_position,
          options: question.options,
          weights: question.weights,
          weight: question.weight,
          general_checked: question.general_checked,
          general_radio: question.general_radio,
          answer: question.answer
        },
      };
      const answer = this.answers[index];
      if (question.type === this.selectorTypes.checkbox) {
        form_field.answer = answer;
      } else if (question.type === this.selectorTypes.radiobox) {
        form_field.answer = new Array(Object.keys(question.options).length).fill(false);
        if (answer !== null) { form_field.answer[answer] = true; }
      } else if (question.type === this.selectorTypes.text) {
        form_field.text_answer = answer;
      }
      progress[index] = form_field;
    });
    //
    return progress;
  }

  private getPartialAnswer() {
    const qI = this.progress.current;
    return { [this.questions[qI].id]: this.answers[qI] };
  }

  public pause: boolean;
  public onSubmitPartial(): void {
    if (this.submittedPartial[this.progress.current]) {
      this.afterSubmitPartial();
    } else {
      this.loader = true;
      this.pause = true;
      requestAnimationFrame(() => {
        this.quizService.partialSave(
          this.info.id,
          this.getPartialAnswer()
        ).toPromise().then((res) => {
          this.afterSubmitPartial();
          this.loader = false;
          this.pause = false;
        }, (err) => {
          this.pause = false;
          this.onError(err);
        });
      });
    }
  }

  public onSubmit(): void {
    this.loader = true;
    requestAnimationFrame(() => {
      this.quizService.submitQuiz(
        this.info.id,
        this.info.group_id,
        this.getReqAnswers()
      ).toPromise().then((res) => {
        this.afterSubmit(res);
      }, (err) => {
        this.onError(err);
      });
    });
  }

  private submittedPartial = {};
  private afterSubmitPartial() {
    this.qTimerDisable[this.progress.current].disabled = true;
    this.submittedPartial[this.progress.current] = true;
    if (this.progress.current + 1 < this.progress.total) {
      this.progress.current++;
    } else {
      // cant be
    }
  }

  private afterSubmit(res: any): void {
    this.clearQuizSave();
    if (this.data.callback) {
      this.data.callback(quizEndStatus.submitted);
    }
    if (this.quizOn === quizTypes.onCent) {
      const quizzesToDo = localStore.get('quizzesToDo') || [];
      const index = quizzesToDo.findIndex(el => el.id === this.info.id);
      if (index !== -1) {
        quizzesToDo.splice(index, 1);
        localStore.set('quizzesToDo', quizzesToDo);
      }
    }
    this.clearQuizSave();
    const user = localStore.get('user');
    this.showSection = this.sections.over;
    this.loader = false;
    if (res.Result || res.FormFields) {
      this.dialog.open(QuizResultComponent, {
        disableClose: true,
        data: {
          timeOut: this.timeOut,
          user_id: user.id,
          info: this.info,
          answers: this.answers,
          data: res
        }
      }).afterClosed().toPromise().then(() => {
        this.afterClose();
      });
      this.self.close();
    } else {
      this.showRequestBtn = !res.Passed;
      this.showSection = this.sections.over;
    }
  }

  public onRequest(): void {
    this.self.close();
    this.dialog.open(QuizRequestComponent, {
      disableClose: true,
      data: {
        quiz_id: this.info.id
      }
    });
  }

  private afterClose(): void {
    if (this.data.callback) {
      this.data.callback(quizEndStatus.closed);
    }
  }

  //
  //
  //
  private onError(err: any): void {
    this.loader = false;
    let msg;
    if (err.error) {
      if (typeof err.error === 'string') {
        msg = err.error;
      } else if (typeof err.error === 'object') {
        if (typeof err.error.error === 'string') {
          msg = err.error.error;
        } else {
          msg = JSON.stringify(err.error);
        }
      }
    }
    this.matSnack.open(msg, 'Dismiss', {
      duration: 3000,
      panelClass: ['error-snackbar']
    });
  }
























  public correct: any[] = [];
  public answersVariants = answersVariants;
  // public noAnswers: any = {};
  public understand = {
    isCorrect: {},
    allowGoNext: {},
    disabled: {},
  }
  private setCorrectAnswers(qI) {
    const question = this.questionsU[qI];

    let achievedPoints: number = 0;

    this.correct[qI] = new Array(question.options.length);
    question.options.forEach((option, oI) => {
      if (question.type === this.selectorTypes.text) {
        // text
        if ((this.answers[qI] || '').trim()) {
          this.correct[qI][oI] = this.answersVariants.correct;
        } else {
          this.correct[qI][oI] = this.answersVariants.incorrect;
        }
        // text
      } else if (question.type === this.selectorTypes.checkbox) {
        // checkbox
        let checker: any;
        if (question.answer_type === 'each') {
          checker = this.info.decoded[question.id][oI];
        } else if (question.answer_type === 'general') {
          checker = this.info.decoded[question.id][oI];
        }
        //
        if (this.answers[qI][oI] && checker) {
          this.correct[qI][oI] = this.answersVariants.correct;
          achievedPoints++;
        } else if (this.answers[qI][oI] && !checker) {
          this.correct[qI][oI] = this.answersVariants.incorrect;
          achievedPoints--;
        } else if (!this.answers[qI][oI] && checker) {
          this.correct[qI][oI] = this.answersVariants.rightAnswer;
        } else {
          this.correct[qI][oI] = this.answersVariants.noAnswer;
        }
        // checkbox
      } else if (question.type === this.selectorTypes.radiobox) {
        const userChecked = this.answers[qI];
        // radiobox
        if (question.answer_type === 'each') {
          if (userChecked === oI) {
            if (this.info.decoded[question.id][oI]) {
              this.correct[qI][oI] = this.answersVariants.correct;
            } else {
              this.correct[qI][oI] = this.answersVariants.incorrect;
            }
          } else {
            if (this.info.decoded[question.id][oI]) {
              this.correct[qI][oI] = this.answersVariants.rightAnswer;
            }
          }
        } else if (question.answer_type === 'general') {
          if (userChecked === oI) {
            if (userChecked === this.info.decoded[question.id]) {
              this.correct[qI][oI] = this.answersVariants.correct;
            } else {
              this.correct[qI][oI] = this.answersVariants.incorrect;
            }
          } else {
            if (this.info.decoded[question.id] === oI && userChecked !== this.info.decoded[question.id]) {
              this.correct[qI][oI] = this.answersVariants.rightAnswer;
            } else {
              this.correct[qI][oI] = this.answersVariants.noAnswer;
            }
          }
        }
        // radiobox
      }
    });


    //
    const answer = this.answers[qI];
    if (question.type === selectorTypes.text) {
      achievedPoints = (answer || '').trim() ? this.info.decoded[question.id][0] : 0;
    } else if (question.type === selectorTypes.checkbox) {
      if (question.answer_type === 'each') {
        let result = 0;
        this.info.decoded[question.id].forEach((score, wI) => {
          if (answer[wI] && score) {
            result += score;
          }
        });
        achievedPoints = result;
      }
    } else if (question.type === selectorTypes.radiobox) {
      if (question.answer_type === 'general') {
        achievedPoints = this.info.decoded[question.id] === answer ? 1 : 0;
      } else if (question.answer_type === 'each') {
        achievedPoints = this.info.decoded[question.id][answer] || 0;
      }
    }

    let totalPoints: number = 0;
    if (question.type === selectorTypes.text) {
      totalPoints = this.info.decoded[question.id][0];
    } else if (question.type === selectorTypes.checkbox) {
      if (question.answer_type === 'general') {
        totalPoints = (this.info.decoded[question.id].filter(a => a)).length;
      } else if (question.answer_type === 'each') {
        for (const key in this.info.decoded[question.id]) {
          totalPoints += this.info.decoded[question.id][key];
        }
      }
    } else if (question.type === selectorTypes.radiobox) {
      if (question.answer_type === 'general') {
        totalPoints = 1;
      } else if (question.answer_type === 'each') {
        let max = 0;
        for (const key in this.info.decoded[question.id]) {
          if (this.info.decoded[question.id][key] > max) { max = this.info.decoded[question.id][key]; }
        }
        totalPoints = max;
      }
    }

    if (achievedPoints >= totalPoints) {
      if (question.type === selectorTypes.text && totalPoints === 0 && !(answer || '').trim()) {
        this.understand.isCorrect[qI] = false;
        this.showMessageError = true;
        this.showStepError = false;
      } else {
        this.understand.allowGoNext[qI] = true;
        this.understand.isCorrect[qI] = true;
      }
    } else {
      this.understand.isCorrect[qI] = false;
      this.showMessageError = true;
      this.showStepError = false;
    }
    this.understand.disabled[qI] = true;
  }
  //
}
