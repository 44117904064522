import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallStatisticsDurationComponent} from './call-statistics-duration.component';

@NgModule({
    declarations: [
        CallStatisticsDurationComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        CallStatisticsDurationComponent
    ]
})
export class CallStatisticsDurationModule {
}
