import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WizardsService {

    constructor(
        private api: ApiService
    ) {
    }

    public getWizardsData(params): Observable<any> {
        return this.api.get('/wizards', {
            params
        });
    }

    public getWizardsByType(params, type): Observable<any> {
        return this.api.get('/wizards/' + type, {
            params
        });
    }

    public searchWizards(params): Observable<any> {
      return this.api.get('/wizards/search', {
          params
      });
    }
}
