import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterLeadModalComponent } from './register-lead-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule, MatFormFieldModule, MatInputModule} from '@angular/material';
import {SmallLoaderModule} from '../../general/small-loader/small-loader.module';
import {Ng2TelInputModule} from 'ng2-tel-input';

@NgModule({
  declarations: [RegisterLeadModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SmallLoaderModule,
    Ng2TelInputModule
  ]
})
export class RegisterLeadModalModule { }
