import {NgModule} from '@angular/core';

import {UsersLoginPopupComponent} from './users-login-popup.component';
import {MatButtonModule, MatIconModule, MatMenuModule, MatPaginatorModule, MatTableModule, MatTooltipModule} from '@angular/material';
import {CommonModule} from '@angular/common';
import {AgentInfoModule} from '../../../../general/agent-info/agent-info.module';
import {AssignedToModule} from '../../../../general/assigned-to/assigned-to.module';
import {LoaderModule} from '../../../../general/loader/loader.module';
import {PieLoaderModule} from '../../../../general/pie-loader/pie-loader.module';

@NgModule({
  declarations: [UsersLoginPopupComponent],
    imports: [
        MatTableModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        CommonModule,
        AgentInfoModule,
        AssignedToModule,
        MatPaginatorModule,
        LoaderModule,
        MatTooltipModule,
        PieLoaderModule
    ],
  exports: [
  ],
  entryComponents: [
    UsersLoginPopupComponent
  ]
})
export class UsersLoginPopupModule {

}
