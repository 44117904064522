import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterTableComponent} from './filter-table.component';
import {NbCardModule, NbInputModule} from '@nebular/theme';
import {FormsModule} from '@angular/forms';
import {DatePickerModule} from './date-picker/date-picker.module';
import {MatTooltipModule} from '@angular/material';
import {AutocompleteModule} from '../autocomplete/autocomplete.module';
import {BoolFilterModule} from './bool-filter/bool-filter.module';
import {AutocompleteBoolModule} from '../autocomplete-bool/autocomplete-bool.module';
import {CustomTranslatePipeModule} from '../../../pipes/customTranslate/customTranslate.pipe.module';

@NgModule({
  declarations: [
    FilterTableComponent
  ],
  imports: [
    CommonModule,
    NbInputModule,
    FormsModule,
    DatePickerModule,
    MatTooltipModule,
    AutocompleteModule,
    AutocompleteBoolModule,
    NbCardModule,
    BoolFilterModule,
    CustomTranslatePipeModule
  ],
  exports: [
    FilterTableComponent
  ],
  entryComponents: [
    FilterTableComponent
  ]
})
export class FilterTableModule {
}
