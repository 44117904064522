import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LevelModalComponent } from './level-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        LevelModalComponent
    ],
    imports: [
        CommonModule,

        MatTooltipModule
    ],
    exports: [
        LevelModalComponent
    ],
    entryComponents: [
        LevelModalComponent
    ]
})
export class LevelModalModule {
}
