import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BubbleLoaderComponent} from './bubble-loader.component';

@NgModule({
    declarations: [
        BubbleLoaderComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        BubbleLoaderComponent
    ]
})
export class BubbleLoaderModule {
}
