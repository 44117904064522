export class Options {

    public options: any = {};

    constructor(initial?: any) {
        if (initial) {
            for (const name in initial) {
                if (initial.hasOwnProperty(name)) {
                    this.options[name] = initial[name];
                }
            }
        }
    }

    public set(name: string, value: any) {
        this.options[name] = value;
    }

    public get(name: string) {
        if (this.options[name]) {
            return this.options[name];
        }
        return [];
    }

    public findValue(name: string, key: string, value: any) {
        return this.get(name).find((el) => {
            return el[key] === value;
        });
    }

}
