import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-call-layout',
    templateUrl: './call-layout.component.html',
    styleUrls: ['./call-layout.component.scss']
})
export class CallLayoutComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
