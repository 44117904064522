import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AuthGuardService} from './services/auth-guard/auth-guard.service';
import {MainComponent} from './layouts/main/main.component';
import {DefaultComponent} from './layouts/default/default.component';
import {DefaultGuardComponent} from './components/general/default-guard/default-guard.component';
import {CallLayoutComponent} from './layouts/call-layout/call-layout.component';

const routes: Routes = [
    {
        path: '', component: MainComponent, canActivate: [AuthGuardService], children: [

            {path: '', pathMatch: 'full', component: DefaultGuardComponent},
            {path: 'dashboard', pathMatch: 'full', component: DefaultGuardComponent},
            {path: 'dashboard/:id', pathMatch: 'full', loadChildren: './components/agents-dash/agents-dash.module#AgentsDashModule'},
            {path: 'opportunities', loadChildren: './pages/opportunities/opportunities.module#OpportunitiesModule'},
            {path: 'applications', loadChildren: './pages/applications/applications.module#ApplicationsModule'},
            {path: 'applications/:id', loadChildren: './pages/applications/applications.module#ApplicationsModule'},
            {path: 'application/:type/:id', loadChildren: './pages/application/application.module#ApplicationModule'},
            {path: 'risks', loadChildren: './pages/risks/risks.module#RisksModule'},
            {path: 'refunds', loadChildren: './pages/refunds/refunds.module#RefundsModule'},
            {path: 'referral-program', loadChildren: './pages/referral-program/referral-program.module#ReferralProgramModule'},
            {path: 'students', loadChildren: './pages/students/students.module#StudentsModule'},
            {path: 'csr', pathMatch: 'full', loadChildren: './pages/csr/csr.module#CsrModule'},
            {path: 'tickets', pathMatch: 'full', loadChildren: './pages/tickets/tickets.module#TicketsModule'},
            {path: 'tickets/:type/:id', pathMatch: 'full', loadChildren: './pages/tickets/tickets.module#TicketsModule'},
            {path: 'calendar', loadChildren: './pages/calendar/calendar.module#CalendarModule'},
            {path: 'view-html/:hash/:allow_links/:agent_id/:account_number', loadChildren: './pages/view-html/view-html.module#ViewHtmlModule'},
            {path: 'show-funnel/:visa_hash/:req_type/:agent_id/:applicant_uuid', pathMatch: 'full', loadChildren: './components/show-funnel/show-funnel.module#ShowFunnelModule'},
            {path: 'access-denied/:reason', pathMatch: 'full', loadChildren: './components/general/access-denied/access-denied.module#AccessDeniedModule'},
            {path: 'details/partials/:id', loadChildren: './pages/partial-payment/partial-payment-details.module#PartialPaymentDetailsModule'},
            {path: 'details/:type/:id', loadChildren: './pages/details/details.module#DetailsModule'},
            {path: 'details/:type/:id/:wizard_type/wizard', loadChildren: './pages/details/details.module#DetailsModule'},
            {path: 'details/full/:type/:id', loadChildren: './pages/full-details/full-details.module#FullDetailModule'},
            {path: 'payment/:type/:id', loadChildren: './pages/payment/payment.module#PaymentModule'},
            {path: 'partial-payment', loadChildren: './pages/partial/partial.module#PartialModule'},
            {path: 'rewards', loadChildren: './pages/points/points.module#PointsModule'},
            {path: 'wizard', loadChildren: './pages/wizard/wizard.module#WizardPageModule'},
            {path: 'users', loadChildren: './pages/users/users.module#UsersModule'},
            {path: 'user', loadChildren: './pages/users/users.module#UsersModule'},
            {path: 'empowerment-process', loadChildren: './pages/empowerment-process/empowerment-process.module#EmpowermentProcessModule'},
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
        ]
    },
    {
        path: '', component: DefaultComponent, children: [
            {path: 'login', pathMatch: 'full', component: LoginComponent},
        ]
    },
    {
        path: '', component: CallLayoutComponent, canActivate: [AuthGuardService], children: [
            { path: 'lead/:id/call/request', pathMatch: 'full', loadChildren: './components/call-request/call-request.module#CallRequestModule' }
        ]
    },
    {path: '**', loadChildren: './components/general/error-page/error-page.module#ErrorPageModule'},
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
