import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule, MatInputModule, MatTabsModule} from '@angular/material';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
    declarations: [
        LoginComponent,
        ChangePasswordComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatButtonModule,
        MatInputModule
    ],
    exports: [
        LoginComponent
    ],
    entryComponents: [
      ChangePasswordComponent
    ]
})
export class LoginModule {
}
