import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cookies } from 'src/app/helpers/Cookies';
import { Update } from 'src/app/helpers/Update';
import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})

export class CommissionsApiService extends ApiService {
  protected baseURL: string = environment.commissions;

  public getRequest(data) {
    let headers = {};
    const token = Cookies.get('token');
    if (token.length > 0) {
      headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Timezone': ((new Date()).getTimezoneOffset() * 60).toString(),
        'Update': Update.get(),
        'secret-key': '039e3cea804ae6c10a53ab3a4b7ae4c9'
      });
    }
    return {
      params: data ? data : {},
      headers: headers,
    };
  }
}
