import {ChartActionTypes, ChartsActionUnion} from '../actions/charts.action';

const initialState = {
    answer: null,
    attempts: null,
    effective: null,
    swiped: null,
    monthly_goal: null
};

export function chartsReducer(state: any = initialState, action: ChartsActionUnion) {
    switch (action.type) {
        case ChartActionTypes.SET_CHARTS: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }

}