import {Component, NgZone, OnInit} from '@angular/core';
import storage from 'store';
import * as PaymentNotificationActions from '../../../../actions/payment_notification.action';
import {select, Store} from '@ngrx/store';
import SearchResponse from '../../../../response/search.response';
import {PaymentService} from '../../../../services/payment/payment.service';
import {Router} from '@angular/router';
import {finalize, map} from 'rxjs/operators';
import {CentrifugeService} from '../../../../services/centrifuge/centrifuge.service';
import {Unsubscribe} from '../../../../helpers/unsubscribe';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _some from 'lodash/some';
import _values from 'lodash/values';
import _isEmpty from 'lodash/isEmpty';
import {OpportunitiesService} from '../../../../services/opportunities/opportunities.service';

@Component({
    selector: 'app-payment-content',
    templateUrl: './payment-content.component.html',
    styleUrls: ['./payment-content.component.scss']
})
export class PaymentContentComponent extends Unsubscribe implements OnInit {

    public autocomplete: any = null;

    public paymentNotifications: SearchResponse = new SearchResponse({
      page_size: 20,
    });

    public owner = 2;

    public unread: number = 0;

    public currentUser: any;

    public loader = false;

    public moreLoader = false;

    public isManager = false;

    constructor(
        private store: Store<any>,
        private paymentService: PaymentService,
        private opportunitiesService: OpportunitiesService,
        private centrifugeService: CentrifugeService,
        private router: Router,
        private ngZone: NgZone,
    ) {
      super();

      this.isManager = this.notificationRelatedTo();

      if (this.isManager) {
        this.owner = 1;
      }

      this.unread = storage.get('unread_notifications') || 0;
      if (this.unread) {
        setTimeout(() => {
          this.unread = 0;
          storage.remove('unread_notifications');
        }, 3000);
      }

      this.currentUser = storage.get('user');

      this.store.pipe(select('paymentNotification')).subscribe(value => {
            if (value.bubbles.length > 0) {
                this.getPaymentNotification();
            }
        });
    }

    ngOnInit() {
        this.autocomplete = storage.get('autocomplete');
        this.store.dispatch(new PaymentNotificationActions.SetNotViewedNotifications(0));
        this.loader = true;

        this.getPaymentNotification();
        this.subscribeSuccessPayment();
    }

    private notificationRelatedTo(): boolean {
      const settings = _get(storage.get('settings'), ['personal', 'show_payment_notification_related_to', 'value', 'value'], null);

      return _some(_values(settings), (value) => !_isEmpty(value));
    }

    private subscribeSuccessPayment(): void {
      const channelName = `success_payment_${this.currentUser.crm_id}`;

      this.centrifugeService.listen(channelName).pipe(
        map(res => _get(res, 'data', {})),
      ).subscribe((data) => {
        this.ngZone.run(() => {
          const event = _find(data, { notification: 'sale' });

          if (event && event.data) {
            this.unread += 1;

            setTimeout(() => {
              this.unread = 0;
            }, 3000);

            if (this.owner !== 1 && event.type === 'manager') {
              this.setFilter({
                value: 1
              }, false);
            } else {
              this.paymentNotifications.data.unshift(event.data);
            }
          }
        });
      });
    }

    public setFilter(event, clear_unread = true): void {
      this.loader = true;

      this.owner = event.value;
      this.paymentNotifications.page = 0;
      if (clear_unread) {
        this.unread = 0;
      }

      this.getPaymentNotification();
    }

    public setDate(date: number): number {
      if (date) {
        return date.toString().length === 10 ? date * 1000 : date;
      }

      return 0;
    }

    public getMethod(method: number): string {
        return _get(_find(this.autocomplete.transactions_methods, {value: method}), ['label'], 'No Method');
    }

    public getService(service: number): string {
        return _get(_find(this.autocomplete.transactions_services, {value: service}), ['label'], 'No Service');
    }

    public getAction(action: number): string {
        return _get(_find(this.autocomplete.transactions_actions, {value: action}), ['label'], 'No Action');
    }

    public getProduct(product: string): string {
        switch (product) {
            case 'IELTS Ashton - Retention - General Course':
                return 'IELTS Conley University - Retention - General Course';
            case 'IELTS Ashton - Retention - Academic Course':
                return 'IELTS Conley University - Retention - Academic Course';
            default:
                return product;
        }
    }

    public getPaymentNotification(callback?: any) {
        this.moreLoader = true;
        this.paymentNotifications.addFilter('user_crm_id', this.currentUser.crm_id);
        this.paymentService.getNotifications(
          {
            ...this.paymentNotifications.getReqData(),
            owner: this.owner
          }
        ).pipe(
          finalize(() => this.moreLoader = false)
        ).subscribe(res => {
            if (callback) {
                callback(res);
            } else {
                this.paymentNotifications = Object.assign(this.paymentNotifications, res);
                this.loader = false;
            }
        });
    }

    public toOpportunity(notice) {
      if (notice.opportunity_id) {
        this.router.navigate(['/details/opportunities/' + notice.opportunity_id]);
      } else {
        this.opportunitiesService.getDetails({
          lead_number: notice.lead_number,
          product: notice.product,
        }).toPromise().then((res) => {
          this.router.navigate(['/details/opportunities/' + res.id]);
        });
      }
    }

    public loadNext() {
        this.paymentNotifications.page++;
        this.getPaymentNotification((res) => {
            const data = Object.assign([], this.paymentNotifications.data);
            this.paymentNotifications = Object.assign(this.paymentNotifications, res);
            this.paymentNotifications.data = data.concat(res.data);
        });
    }
}
