import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { SalaryProjectionService } from 'src/app/services/salary-projection/salary-projection.service';
import { SalaryProjectionBarModalComponent } from './salary-projection-bar-modal/salary-projection-bar-modal.component';

@Component({
  selector: 'app-salary-projection-bar',
  templateUrl: './salary-projection-bar.component.html',
  styleUrls: ['./salary-projection-bar.component.scss',]
})
export class SalaryProjectionBarComponent implements OnInit {

  public permissions: any;

  public data = {
    current_salary: 0,
    salary_projection: 0,

    achievedForPastDays: 0,
    current_month_left_working_days: 0,
    worked_days: 0,

    progress: 0,

    zar_currency: 1,

    details: null,
  }

  constructor(
    private salaryProjectionService: SalaryProjectionService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.getSalaryProjection();
  }

  public getSalaryProjection() {
    this.salaryProjectionService.getSalaryProjection().toPromise().then((res) => {
      if (res && res.data) {
        const data = res.data
        if (!data.working_days) { return; }
        const working_days = data.working_days;

        const worked_days = working_days.worked_days || 0;
        const current_month_left_working_days = working_days.current_month_left_working_days || 0;

        this.data.current_salary = (data.current_salary || 0).toFixed(2);
        this.data.salary_projection = (data.salary_projection || 0).toFixed(2);
        this.data.worked_days = worked_days;
        this.data.current_month_left_working_days = current_month_left_working_days;
        this.data.zar_currency = data.zar_currency || 10.6;

        const progress = worked_days * 100 / ((worked_days + current_month_left_working_days) || 1);
        this.data.progress = progress > 100 ? 100 : progress < 0 ? 0 : progress;

        this.data.details = data.details;
      }
    }, (err) => {
      this.snack.open(err.error, 'OK!', {
        duration: 3000
      });
    });
  }

  public openSalaryProjectionPopup() {
    this.dialog.open(SalaryProjectionBarModalComponent, {
      data: this.data
    });
  }

}
