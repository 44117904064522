import {TimerActionTypes, TimerActionUnion} from '../actions/timer.action';

const initialState = 15;

export function timerReducer(state = initialState, action: TimerActionUnion) {
    switch (action.type) {
        case TimerActionTypes.RESET: {
            return action.payload;
        }
        case TimerActionTypes.DECREMENT: {
            return state - action.payload;
        }
        case TimerActionTypes.SET: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
