import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

import { Progress15Module } from '../progtess-15/progtess-15.module';

import { HeaderUserComponent } from './header-user.component';

@NgModule({
  declarations: [
    HeaderUserComponent
  ],
  imports: [
    CommonModule,
    // RouterModule,

    MatIconModule,
    MatTooltipModule,
    MatDialogModule,

    
    Progress15Module,
  ],
  exports: [
    HeaderUserComponent
  ]
})
export class HeaderUserModule {
}
