import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {ApiService} from '../api/api.service';
import SearchResponse from '../../response/search.response';

@Injectable({
    providedIn: 'root'
})
export class TicketsService {

    constructor(private api: ApiService) {
    }

    public getList(params): Observable<any> {
        if (params instanceof SearchResponse) {
            params = params.getReqData();
        }
        return this.api.get('/tickets', {
            params: params
        });
    }

    public getListCount(params): Observable<any> {
      if (params instanceof SearchResponse) {
          params = params.getReqData();
      }
      return this.api.get('/tickets/count', {
          params: params
      });
  }

    public deleteTicket(ids): Observable<any> {
        return this.api.post('/tickets/delete', {
            body: {
                ticket_ids: ids
            }
        });
    }

    public approveTicket(id): Observable<any> {
        return this.api.post('/tickets' + '/' + id + '/' + 'approve', {});
    }

    public createTicket(body): Observable<any> {
        return this.api.post('/tickets', {
            body
        });
    }

    public readTicket(ticket_id): Observable<any> {
        return this.api.post('/tickets/' + ticket_id + '/read', {});
    }

    public changeTicketStatus(id: number, body): Observable<any> {
        return this.api.put('/tickets/' + String(id) + '/change-status', {
            body
        });
    }

    public getCountTicketsBouble(): Observable<any> {
        return this.api.get('/tickets/bouble/count', {});
    }

    public getRequirementTickets(id: number, params?: any): Observable<any> {
      return this.api.get(`/tickets/requirement/${id}`, { params });
    }
}
