import {Action} from '@ngrx/store';

export enum TimerActionTypes {
    DECREMENT = '[Timer] Decrement',
    RESET  = '[Timer] Reset',
    SET = '[Timer] Set'
}

export class Decrement implements Action {
    readonly type = TimerActionTypes.DECREMENT;
    constructor(public payload = 1) {}
}

export class Reset implements Action {
    readonly type = TimerActionTypes.RESET;
    constructor(public payload = 60) {}
}

export class Set implements Action {
    readonly type = TimerActionTypes.SET;
    constructor(public payload = 0) {}
}


export type TimerActionUnion = Decrement | Reset | Set;
