import {Component, NgZone, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as ChatActions from '../../../../actions/chat.actions';

@Component({
  selector: 'app-bubble-message',
  templateUrl: './bubble-message.component.html',
  styleUrls: ['./bubble-message.component.less']
})
export class BubbleMessageComponent implements OnInit {

  public messages = [];

  constructor (
      private ngZone: NgZone,
      private store: Store<any>
  ) {
    this.store.pipe(select('chat')).subscribe(value => {
      if (value.bubbles.length > 3) {
        this.store.dispatch(new ChatActions.RemoveBubbleMessage(value.bubbles[0].message.id));
      }

      this.ngZone.run(() => {
        this.messages = value.bubbles;
      })
    });
  }

  ngOnInit() {}
}
