import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";
import {Observable} from "rxjs/Rx";

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private api: ApiService) { }

  public getNewPermissions(): Observable<any> {
    return this.api.get('/user_info/common_settings', {});
  }

  public getPermissions(): Observable<any> {
    return this.api.get('/user_info/permissions', {});
  }

  public getSettings(): Observable<any> {
    return this.api.get('/user_info/settings', {});
  }

  public getAutocompletes(): Observable<any> {
    return this.api.get('/user_info/autocomplete', {});
  }

  public getUsers(): Observable<any> {
    return this.api.get('/user_info/users', {});
  }
}
