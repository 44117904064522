import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})

export class InfoV2ApiService extends ApiService {
  protected baseURL: string = environment.entityInfoUrl;
}
