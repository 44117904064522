import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssignedToComponent} from './assigned-to.component';
import {MatBottomSheetModule, MatButtonModule, MatIconModule} from '@angular/material';
import {AgentsSheetModule} from '../agents-sheet/agents-sheet.module';

@NgModule({
    declarations: [AssignedToComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatBottomSheetModule,
        AgentsSheetModule
    ],
    exports: [
        AssignedToComponent
    ],
})
export class AssignedToModule {
}
