import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Cookies} from '../../helpers/Cookies';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Update} from '../../helpers/Update';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    protected baseURL: string = environment.apiURL;

    constructor(private http: HttpClient,
                private router: Router) {
    }

    protected getRequest(data, customHeaders?) {

        let headers = {};

        const token = Cookies.get('token');
        const lang = localStorage.getItem('lang') || 'en';

        if (token.length > 0) {
            let httpHeaders = {
                'Authorization': 'Bearer ' + token,
                'Timezone': ((new Date()).getTimezoneOffset() * 60).toString(),
                'Update': Update.get(),
                'Accept-Language': lang,
            };
            if (customHeaders) {
                httpHeaders = Object.assign(httpHeaders, customHeaders);
            }
            headers = new HttpHeaders(httpHeaders);
        }

        return {
            params: data || {},
            headers,
        };
    }

    public get(url, data) {
        return new Observable((observer) => {

            const sub = this.http.get(
                this.baseURL + url,
                this.getRequest(data.params, data.headers)
            ).subscribe((r) => {

                observer.next(r);
                observer.complete();

            }, (e) => {

                switch (e.status) {
                    case 401:
                        Cookies.set('token', '', -1);
                        location.href = '/login';
                        observer.error(e);
                        observer.complete();
                        break;
                    case 403:
                        observer.error(e);

                        this.router.navigate(['access-denied/' + e.error.error]).then((res) => {
                            if (!res) {
                                location.href = 'access-denied/' + e.error.error;
                            }
                            observer.complete();
                        });
                        break;
                    default:
                        observer.error(e);
                        observer.complete();
                        break;
                }
            });

            return {
                unsubscribe() {
                    sub.unsubscribe();
                }
            };
        });
    }

    public post(url, data) {
        return new Observable((observer) => {

            const sub = this.http.post(
                this.baseURL + url,
                data.body ? data.body : {},
                this.getRequest(data.params, data.headers)
            ).subscribe((r) => {

                observer.next(r);
                observer.complete();

            }, (e) => {

                switch (e.status) {
                    case 401:
                        Cookies.set('token', '', -1);
                        location.href = '/login';
                        observer.error(e);
                        observer.complete();
                        break;
                    case 403:
                        this.router.navigate(['access-denied/' + e.error.error]).then((res) => {
                            if (!res) {
                                location.href = 'access-denied/' + e.error.error;
                            }
                            observer.complete();
                        });
                        break;
                    default:
                        observer.error(e);
                        observer.complete();
                        break;
                }
            });

            return {
                unsubscribe() {
                    sub.unsubscribe();
                }
            };
        });
    }

    public put(url, data) {
        return new Observable((observer) => {

            const sub = this.http.put(
                this.baseURL + url,
                data.body ? data.body : {},

                this.getRequest(data.params, data.headers)
            ).subscribe((r) => {

                observer.next(r);
                observer.complete();

            }, (e) => {

                switch (e.status) {
                    case 401:
                        Cookies.set('token', '', -1);
                        location.href = '/login';
                        observer.error(e);
                        observer.complete();
                        break;
                    case 403:
                        this.router.navigate(['access-denied/' + e.error.error]).then((res) => {
                            if (!res) {
                                location.href = 'access-denied/' + e.error.error;
                            }
                            observer.complete();
                        });
                        break;
                    default:
                        observer.error(e);
                        observer.complete();
                        break;
                }

            });
            return {
                unsubscribe() {
                    sub.unsubscribe();
                }
            };
        });
    }

    public delete(url, data) {
        return new Observable((observer) => {

            const sub = this.http.delete(
                this.baseURL + url,
                this.getRequest(data.params, data.headers)
            ).subscribe((r) => {
                observer.next(r);
                observer.complete();
            }, (e) => {

                switch (e.status) {
                    case 401:
                        Cookies.set('token', '', -1);
                        location.href = '/login';
                        observer.error(e);
                        observer.complete();
                        break;
                    case 403:
                        this.router.navigate(['access-denied/' + e.error.error]).then((res) => {
                            if (!res) {
                                location.href = 'access-denied/' + e.error.error;
                            }
                            observer.complete();
                        });
                        break;
                    default:
                        observer.error(e);
                        observer.complete();
                        break;
                }
            });

            return {
                unsubscribe() {
                    sub.unsubscribe();
                }
            };
        });
    }
}
