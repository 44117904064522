import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { VERSION } from 'src/environments/version';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss',]
})
export class FooterComponent implements OnInit {

  public version = VERSION.tag;
  
  constructor() { }

  ngOnInit(): void {}

}

