import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {OwlDateTimeComponent} from 'ng-pick-datetime';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges {

    @Input() public info: any;

    @Output() public filterChange: EventEmitter<any> = new EventEmitter();

    @Input() public model: any;

    @Output() public modelChange: EventEmitter<any> = new EventEmitter();

    @ViewChild('picker') public picker: OwlDateTimeComponent<any>;

    @ViewChild('pickerInput') public input: ElementRef;

    public inputModel: any;

    public errors: any = [];

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.model && !change.model.firstChange) {
            if (!change.model.currentValue) {
                this.input.nativeElement.value = '';
            }
        }

        if (change.model) {
            if (change.model.currentValue) {
                const time = JSON.parse(this.model);
                this.inputModel = [new Date(time.from * 1000), new Date(time.to * 1000)];

            }
        }
    }

    public setFilter() {

        this.errors = [];

        if (!this.picker.selecteds[0] && !this.picker.selecteds[1]) {
            // this.errors.push('Please select Date');
            return;
        }

        if (this.picker.selecteds[0] === null) {
            // this.errors.push('Please select Start Date');
            return;
        }

        if (this.picker.selecteds[1] === null) {
            // this.errors.push('Please select End Date');
            return;
        }

        this.model = {
            'from': this.picker.selecteds[0].getTime() / 1000,
            'to': Math.floor(this.picker.selecteds[1].getTime() / 1000) + 86399
        };

        this.modelChange.emit(JSON.stringify(this.model));

        this.filterChange.emit(this.model);
    }

    public clear() {

        if (!this.model) {
            return;
        }

        if (Object.keys(this.model).length <= 0) {
            return;
        }

        this.picker.selecteds[0] = null;

        this.picker.selecteds[1] = null;

        this.modelChange.emit('');

        this.filterChange.emit('');

    }

}
