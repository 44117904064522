import { NgModule } from '@angular/core';
import { CustomTranslatePipe } from './customTranslate.pipe';

@NgModule({
  declarations: [CustomTranslatePipe],
  exports: [
    CustomTranslatePipe
  ]
})
export class CustomTranslatePipeModule { }
