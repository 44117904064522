import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-level-modal',
    templateUrl: './level-modal.component.html',
    styleUrls: ['./level-modal.component.scss']
})
export class LevelModalComponent implements OnInit, OnDestroy {

    // public text: any = {
    //     1: 'Keep your hair on Rugrat, you have just reached the beginner level! Prove there is bite behind your bark and soon you’ll roll with the big dogs.',
    //     2: 'Nice one Chester! Take a moment to savour the sweet taste of success. Stuffing your face seems like a good deal for now, but we know you are hungry for more.',
    //     3: 'Your sales chops prove you’re not just a badass in a nice suit. Body and brains, what more could you want? Money of course. Keep it up and you’ll be making big bucks Brad.',
    //     4: 'Well well well looks like the emperor of F***sville is bringing in some big sales. Don’t get lost in your success, keep focused and soon you’ll move from sidekick to alpha wolf.',
    //     5: 'You have nearly reached the top of the food chain! Whether you like it or not, you are inspiring others. Keep drumming up sales with your war chant Mark.',
    //     6: 'Hey Alpha, you’ve reached the top! How’s the view? As the new Wolf of Wall Street, you know there is always more to be done. Time to lead your pack to riches.'
    // };

    public levels: any = [];

    public currentLevel: any = {};
    public currentLevelIndex: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public self: MatDialogRef<LevelModalComponent>
    ) {
        this.self.addPanelClass('level-info');
        
        if (this.data.levelsData) {
            const levelsData = this.data.levelsData;
            this.currentLevel = levelsData.current_level;
            this.levels = levelsData.levels.map((el) => {
                return {
                    name: el.name,
                    avatar: el.avatar,
                    description: el.description,
                    level_number: el.level_number,
                    percent: el.points,
                    points: el.points, 
                    achieved_points: el.achieved_points,
                    id: el.id,
                }
            });
            this.currentLevelIndex = this.levels.findIndex(el => el.id === this.currentLevel.id)
        }
    }

    ngOnInit() {
    }

    ngOnDestroy() {

    }

}
