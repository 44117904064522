import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NbTabComponent, NbTabsetComponent} from '@nebular/theme/components/tabset/tabset.component';
import {NbSidebarService} from '@nebular/theme';

@Component({
    selector: 'app-right-sidebar',
    templateUrl: './right-sidebar.component.html',
    styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit, OnDestroy {

    @ViewChild('tabset') public tabSet: NbTabsetComponent;

    @ViewChild('tab') public tab: NbTabComponent;

    public listener: any;

    public isNotification = false;

    public icons = {
        on: {
            text: 'Open Chat',
            icon: 'chat'
        },
        off: {
            text: 'Close Chat',
            icon: 'chat_bubble_outline'
        }
    };

    public switchIcon = 'on';

    constructor(private sidebarService: NbSidebarService) {
    }


    ngOnInit() {
        document.addEventListener('selectAgent', this.listener = () => {
            this.switchIcon = 'off';
        });
    }

    ngOnDestroy() {
        if (this.listener) {
            document.removeEventListener('selectAgent', this.listener);
        }
    }

    public close() {

        this.switchIcon = this.switchIcon === 'on' ? 'off' : 'on';

        this.sidebarService.toggle(true, 'settings-sidebar');

        this.tabSet.selectTab(this.tab);

        const list = document.getElementsByClassName('agents-wrap')[0].querySelectorAll('.agent');

        for (let i = 0; i < list.length; i++) {
            document.getElementsByClassName('agent')[i].classList.remove('active');
        }

        return false;
    }

    public changeTab(event) {
        if (event && event.tabTitle === 'Notification') {
          this.isNotification = true;
        }
    }
}
