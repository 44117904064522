import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {ApiService} from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SalaryProjectionService {

  constructor(private api: ApiService) {
  }

  public getSalaryProjection(params?): Observable<any> {
    return this.api.get(`/projection`, { params });
  }
}
