import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from '../../services/users/user.service';
import {MatDialog} from '@angular/material';
import {QuickPointsModalComponent} from '../general/quick-points-modal/quick-points-modal.component';
import {LevelModalComponent} from '../general/level-modal/level-modal.component';

@Component({
    selector: 'app-current-user',
    templateUrl: './current-user.component.html',
    styleUrls: ['./current-user.component.scss']
})
export class CurrentUserComponent implements OnInit, OnChanges {

    @Input() public user: any;

    public minus: any = {
        level: {
            point: 0,
            show: false
        }
    };



    constructor(
        private matDialog: MatDialog,
        private userService: UserService,
    ) {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {

        if (!changes.user.firstChange && changes.user.currentValue && changes.user.previousValue && (changes.user.currentValue.points !== changes.user.previousValue.points)) {

            this.minus.level.point = changes.user.currentValue.points - changes.user.previousValue.points;
            this.minus.level.show = true;
            setTimeout(() => {
                this.minus.level.show = false;
            }, 2000);
        }
    }


    public getPercent() {
        return Math.round((this.user.level_points / this.user.level.points) * 100);
    }

    public pointsModal() {
        this.matDialog.open(QuickPointsModalComponent, {
            width: '800px',
            panelClass: 'points-info',
            hasBackdrop: true,
            data: {
                user: ''
            }
        });
    }

    public congratsModal(){
        this.matDialog.open(LevelModalComponent, {
            hasBackdrop: true,
            data: {
                user: this.user,
                text: 'YOUR LEVEL'
            }
        });
    }

    public getUserAvatar(crm_id: number) {
        return this.userService.getAvatar(crm_id);
    }

}
