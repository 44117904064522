import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuickPointsModalComponent} from './quick-points-modal.component';
import {MatDialogModule, MatIconModule, MatProgressBarModule, MatTooltipModule} from '@angular/material';
import {SmallLoaderModule} from '../small-loader/small-loader.module';

@NgModule({
    declarations: [
        QuickPointsModalComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatProgressBarModule,
        MatTooltipModule,
        SmallLoaderModule,
        MatIconModule,
    ],
    exports: [
        QuickPointsModalComponent
    ]
})
export class QuickPointsModalModule {
}
