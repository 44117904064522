import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private api: ApiService) {
    }

    public getNotification(params, type): Observable<any> {
        return this.api.get('/notifications/' + type, {
            params: params
        });
    }

    public addNotification(data): Observable<any> {
        return this.api.post('/notifications', {
            body: data
        });
    }

    public editNotification(data, id): Observable<any> {
        return this.api.put('/notifications/' + id, {
          body: data
        });
    }

    public deleteNotification(id): Observable<any> {
        return this.api.delete('/notifications/' + id,{});
    }

    public setScheduleNutification(data): Observable<any> {
        return this.api.post('/notifications/send-schedule',{
          body: data
        });
    }

    public searchCity(data): Observable<any> {

        return this.api.post('/notifications/search', {
            body: data
        });
    }

    public checkCall(type, id): Observable<any> {
      return this.api.get('/notifications/check-call/' + type + '/' + id, {});
    }

    public getUserManagersByRole(role): Observable<any> {
      return this.api.get('/users/user-managers/' + role, {});
    }

    public getTimezone(body): Observable<any> {
      return this.api.post('/general/get-timezone', { body });
    }
}
