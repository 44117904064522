import {Injectable} from '@angular/core';
import io from 'socket.io-client';
import {Socket} from 'socket.io-client';
import {environment} from '../../../environments/environment';
import {Cookies} from '../../helpers/Cookies';
import {Observable} from 'rxjs';
import {Update} from '../../helpers/Update';

@Injectable({
    providedIn: 'root'
})

export class LiveService {

    constructor() {}

    private socket(namespace: string): Socket {
        return io(environment.liveUrl + '/' + namespace + '?user=' +
            Cookies.get('token') + '&update=' + Update.get());
    }

    public subscribe(namespace: string): Observable<any> {
        return new Observable();

        // return new Observable((observer) => {
        //
        //     const socket = this.socket(namespace);
        //
        //     socket.on('change', (event) => {
        //         observer.next(event);
        //     });
        //
        //     socket.on('connect_error', (event) => {
        //         // console.log('connect_error', namespace)
        //         socket.close();
        //         observer.complete();
        //     });
        //
        //     return {
        //         unsubscribe() {
        //             observer.complete();
        //             socket.disconnect();
        //         }
        //     };
        // });
    }
}
