import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-pie-loader',
    templateUrl: './pie-loader.component.html',
    styleUrls: ['./pie-loader.component.scss']
})
export class PieLoaderComponent implements OnInit {

    @Input() public show: any;

    constructor() {
    }

    ngOnInit() {
    }

}
