import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatTableModule } from '@angular/material/table';

import { SalaryProjectionBarModalComponent } from './salary-projection-bar-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SalaryProjectionBarModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatTableModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [
    SalaryProjectionBarModalComponent
  ],
  entryComponents: [
    SalaryProjectionBarModalComponent
  ]
})
export class SalaryProjectionBarModalModule {
}
