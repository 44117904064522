import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BubbleMessageComponent} from './bubble-message.component';
import { BubbleModule} from './bubble/bubble.module';

@NgModule({
    declarations: [
        BubbleMessageComponent
    ],
    imports: [
        CommonModule,
        BubbleModule
    ],
    exports: [
        BubbleMessageComponent
    ]
})
export class BubbleMessageModule {}
