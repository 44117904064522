import {UserActionTypes, UserActionUnion} from '../actions/user.action';

const initialState = {
    avatar: null,
    crm_id: null,
    email: null,
    full_name: null,
    id: null,
    level_points: null,
    points: null,
    role: null,
    token: null,
    username: null,
    created_at: null,
    updated_at: null,
    level: {}
};

export function userReducer(state: any = initialState, action: UserActionUnion) {
    switch (action.type) {
        case UserActionTypes.SET_USER: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
}
