import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import SearchResponse from 'src/app/response/search.response';
import { ApplicationsService } from 'src/app/services/applications/applications.service';
import { EventBusService } from 'src/app/services/event-bus/event-bus.service';
import { LeadsService } from 'src/app/services/leads/leads.service';
import localStore from 'store';
import _get from 'lodash/get';
import {ConfirmModalComponent} from '../../../../general/confirm-modal/confirm-modal.component';
import {MatDialog} from '@angular/material';
import {ErrorModalComponent} from '../../../../general/error-modal/error-modal.component';
import {CreateLeadModalComponent} from '../../../../general/create-lead-modal/create-lead-modal.component';
import storage from 'store';

@Component({
  selector: 'app-call-bar',
  templateUrl: './call-bar.component.html',
  styleUrls: ['./call-bar.component.scss']
})
export class CallBarComponent implements OnInit, OnDestroy {

  @HostListener('document:visibilitychange', ['$event'])
  onFocus(event: any): void {
    if (!event.target.hidden) {
      const call_data = localStore.get('call_data');

      if (call_data) {
        this.leadNumber = call_data.lead_number;

        this.timerData = call_data;
      }
    }
  }

  public subscribes: any = {};

  public startPointCounter = 100000000;
  public interval = 1000;
  public dueDate = 0;
  public leads: SearchResponse = new SearchResponse();
  public applications: SearchResponse = new SearchResponse();
  public showTimer = false;
  public showTimerDelay = false;
  public leadNumber: string;
  public callId: number;
  public value = 60;
  public seconds = '--';
  public minutes = '--';
  public hours = '--';
  public timerData: any;

  public subscriber: any;

  constructor(
    private eventBus: EventBusService,
    private router: Router,
    private leadsService: LeadsService,
    private applicationsService: ApplicationsService,
    private matDialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.initCalls();
  }

  ngOnDestroy(): void {
    for (const key in this.subscribes) {
      if (this.subscribes[key] && this.subscribes[key].unsubscribe) {
        this.subscribes[key].unsubscribe();
      }
    }
  }

  public initCalls() {
    const agentOnCall = localStore.get('agent_on_call');
    this.dueDate = 0;
    if (Boolean(agentOnCall)) {
      const callStart = localStore.get('call_answer');
      const now = new Date().getTime();
      if (callStart && (now - callStart) / 3600000 <= 1) {
        this.showTimer = true;
        this.showTimerDelay = true;
        this.timerData = localStore.get('call_data');
        this.leadNumber = this.timerData.lead_number;
        this.dueDate = now - callStart;
      } else {
        storage.remove('agent_on_call');
        storage.remove('call_data');
        storage.remove('call_answer');
      }
    }

    this.subscribes.call_data = this.eventBus.on(
      'call-data'
    ).subscribe((res: any) => {
      if (res) {
        this.leadNumber = res.lead_number;

        this.timerData.lead_crm_id = res.crm_id;
      }
    });

    this.subscribes.call_timer = this.eventBus.on(
      'call-timer'
    ).subscribe((res: any) => {
      this.dueDate = res.dueDate || 0;
      this.showTimer = res.status;

      if (!res.status) {
        this.showTimerDelay = false;
      }

      if (this.showTimer) {
        this.showTimerDelay = true;
        this.leadNumber = res.body.lead_number;
        this.callId = res.body.call_id;
        this.timerData = res.body;
      }
    });
  }

  public openEntity() {
    if (this.timerData && this.timerData.lead_crm_id) {
      if (this.timerData.entity_type === 'leads') {
        this.getLead();
      } else if (this.timerData.entity_type === 'applications') {
        this.getApplication();
      }
    }
  }

  public endCAll() {
    const dialog = this.matDialog.open(ConfirmModalComponent, {
      width: '500px',
      hasBackdrop: true,
      data: {
        title: 'End Call',
        message: 'Are you sure want to end this call?',
      }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result && this.callId) {
        this.subscriber = this.leadsService.terminateCall(this.callId).subscribe(() => {

        }, (error) => {
          console.log(error);
        });
      }
    }, (error) => {
      if (error !== 'close') {
        this.matDialog.open(ErrorModalComponent, {
          width: '500px'
        });
      }
    });
  }

  public createLead(e): void {
    e.stopPropagation();

    this.matDialog.open(
      CreateLeadModalComponent,
      {
        width: '550px',
        panelClass: 'create-template',
        data: {
          type: 'inbound-call',
          formData: this.timerData
        }
    });
  }

  public getLead() {
    this.leads.addFilter('crm_id', this.timerData.lead_crm_id);

    this.leadsService.getAllLeads(
      Object.assign(this.leads.getReqData(), {})
    ).toPromise().then((res) => {
      if (res.data && res.data[0]) {
        const leadId = res.data[0].id;
        this.navigate(leadId);
      }
    });
  }

  public navigate(id) {
    this.router.navigate([`/details/${this.timerData.entity_type}/${id}`]);
  }

  public getApplication() {
    this.applications.addFilter('crm_id', this.timerData.lead_crm_id);

    this.applicationsService.getAllList(
      Object.assign(this.applications.getReqData())
    ).toPromise().then((res) => {
      if (res && res.data && res.data[0]) {
        const applicationId = res.data[0].id;
        this.navigate(applicationId);
      }
    });
  }

}
