import {Injectable} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventBusService {

    private _eventBus: Subject<any>;

    private separator = ':';

    constructor() {
        this._eventBus = new Subject<any>();
    }

    public keyMatch(key: string, wildcard: string) {

        const w = '*';
        const ww = '**';

        const partMatch = (wl, k) => {
            return (wl === w) || (wl === k);
        };

        const sep = this.separator;
        const kArr = key.split(sep);
        const wArr = wildcard.split(sep);

        const kLen = kArr.length;
        const wLen = wArr.length;
        const max = Math.max(kLen, wLen);

        for (let i = 0; i < max; i++) {
            const cK = kArr[i];
            const cW = wArr[i];

            if (cW === ww && (typeof cK !== 'undefined')) {
                return true;
            }

            if (!partMatch(cW, cK)) {
                return false;
            }
        }

        return true;
    }

    public emit(key: string, data?: any) {

        this._eventBus.next({key, data});
    }

    public on<T>(key: string): Observable<T> {

        return this._eventBus.asObservable().pipe(
            filter((event) => {
                return this.keyMatch(event.key, key);
            }),
            map(event => <T>event.data)
        );
    }
}
