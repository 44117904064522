import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstallmentNotificationService {

  public count: number = 0;

  private currentCountSource = new BehaviorSubject(null);
  currentCount = this.currentCountSource.asObservable();

  constructor() { }

  public setCount(count: number): void {
    this.count = count;

    this.currentCountSource.next(count);
  }

  public increase(): void {
    this.count++;

    this.currentCountSource.next(this.count);
  }

  public decrease(): void {
    if(this.count){
      this.count--;
    }

    this.currentCountSource.next(this.count);
  }
}
