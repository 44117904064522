import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { ApiWizard2 } from './api.wizards.service';
import {ApiWizardV2} from './api.wizardsV2.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Wizards2Service {

  constructor(
    private api: ApiWizard2,
    private apiV2: ApiWizardV2
  ) {}

  public getWizardsWidgetCount(data): Observable<any> {
    return this.apiV2.post('/v1/api/count', {
      body: JSON.stringify(data),
      headers: {
        Authorization: 'Bearer bi4kspH6T1h2qi__vq1l2wEvJDrUKOmOOM8FKZOP3RiYnhhWpwxncyj2ZZ2LN2ze',
        'Content-Type': 'application/json'
      }
    }).pipe(
      map((i: any) => {
        Object.keys(i.items).forEach(key => {
          if (i.items[key].csr_type) {
            i.items[key]['products'] = i.items[key].csr_type;
          }
        });

        return i;
      })
    );
  }

  public getWizardsLeads(data): Observable<any> {
    return this.apiV2.post('/v1/api', {
      body: JSON.stringify(data),
      headers: {
        Authorization: 'Bearer bi4kspH6T1h2qi__vq1l2wEvJDrUKOmOOM8FKZOP3RiYnhhWpwxncyj2ZZ2LN2ze',
        'Content-Type': 'application/json'
      }
    });
  }
}
