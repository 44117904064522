import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { NotificationsApiService } from '../notifications-api/notifications-api';

@Injectable({
    providedIn: 'root'
})
export class NotificationSystemService {

  constructor(private api: NotificationsApiService) {
  }

  public getNotifications(params, agentId): Observable<any> {
    return this.api.get('/events/' + agentId + '/list', {
      params
    });
  }

  public readNotification(body) {
    return this.api.post('/events/read', {
      body
    });
  }

  public deleteNotification(user_crm_id, updated_at): Observable<any> {
    return this.api.delete(`/events/delete-click`, {
      params: { user_id: user_crm_id, scores: updated_at }
    });
  }

  public clickNotiication(body) {
    return this.api.post('/events/click', {
      body
    });
  }

}
