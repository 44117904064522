export class Cookies {

    static set(cname, cvalue, exdays) {

        const d: Date = new Date();

        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

        const expires = 'expires=' + d['toGMTString']();

        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    static get(cname) {

        const name = cname + '=';

        const decodedCookie = decodeURIComponent(document.cookie);

        const ca = decodedCookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    }

    static delete(cname) {
      document.cookie = cname + '=; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    }
}
