import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root'
})

export class PublicApiService extends ApiService {
    protected baseURL: string = environment.publicApi;
}
