import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CentrifugeService } from 'src/app/services/centrifuge/centrifuge.service';
import { CommissionsService } from 'src/app/services/commissions/commissions.service';
import { EventBusService } from 'src/app/services/event-bus/event-bus.service';
import { User } from 'src/app/services/users/user';
import localStore from 'store';
import { CommissionAchievedPopupComponent } from '../commission-achieved-popup/commission-achieved-popup.component';
import { PointsAchievedPopupComponent } from '../points-achieved-popup/points-achieved-popup.component';
import { CommissionModalComponent } from './commission-modal/commission-modal.component';
import { MilestonesModalComponent } from './milestones-modal/milestones-modal.component';
import { MonthlyGoalVouchersModalComponent } from './monthly-goal-vouchers-modal/monthly-goal-vouchers-modal.component';
import _get from 'lodash/get';

@Component({
  selector: 'app-commissions-v3',
  templateUrl: './commissions-v3.component.html',
  styleUrls: ['./commissions-v3.component.scss',],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(400, style({ opacity: 1 }))
      ]),
    ])
  ]
})
export class CommissionsV3Component implements OnInit, OnDestroy {
  @ViewChild('container') container: ElementRef;

  @Input() set setData(val) {
    if (!(val && Object.keys(val).length)) { return; }
    this.data = val;
    // this.getCommission();
    this.setPerformanceCommission()
    this.getGroupDailyGoal();
    this.getPersonalDailyGoal();
    this.getPersonalDailyFor();
    this.getMonthlyBonusTarget()
    this.getGroupTarget();
    this.getIndividualTarget();
    this.getMilestones();

    this.loading = false;
    this.initPendingCommissionGlow();
  };

  public is_retention: boolean;

  public loading = true;

  public readonly PERFORMANCE_STATUS = {
    NO: 1,
    IN_PROGRESS: 2,
    TRIAL: 3
  };

  public readonly COMMISSION_TYPE = {
    PENDING:  1,
    MAIN: 2
  };
  public readonly COMMISSION_DEDUCTION_TYPE = {
    PERCENTAGE: 1,
    AMOUNT: 2
  };

  public currencyRatio = User.CURRENSY;
  public data: any;
  public user: any;

  public WIDTH = {
    IF_THIRD: 93,
    IF_SECOND: 128,
    IF_LAST: 170,
  }

  public subscribes: any = {};

  public commission: any = {
    show: false,
    total: 0,
    total_pending: 0,
  };

  public groupDailyGoal = {
    show: false,
    type: '',
    progress: 0,
    reward: 0,
    reached_by_group: 0,
    t_b: 0,
    t_1: null,
    t_2: null,
    t_3: null,
  }

  public personalDailyGoal = {
    show: false,
    progress: 0,
    personal_goal: 0,
    current_value: 0,

    goal: 0,

  }

  public personalDailyFor = {
    show: false,
    type: '',
    title: 'Personal Daily Commission',
    progress: 0,
    reward: 0,

    reached_by_user: 0,
    t_b: 0,
    t_1: null,
    t_2: null,
    t_3: null,
  }


  public monthlyBonusTarger = {
    show: false,
    type: '',
    title: 'commission.monthly_bonus_target',
    progress: 0,
    reward: 0,

    reached_by_user: 0,
    t_b: 0,
    t_1: null,
    t_2: null,
    t_3: null,
  }



  public groupTarget = {
    show: false,
    visible: false,
    progress: 0,
    // antiProgress: 0,
    collected: '',
    goal: '',
    type: '',
  }

  public individualTarget = {
    show: false,
    visible: false,
    progress: 0,
    antiProgress: 0,
    collected: '',
    goal: 0,
    type: '',

    prediction_goal: null
  }

  public milestones = {
    show: false,
    monthlyGoal: 0,
    commission: 0,
    currentMilestone: 0,
    progress: 0,
    achieved: 0,
    overdue: 0,
    left: 0
  }

  public performanceProcess = null;

  public current_commission_animation: boolean;
  public pending_commission_animation: boolean;

  public commissionTooltip = 'Dear Account Manager, In order to receive the full amount of your "Pending Commission", you would need to meet Your Personal Monthly Goal on a calendar level. Good Luck!';

  public am4Core: any = (window as any).am4core;
  
  public am4Charts: any = (window as any).am4charts;
  
  public am4ThemesAnimated: any = (window as any).am4themes_animated;

  constructor(
    private dialog: MatDialog,
    private centrifugeService: CentrifugeService,
    private commissionsService: CommissionsService,
    private eventBus: EventBusService,
    private ngZone: NgZone,
  ) {
    this.user = localStore.get('user');
    this.performanceProcess = this.user.performance_process_details;

    this.is_retention = this.user.role === User.RETENTION_AGENT || this.user.role === User.RETENTION_AGENTS_J;
  }

  ngOnInit(): void {
    // this.checkPointsBlock();
    this.initPointsCentrifuge();
    this.initCommissionCentrifuge();

    // document.addEventListener('mouseup', this.onMouseUp)

    this.subscribes.congratPopup = this.eventBus.on(
      'change-commission-in-header'
    ).subscribe((res: any) => {
      if (res.is_pending) {
        this.commission.total_pending += res.achieved_commission;
        this.pending_commission_animation = true;
        setTimeout(() => { this.pending_commission_animation = false; }, 850);
      } else {
        this.commission.total += res.achieved_commission;
        this.current_commission_animation = true;
        setTimeout(() => { this.current_commission_animation = false; }, 850);
      }
    })
  }

  public initPendingCommissionGlow() {
    const key = 'commission_pending_glow_last_time';
    let lastTime = localStore.get(key) || 0;
    const ms_1h30m = 5400000 - 200; // 1h30m in milliseconds minus 0.2second

    setInterval(() => {
      const el = document.getElementById('header-commission-pending');
      if (!el) { return; }
      const now = Date.now();
      if (now >= lastTime + ms_1h30m) {
        localStore.set(key, now);
        lastTime += now;
        el.classList.add('_glow');
        setTimeout(() => {
          el.classList.remove('_glow');
        }, 10000);
      }
    }, 10000);
  }

  ngOnDestroy() {
    document.removeEventListener('mouseup', this.onMouseUp);

    for (const key in this.subscribes) {
      if (this.subscribes[key].unsubscribe) {
        this.subscribes[key].unsubscribe();
      }
    }
  }



  private setPerformanceCommission(): void {
    if (this.data.short.total === null) { return; }

    const commissionDdetails = _get(this.performanceProcess, 'details.commission', null);

    const commission = {
      show: true,
      total: +this.data.short.total || 0,
      total_pending: +this.data.short.total_pending || 0
    };

    if (
      this.performanceProcess &&
      commissionDdetails &&
      this.performanceProcess.status === this.PERFORMANCE_STATUS.IN_PROGRESS &&
      commissionDdetails.connect_commissions
    ) {

      commission['performance'] = true;
      commission['type'] = commissionDdetails.type;
      commission['amount'] = commissionDdetails.amount;

      if (commissionDdetails.commission_type === this.COMMISSION_TYPE.MAIN) {
        if (commissionDdetails.type === this.COMMISSION_DEDUCTION_TYPE.PERCENTAGE) {
          commission['total_performance'] = this.percent(commission.total, commissionDdetails.amount);

          commission['total_performance_amount'] = this.percent(commission.total, commissionDdetails.amount, true);
        } else if (commissionDdetails.type === this.COMMISSION_DEDUCTION_TYPE.AMOUNT) {
          commission['total_performance'] = commission.total - commissionDdetails.amount;

          commission['total_performance_amount'] = commissionDdetails.amount;
        }
      }

      if (commissionDdetails.commission_type === this.COMMISSION_TYPE.PENDING) {
        if (commissionDdetails.type === this.COMMISSION_DEDUCTION_TYPE.PERCENTAGE) {
          commission['total_pending_performance'] = this.percent(commission.total_pending, commissionDdetails.amount);

          commission['total_pending_performance_amount'] = this.percent(commission.total_pending, commissionDdetails.amount, true);
        } else if (commissionDdetails.type === this.COMMISSION_DEDUCTION_TYPE.AMOUNT) {
          commission['total_pending_performance'] = commission.total_pending - commissionDdetails.amount;

          commission['total_pending_performance_amount'] = commissionDdetails.amount;
        }
      }
    }

    this.commission = commission;
  }

  private percent(originalPrice: number, percent: number, type = false): number {
    if (originalPrice === 0) return 0;

    const numberPercent = originalPrice / 100 * percent;

    if (type) {
      return Number(numberPercent);
    }

    return Number(originalPrice) - Number(numberPercent);
  }

  public initPointsCentrifuge() {
    if (this.centrifugeService.connected) {
      const userId = this.user.id;
      setTimeout(() => {
        this.subscribes.points_stream = this.centrifugeService.listen(
          `user_${userId}_points_stream`
        ).subscribe((res) => {
          this.openCongratulationsPopupPoints(res);
        })
      }, 500);
    } else {
      setTimeout(() => {
        this.initPointsCentrifuge();
      }, 1000);
    }
  }
  public initCommissionCentrifuge() {
    if (this.centrifugeService.connected) {
      const userCRMID = this.user.crm_id;
      setTimeout(() => {
        this.subscribes.commission_notification = this.centrifugeService.listen(
          `commission_notification_${userCRMID}`
        ).subscribe((res) => {
          if (res.data && res.data.init_commission) {
            this.callCommissionsApi();
          }
        })
      }, 500);
    } else {
      setTimeout(() => {
        this.initCommissionCentrifuge();
      }, 1000);
    }

  }

  public callCommissionsApi() {
    this.commissionsService.getCommissionsAgent({
      user_id: this.user.id,
      role_id: this.user.role,
      shift_ids: JSON.stringify(this.user.shift_ids)
    }).toPromise().then((res) => {
      const event = new CustomEvent('commissions', {detail: res});
      localStore.set('commissions', res);
      document.dispatchEvent(event);

      const short = res.short;
      if (short.total === null) {
        return;
      }

      if (short.total > this.commission.total) {
        const diff = short.total - this.commission.total;
        if (Math.round(diff * this.currencyRatio)) {
          this.openCongratulationsPopupCommission({
            achieved_commission: diff
          });
        } else {
          this.commission.total = short.total;
        }
      }

      if (short.total_pending > this.commission.total_pending) {
        const diff = short.total_pending - this.commission.total_pending;
        if (Math.round(diff * this.currencyRatio)) {
          this.openCongratulationsPopupCommission({
            achieved_commission: diff,
            is_pending: true
          });
        } else {
          this.commission.total_pending = short.total_pending;
        }
      }

      this.setTooltip(short);

    }, (err) => {
      console.warn(err);
    });
  }

  private setTooltip(short): void {
    if (short.red_line_value) {
      this.commissionTooltip = `What's this? - It indicates your Minimum Monthly Achievement Goal. Reach to $${short.red_line_value} (Nett) in order to release your Pending Commission.`;
    }
  }

  private congratulationsPopupCommissionOpened: boolean;

  public openCongratulationsPopupCommission(commission) {
    if (!commission) { return; }
    if (this.congratulationsPopupCommissionOpened || this.congratulationsPopupPointsOpened) {
      this.ngZone.run(() => {
        const achieved_commission = commission.achieved_commission || 0;
        const is_pending = commission.is_pending || false;
        this.eventBus.emit(
          'change-commission-in-header',
          { achieved_commission, is_pending }
        );
      });
    } else {
      this.congratulationsPopupCommissionOpened = true;
      setTimeout(() => {
        this.congratulationsPopupCommissionOpened = false;
      }, 4000);
      this.ngZone.run(() => {
        this.dialog.open(CommissionAchievedPopupComponent, {
          data: {
            commission,
          },
        });
      });
    }
  }

  public getCommission() {
    if (this.data.short.total === null) { return; }
    this.commission.show = true;
    const total = +this.data.short.total;
    const total_pending = +this.data.short.total_pending;
    this.commission.total = total || 0;
    this.commission.total_pending = total_pending || 0;

    this.setTooltip(this.data.short);
  }

  public onOpenCommisionsModal() {
    if (this.scroll.down || this.scroll.movingDelay) { return; }
    this.dialog.open(CommissionModalComponent, {
      data: {
        commission: this.commission.total,
        title: this.personalDailyFor.title,
        data: this.data,
        user: this.user,
        update: (res) => {
          this.data.short = res.short;
        }
      }
    })
  }

  public onOpenMilestonesModal() {
    this.dialog.open(MilestonesModalComponent, {
      data: {
        data: this.data
      }
    })
  }

  public get isActiveVouchers(): boolean {
    const endDate = 1640476799000;
    const currentDay = new Date().getTime();
    return currentDay < endDate && (this.user.role === 'retention-agent' || this.user.role === 'retention_agents_j');
  }

  public onOpenMonthlyVouchersModal() {
    if (this.isActiveVouchers) {
      this.dialog.open(MonthlyGoalVouchersModalComponent, {
        width: '1000px',
        panelClass: 'vouchers-modal',
        data: {
          commission: this.data.short.individual_target,
        }
      });
    }
  }

  private congratulationsPopupPointsOpened: boolean;
  public openCongratulationsPopupPoints(res) {
    if (res.data) {
      if (this.congratulationsPopupPointsOpened || this.congratulationsPopupCommissionOpened) {
        const pointsData = res.data;
        const achieved_points = pointsData.achieved_points || 0;
        const is_pending = pointsData.is_pending || false;
        this.eventBus.emit(
          'change-points-in-header',
          { achieved_points, is_pending }
        );

      } else {
        this.congratulationsPopupPointsOpened = true;
        setTimeout(() => {
          this.congratulationsPopupPointsOpened = false;
        }, 4000);
        this.ngZone.run(() => {
          this.dialog.open(PointsAchievedPopupComponent, {
            data: {
              points: res.data,
            },
          });
        });
      }
    }
  }

  public getGroupDailyGoal() {
    // width in %
    const SegmentsWidth = [40, 30, 30];

    const group_daily_goal = this.data.short.group_daily_goal;
    if (!group_daily_goal) { return; }
    const type = group_daily_goal.type;
    this.groupDailyGoal.show = true;
    this.groupDailyGoal.type = type;
    //
    let reached_by_group = +group_daily_goal.reached_by_group;


    this.groupDailyGoal.reached_by_group = reached_by_group;
    const goals = group_daily_goal.group_goals;
    if (reached_by_group < 0) { reached_by_group = 0; }
    this.setProgressInBars(
      'groupDailyGoal',
      reached_by_group,
      goals,
      SegmentsWidth
    );
  }


  public getPersonalDailyGoal() {
    const group_daily_goal = this.data.short.group_daily_goal;
    if (!group_daily_goal) { return; }
    const type = group_daily_goal.type
    this.groupDailyGoal.type = type;
    this.personalDailyGoal.show = !!group_daily_goal.personal_goal;
    this.personalDailyGoal.personal_goal = group_daily_goal.personal_goal;
    this.personalDailyGoal.current_value = group_daily_goal.reached_by_user || 0;
    this.personalDailyGoal.progress = ((group_daily_goal.reached_by_user || 0) / (group_daily_goal.personal_goal || 1)) * 100;

    this.personalDailyGoal.goal = this.personalDailyGoal.personal_goal;
  }

  public getPersonalDailyFor() {
    // width in %
    const SegmentsWidth = [40, 30, 30];

    const personal_daily_goal = this.data.short.personal_daily_goal;

    if (!personal_daily_goal) { return; }
    this.personalDailyFor.show = true;
    const type = personal_daily_goal.type;
    this.personalDailyFor.type = type;
    //
    this.personalDailyFor.title = `Personal Daily Goal for ${this.user.full_name}`;

    let reached_by_user = +personal_daily_goal.reached_by_user;
    this.personalDailyFor.reached_by_user = reached_by_user
    const goals = personal_daily_goal.goals;
    if (reached_by_user < 0) { reached_by_user = 0; }
    this.setProgressInBars(
      'personalDailyFor',
      reached_by_user,
      goals,
      SegmentsWidth
    );
  }

  public getMonthlyBonusTarget() {
    // width in %
    const SegmentsWidth = [40, 30, 30];

    const personal_monthly_goal = this.data.short.personal_monthly_goal;

    if (!personal_monthly_goal) { return; }

    this.monthlyBonusTarger.show = true;
    const type = personal_monthly_goal.type;
    this.monthlyBonusTarger.type = type;

    let reached_by_user = +personal_monthly_goal.reached_by_user;
    this.monthlyBonusTarger.reached_by_user = reached_by_user
    const goals = personal_monthly_goal.goals;
    if (reached_by_user < 0) { reached_by_user = 0; }
    this.setProgressInBars(
      'monthlyBonusTarger',
      reached_by_user,
      goals,
      SegmentsWidth
    );
  }

  public setProgressInBars(objType: string, reached_by_entiny: number, goals: any[], SegmentsWidth: number[]) {

    if (!goals) {
      this[objType].reward = 0;
      this[objType].progress = 0;
      return;
    }

    const gLength = goals.length;

    let achI = -1; // index of achieved goal;
    for (let i = 0; i < goals.length; i++) {
      const ci = i;
      if (goals[ci].target <= reached_by_entiny) {
        achI = ci;
      } else {
        break;
      }
    }

    if (achI === -1) {
      this[objType].reward = 0;
      this[objType].progress = 0;
    } else {
      this[objType].reward = goals[achI].reward;
    }

    if (gLength === 1) {
      this[objType].t_3 = goals[0].target;
      this[objType].progress = reached_by_entiny / this[objType].t_3 * 100;
    } else if (gLength === 2) {
      // 2 segments
      this[objType].t_b = 0;
      this[objType].t_1 = goals[0].target;
      this[objType].t_3 = goals[1].target;

      const firstSegmentWidth = SegmentsWidth[0] + SegmentsWidth[1];

      if (achI === 1) {
        // more than last
        this[objType].progress = 100;
      } else if (achI === 0) {
        // last segment
        const reached = reached_by_entiny - this[objType].t_1;
        const full = this[objType].t_3 - this[objType].t_1;
        this[objType].progress = reached / full * SegmentsWidth[2] + firstSegmentWidth;
      } else {
        // first segment
        this[objType].progress = reached_by_entiny / this[objType].t_1 * firstSegmentWidth;
      }
    } else if (gLength >= 3) {
      if (achI === -1) {
        // first segment
        this[objType].t_b = 0;
        this[objType].t_1 = goals[0].target;
        this[objType].t_2 = goals[1].target;
        this[objType].t_3 = goals[2].target;
        this[objType].progress = (reached_by_entiny / this[objType].t_1) * SegmentsWidth[0];
      } else {
        if (achI + 1 === gLength) {
          // more than last
          this[objType].t_b = goals[achI - 3] ? goals[achI - 3].target : 0;
          this[objType].t_1 = goals[achI - 2].target;
          this[objType].t_2 = goals[achI - 1].target;
          this[objType].t_3 = goals[achI].target;
          this[objType].progress = 100;
        } else if (achI + 2 === gLength) {
          // last segment
          this[objType].t_b = goals[achI - 2] ? goals[achI - 2].target : 0;
          this[objType].t_1 = goals[achI - 1].target;
          this[objType].t_2 = goals[achI].target;
          this[objType].t_3 = goals[achI + 1].target;
          const reached = reached_by_entiny - this[objType].t_2;
          const full = this[objType].t_3 - this[objType].t_2;
          this[objType].progress = SegmentsWidth[0] + SegmentsWidth[1] + (reached / full * SegmentsWidth[2]);
        } else if (achI + 3 === gLength) {
          // second segment
          this[objType].t_b = goals[achI - 1] ? goals[achI - 1].target : 0;
          this[objType].t_1 = goals[achI].target;
          this[objType].t_2 = goals[achI + 1].target;
          this[objType].t_3 = goals[achI + 2].target;
          const reached = reached_by_entiny - this[objType].t_1;
          const full = this[objType].t_2 - this[objType].t_1;
          this[objType].progress = SegmentsWidth[0] + (reached / full * SegmentsWidth[1])
        } else if (achI + 3 < gLength) {
          // first segment
          if (gLength === 3) {
            this[objType].t_b = 0;
            this[objType].t_1 = goals[0].target;
            this[objType].t_2 = goals[1].target;
            this[objType].t_3 = goals[2].target;
          } else {
            this[objType].t_b = goals[achI].target || 0;
            this[objType].t_1 = goals[achI + 1].target;
            this[objType].t_2 = goals[achI + 2].target;
            this[objType].t_3 = goals[achI + 3].target;
          }
          const reached = reached_by_entiny - this[objType].t_b;
          const full = this[objType].t_1 - this[objType].t_b;
          this[objType].progress = (reached / full * SegmentsWidth[0]);
        }
      }
    }
  }

  private initedIndividualAndGroup = 0;
  public showIndividualAndGroupSwitch: boolean;
  public getGroupTarget() {
    this.initedIndividualAndGroup++;
    const group_target = this.data.short.group_target;
    if (!group_target) { this.detectCallSetSwitcherIndividualAndGroup(); return; }
    if (this.objIsEmpty(group_target)) { this.detectCallSetSwitcherIndividualAndGroup(); return; }
    this.groupTarget.show = true;

    this.groupTarget.goal = group_target.goal;

    this.groupTarget.type = +this.groupTarget.goal > 999 ? 'Amount' : 'Count';
    this.groupTarget.collected = group_target.collected;
    let progress = (+this.groupTarget.collected / +(this.groupTarget.goal || 1)) * 100;
    if (progress > 100) { progress = 100; }
    this.groupTarget.progress = progress;
    // this.groupTarget.antiProgress = progress > 97 ? progress - 97 : 0;
    this.detectCallSetSwitcherIndividualAndGroup();
  }
  public getIndividualTarget() {
    this.initedIndividualAndGroup++;
    const individual_target = this.data.short.individual_target;
    if (!individual_target) { this.detectCallSetSwitcherIndividualAndGroup(); return; }
    if (this.objIsEmpty(individual_target)) { this.detectCallSetSwitcherIndividualAndGroup(); return; }
    this.individualTarget.show = true;

    if (individual_target.prediction_goal && individual_target.prediction_goal.goal) {
      this.individualTarget.prediction_goal = individual_target.prediction_goal;
    }

    this.individualTarget.goal = individual_target.goal;

    this.individualTarget.type = +this.individualTarget.goal > 999 ? 'Amount' : 'Count';
    this.individualTarget.collected = individual_target.collected;

    let progress = (+this.individualTarget.collected / (+this.individualTarget.goal || 1)) * 100;
    if (progress > 100) { progress = 100; }
    this.individualTarget.progress = progress;

    if (this.individualTarget.prediction_goal) {
      this.individualTarget.antiProgress = progress > 97 ? progress - 97 : 0;
    }

    this.detectCallSetSwitcherIndividualAndGroup();
  }

  public getMilestones() {
    const milestone_info = this.data.short.milestone_info;

    if (
      (!milestone_info || this.objIsEmpty(milestone_info)) ||
      (!milestone_info.milestones || milestone_info.milestones.length < 1)
    ) return;

    let milestonesList = this.updateCurrentMilestoneIfNextExists(milestone_info.milestones);

    let currentMilestone = milestonesList.find(i => i.current);

    if (currentMilestone) {
      this.individualTarget.visible = false;

      const achieved = currentMilestone.achieved + currentMilestone.amount_from_previous_milestone;
      this.milestones = {
        show: true,
        monthlyGoal: this.individualTarget.goal,
        commission: currentMilestone.pending_commission,
        currentMilestone: currentMilestone.milestone_number,
        progress: achieved / currentMilestone.goal * 100,
        achieved: achieved,
        overdue: achieved > currentMilestone.goal ? achieved - currentMilestone.goal : 0,
        left: currentMilestone.goal - achieved
      }

      setTimeout(() => {
        this.initMilestoneChart(milestonesList);
      }, 0)
    }
  }

  public updateCurrentMilestoneIfNextExists(milestones): any {
    let future = null;

    return milestones.map(m => {
      if(m.current && m.success_status) {
        future = this.getFutureMilestone(m, milestones);

        if(future) m.current = false;
      }

      if (future && future.milestone_number === m.milestone_number ){
        m.current = true;
      }

      return m;
    })
  }

  public getFutureMilestone(current, milestones): any {
    let futureId = current.milestone_number + 1;
    let future = milestones.find(m => m.milestone_number == futureId);

    return future;
  }

  public initMilestoneChart(milestones) {
    if (!document.getElementById('milestones-chart')) { return; }

    this.am4Core.useTheme(this.am4ThemesAnimated);
    this.am4Core.addLicense("ch-custom-attribution");

    let chart = this.am4Core.create("milestones-chart", this.am4Charts.PieChart);

    let totalAchieved = 0;

    chart.data = milestones.map((m, idx) => {
      const colorHash = m.success_status ? '#00c01a' : '#972E2E';
      totalAchieved += m.achieved;

      return {
        'name': 'Milestone' + (idx + 1),
        'progress': m.achieved + m.amount_from_previous_milestone,
        'color': this.am4Core.color(colorHash)
      }
    })

    // empty space
    chart.data.push({
      'name': 'space',
      'progress': this.milestones.monthlyGoal - totalAchieved,
      'color': this.am4Core.color('#495E71')
    })

    // Add and configure Series
    let pieSeries = chart.series.push(new this.am4Charts.PieSeries());
    pieSeries.dataFields.value = "progress";
    pieSeries.dataFields.category = "name";
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.slices.template.tooltipText = "";
    pieSeries.slices.template.propertyFields.fill = "color";
  }

  private detectCallSetSwitcherIndividualAndGroup() {
    if (this.initedIndividualAndGroup === 2) {
      this.setSwitcherIndividualAndGroup();
    }
  }
  private setSwitcherIndividualAndGroup() {
    this.initedIndividualAndGroup++;
    if (this.individualTarget.show && this.groupTarget.show) {
      this.showIndividualAndGroupSwitch = this.individualTarget.show && this.groupTarget.show;
      this.individualTarget.visible = true;
      return;
    }
    this.individualTarget.visible = this.individualTarget.show;
    this.groupTarget.visible = this.groupTarget.show;
  }

  public flipMonthlyCard() {
    this.groupTarget.visible = !this.groupTarget.visible;
    this.individualTarget.visible = !this.individualTarget.visible;
  }

  private objIsEmpty(obj) {
    return obj // 👈 null and undefined check
      && Object.keys(obj).length === 0
      && Object.getPrototypeOf(obj) === Object.prototype
  }


  //
  public scroll = {
    down: false,
    moving: false,
    movingDelay: false,
    movingDelayT: null,
    x: 0,
    startX: 0,
  }
  public onMouseDown(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this.scroll.movingDelayT = setTimeout(() => {
      this.scroll.movingDelay = true;
    }, 200);
    this.scroll.down = true;
    this.scroll.x = evt.clientX + this.container.nativeElement.scrollLeft;
    this.scroll.startX = this.container.nativeElement.scrollLeft;

    document.addEventListener('mouseup', this.onMouseUp)
  }
  public onMouseMove(evt) {
    if (!this.scroll.down) { return; }
    const dx = evt.clientX - this.scroll.x;
    if (!this.scroll.moving) {
      if (Math.abs(this.scroll.startX + dx) < 20) { return; }
      this.scroll.moving = true;
    }
    requestAnimationFrame(() => {
      this.container.nativeElement.scrollLeft = -dx;
    });
  }
  public onMouseUp = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (!this.scroll.down) { return; }
    clearTimeout(this.scroll.movingDelayT);
    this.scroll.down = false;
    // after two frames 👇🏻
    requestAnimationFrame(() => {
      this.scroll.movingDelay = false;
      requestAnimationFrame(() => {
        this.scroll.moving = false;
      });
    });

    document.removeEventListener('mouseup', this.onMouseUp);
  }

  public scrollPrev() {
    if (!this.container) { return; }
    const el = this.container.nativeElement;
    el.scrollTo({
      left: el.scrollLeft - 183,
      behavior: 'smooth'
    })
  }
  public scrollNext() {
    if (!this.container) { return; }
    const el = this.container.nativeElement;

    el.scrollTo({
      left: el.scrollLeft + 183,
      behavior: 'smooth'
    })
  }

  public onMouseLeave() {
    this.eventBus.emit('bar-under', null);
  }
  public onMouseEnter(evt, bar) {
    let data;
    if (bar === 'pmg') {
      if (!this.individualTarget.prediction_goal) { return; }
      data = this.individualTarget.prediction_goal;
    }

    const left = evt.target.getBoundingClientRect().x - 50;
    this.eventBus.emit(
      'bar-under',
      { bar, data, left }
    );
  }
}

