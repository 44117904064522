import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentNotoficationModalComponent} from './agent-notofication-modal.component';
import {MatButtonModule, MatDialogModule} from '@angular/material';

@NgModule({
    declarations: [
        AgentNotoficationModalComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [
        AgentNotoficationModalComponent
    ]
})
export class AgentNotoficationModalModule {
}
