import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {User} from '../../../services/users/user';

@Component({
  selector: 'app-avg-modal',
  templateUrl: './avg-modal.component.html',
  styleUrls: ['./avg-modal.component.scss']
})
export class AvgModalComponent implements OnInit {

  public currencyRatio = User.CURRENSY;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {}

  public getStatus(level: any, index: number): string {
    const nextTarget = this.data.avg.info.details.slices[index + 1] ? this.data.avg.info.details.slices[index + 1].value : 0;
    const val = this.data.avg.reached;

    if (val >= level && val < nextTarget) {
      return 'active';
    } else if (val < level) {
      return 'next';
    } else {
      return 'achieved';
    }
  }

}
