import { NgModule } from '@angular/core';
import {DecimalPipe} from './decimal.pipe';

@NgModule({
  declarations: [DecimalPipe],
  exports: [
    DecimalPipe
  ]
})
export class DecimalPipeModule { }
