import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/services/users/user';

@Component({
  selector: 'app-commission-basic',
  templateUrl: './commission-basic.component.html',
  styleUrls: ['../commissions.scss',]
})
export class CommissionBasicComponent implements OnInit {
  @Input() data: any;
  @Input() shift_main_target: number = 0;

  public percentage = 0;
  public progress = 456;
  public reached_by_user: number;

  public totalReward: number;

  public for_each: boolean;

  public commission: number;

  public target = 0;

  public type: string;

  ngOnInit(): void {
    if (!this.data) { return; }
    const reward_settings = this.data.reward_settings;
    this.type = this.data.type;
    this.reached_by_user = this.data.reached_by_user || 0;
    this.target = reward_settings.details.target || 0;

    if (typeof reward_settings.details.for_each === 'boolean') {
      this.for_each = reward_settings.details.for_each && reward_settings.details.for_each_value;
    } else {
      this.for_each = reward_settings.details.for_each_value;
    }
    if (this.reached_by_user === 0) {
      this.setprogress(0);
    } else {
      this.setprogress(100 * this.reached_by_user / this.target)
    }
    this.totalReward = reward_settings.details.reward;
    this.commission = this.data.commission || 0;
  }

  public setprogress(val: number): void {
    // Start 456; // End 190 // Duration 266
    if (val > 100) { val = 100; }
    this.percentage = val;
    this.progress = 456 - (2.66 * val);
  }
}
