import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UserService} from 'src/app/services/users/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public changePasswordGroup: FormGroup;
  public loader = false;
  public error;

  public currentPasswordType = 'password';

  public newPasswordType = 'password';

  public confirmPasswordType = 'password'

  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private self: MatDialogRef<ChangePasswordComponent>
  ) { }

  ngOnInit() {
    this.changePasswordGroup = new FormGroup({
      currentPassword: new FormControl('', this.data.isRestore ? [] : [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    });
  }

  public updatePassword() {
    this.loader = true;

    const body = {
      old_password: this.changePasswordGroup.get('currentPassword').value,
      new_password: this.changePasswordGroup.get('newPassword').value,
      confirm_new_password : this.changePasswordGroup.get('confirmPassword').value,
    };

    if (this.data.isRestore) {
      body['is_restore'] = true;
    }

    this.userService.changePassword(this.data.userId, body).subscribe(res => {
      this.loader = false;
      this.self.close(res);
    }, (error) => {
      this.loader = false;
      this.error = error.error.error;
    })
  }

  public changePasswordType(input) {
    switch (input) {
      case 'currentPassword':
        if (this.currentPasswordType === 'password') {
          this.currentPasswordType = 'text';
        } else {
          this.currentPasswordType = 'password';
        }
        break;
      case 'newPassword':
        if (this.newPasswordType === 'password') {
          this.newPasswordType = 'text';
        } else {
          this.newPasswordType = 'password';
        }
        break;
      case 'confirmPassword':
        if (this.confirmPasswordType === 'password') {
          this.confirmPasswordType = 'text';
        } else {
          this.confirmPasswordType = 'password';
        }
        break;
    }
  }

}
