import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { EventBusService } from 'src/app/services/event-bus/event-bus.service';

@Component({
  selector: 'app-header-under',
  templateUrl: './header-under.component.html',
  styleUrls: ['./header-under.component.scss',],
  encapsulation: ViewEncapsulation.None
})
export class HeaderUnderComponent implements OnInit, OnDestroy {

  public subscribers: any = {};

  public bars = {
    pmg: {
      show: false,
      left: 0,
      data: { total_days: 0, future_days: 0, goal: 0 }
    }
  }

  constructor(
    private eventBus: EventBusService,
  ) { }

  ngOnInit(): void {
    this.subscribers.under_bar = this.eventBus.on('bar-under').subscribe((res) => {
      this.onBar(res);
    });
  }

  ngOnDestroy(): void {
    for (const key in this.subscribers) {
      if (this.subscribers[key] && this.subscribers[key].unsubscribe) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  public timeOutC: any;

  public onBar(data) {
    if (data === null) {
      this.timeOutC = setTimeout(() => {
        for (const bar in this.bars) {
          this.bars[bar].show = false;
        }
      }, 2000);
    } else if (data) {
      clearTimeout(this.timeOutC);
      const bar = data.bar;
      if (!this.bars[bar]) { return; }
      this.bars[bar].show = true;
      this.bars[bar].left = data.left;
      this.bars[bar].data = data.data;
    }
  }

}
