///<reference path="../../../../node_modules/@angular/material/input/typings/input-module.d.ts"/>
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationModalComponent} from './notification-modal.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {ReactiveFormsModule} from '@angular/forms';
import {NbCardModule} from '@nebular/theme';
import {SmallLoaderModule} from '../general/small-loader/small-loader.module';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSnackBarModule
} from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TranslateModule} from '@ngx-translate/core';
import {RangeTimeInputModule} from '../range-time-input/range-time-input.module';
import {ScheduleNotificationModule} from '../schedule-notification/schedule-notification.module';

@NgModule({
  declarations: [
    NotificationModalComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    NbCardModule,
    SmallLoaderModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    TranslateModule,
    RangeTimeInputModule,
    ScheduleNotificationModule,
    MatIconModule
  ],
  exports: [
    NotificationModalComponent
  ]
})
export class NotificationModalModule {
}
