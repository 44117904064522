import {Action} from '@ngrx/store';

export enum UsersActionTypes {
    SET_USERS = '[Users] SetUsers',
    SET_USERS_MAP  = '[Users] SetUsersMap'
}

export class SetUsers implements Action {
    readonly type = UsersActionTypes.SET_USERS;
    constructor(public payload: Array<any>) {}
}

export class SetUsersMap implements Action {
    readonly type = UsersActionTypes.SET_USERS_MAP;
    constructor(public payload: any) {}
}

export type UsersActionUnion = SetUsers | SetUsersMap;
