import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as PaymentNotificationActions from '../../../actions/payment_notification.action';
import storage from 'store';

@Component({
    selector: 'app-payment-notifications',
    templateUrl: './payment-notifications.component.html',
    styleUrls: ['./payment-notifications.component.scss']
})
export class PaymentNotificationsComponent implements OnInit {

    public unread: any;

    public isOpen: boolean;

    private subs = {
        paymentNotification: null,
    };

    constructor(
        private store: Store<any>,
    ) {
        this.subs.paymentNotification = this.store.pipe(select('paymentNotification')).subscribe( (value: any) => {

            this.isOpen = value.isOpen;

            if (!value.isOpen) {
                this.unread = value.not_viewed;
                storage.set('unread_payment_notifications', value.not_viewed);
                storage.set('unread_notifications', value.not_viewed);
            } else  {
                this.unread = 0;
                if (value.not_viewed) {
                  this.store.dispatch(new PaymentNotificationActions.SetNotViewedNotifications({ count: 0 }));
                }
                storage.set('unread_payment_notifications', 0);
            }
        });
    }

    ngOnInit() {
    }

    public openPaymentWindow() {
        this.store.dispatch(new PaymentNotificationActions.TogglePaymentNotifications());
    }
}
