import { NgModule } from '@angular/core';
import {ArrayTranslatePipe} from './array-translate.pipe';

@NgModule({
  declarations: [
    ArrayTranslatePipe
  ],
  exports: [
    ArrayTranslatePipe
  ]
})
export class ArrayTranslatePipeModule { }
