import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

import { SpinnerModule } from '../../general/spinner/spinner.module';

import { CommissionModalComponent } from './commission-modal.component';
import { CommissionsModule } from './commissions/commissions.module';
import { TranslateModule } from '@ngx-translate/core';
import {ToCurrencyPipeModule} from '../../../pipes/to-currency/to-currency.pipe.module';


@NgModule({
  declarations: [
    CommissionModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatTabsModule,
    MatTooltipModule,
    MatIconModule,

    SpinnerModule,
    CommissionsModule,
    TranslateModule,
    ToCurrencyPipeModule
  ],
  entryComponents: [
    CommissionModalComponent
  ]
})
export class CommissionModalModule {
}
