import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NotificationModalComponent} from '../notification-modal/notification-modal.component';
import {NotificationsService} from '../../services/notifications/notifications.service';
import SearchResponse from '../../response/search.response';
import {ConfirmModalComponent} from '../general/confirm-modal/confirm-modal.component';
import {ErrorModalComponent} from '../general/error-modal/error-modal.component';
import {MatDialog} from '@angular/material';


@Component({
    selector: 'app-action-notification',
    templateUrl: './action-notification.component.html',
    styleUrls: ['./action-notification.component.scss']
})
export class ActionNotificationComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    public subscriber: any;

    public currentTab: any;

    public pieLoader: any = true;

    public completed: any;

    @Input() public opened: boolean;

    public checked: any = {
        'current': [],
        'missed': [],
    };

    public notifications: SearchResponse = new SearchResponse();

    @ViewChild('checkbox') public checkbox: any;

    constructor(private notif: NotificationsService,
                private matDialog: MatDialog) {
    }

    public changeTab(event) {
        this.currentTab = event.tab.textLabel.toLowerCase();
        this.notifications.data = [];
        this.getNotification(this.currentTab);

    }

    ngOnInit() {
    }

    ngOnChanges(changed) {
      if (this.opened) {
        this.getNotification('current');
      }
    }

    ngAfterViewInit() {
    }

    public newNotification() {

        const dialog = this.matDialog.open(NotificationModalComponent, {
            width: '700px',
            hasBackdrop: true,
            data: {
                type: 'general',
            }
        });


        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.getNotification('current');
            }
        }, (error) => {
            if (error !== 'close' && error !== 1) {
                this.matDialog.open(ErrorModalComponent, {
                    width: '500px'
                });
            }
        });
    }

    public getNotification(currentTab) {
        this.pieLoader = true;
        this.subscriber = this.notif.getNotification(this.notifications.getReqData(), currentTab).subscribe((response) => {
            this.notifications = Object.assign(this.notifications, response);
            this.pieLoader = false;
        }, (error) => {
            // console.log(error);
        });
    }

    public goToNotification(notification) {
        if (notification.related_type === 'general') {
            return;
        }
        window.open('/details/' + notification.related_type + '/' + notification.related_id);
    }

    public editNotification(notification) {
        const dialog = this.matDialog.open(NotificationModalComponent, {
            width: '700px',
            hasBackdrop: true,
            data: {
                notification: notification
            },
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.getNotification('current');
            }
        }, (error) => {
            if (error !== 'close' && error !== 1) {
                this.matDialog.open(ErrorModalComponent, {
                    width: '500px'
                });
            }
        });
    }

    public removeNotification(currentTab, notification) {

        const dialog = this.matDialog.open(ConfirmModalComponent, {
            width: '500px',
            hasBackdrop: true,
            data: {
                title: 'Remove Notification',
                message: 'Are you sure want to remove this notification ?',
            }
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.subscriber = this.notif.deleteNotification(notification.id).subscribe(() => {
                    this.pieLoader = false;
                    this.getNotification(currentTab);
                }, (error) => {
                    // console.log(error);
                });
            }
        }, (error) => {
            if (error !== 'close') {
                this.matDialog.open(ErrorModalComponent, {
                    width: '500px'
                });
            }
        });
    }

    public markComplete(tab, event, notification, i) {

        const checked = this.checked[tab];

        const dialog = this.matDialog.open(ConfirmModalComponent, {
            width: '500px',
            hasBackdrop: true,
            data: {
                title: 'Mark Complete',
                message: 'Are you sure want to mark  ' + notification.title + ' completed ?',
            }
        });

        dialog.afterClosed().subscribe((result) => {

            if (result) {
                this.completed = notification;
                this.completed.completed = true;

                this.subscriber = this.notif.editNotification(this.completed, this.completed.id).subscribe(() => {
                    this.pieLoader = false;
                    this.getNotification(tab);
                });

            }

            checked[i] = false;

        }, (error) => {
            if (error !== 'close') {
                this.matDialog.open(ErrorModalComponent, {
                    width: '500px'
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    }

}
