import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Rx';
import {LiveService} from '../live/live.service';

@Injectable({
    providedIn: 'root'
})
export class FlipCardsService {

    constructor(private api: ApiService,
                private live: LiveService) {

    }

    public getFlipCards(body): Observable<any> {
        return this.api.post('/flipcards', {
            body: body
        });
    }

    public incoming(): Observable<any> {
        return this.live.subscribe('goals');
    }
}
