import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator, Validators
} from '@angular/forms';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneInputComponent
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    }
  ]
})
export class PhoneInputComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() placeholder: string = 'Phone number';

  @Output() countryChange: EventEmitter<any> = new EventEmitter();
  @Output() errors: EventEmitter<any> = new EventEmitter();

  private validationError = {
    '-99': 'Phone number is invalid',
    0: 'Phone number is invalid',
    1: 'Invalid country code',
    2: 'Too short',
    3: 'Too long',
    4: 'Is possible local only',
    5: 'Invalid length'
  };

  public telOptions = {
    separateDialCode: true,
    placeholder: 'Phone Number *',
    initialCountry: 'gb',
  };

  public telInput = null;

  public phoneControl: FormControl = new FormControl('', [
    Validators.required
  ]);

  public touched = false;
  public disabled = false;

  onChange = (value) => {};
  onTouched = () => {};
  onValidationChange: any = () => {};

  constructor() { }

  public onCountryChange(e): void {
    const countryName = e.name.split(' (')[0] || '';

    this.countryChange.emit(countryName);
  }

  ngOnInit() {
    this.phoneControl.valueChanges.subscribe(value => {
      if (this.telInput) {
        const phone = this.telInput.getSelectedCountryData();

        this.onChange('+' + phone.dialCode + value.replaceAll(' ', ''));

        this.onCountryChange(phone);

        this.onValidationChange();
      }
    });
  }

  public hasError(status): void {
    if (!status) {
      const errorCode = this.telInput.getValidationError();
      const phone = this.phoneControl.value;

      if (phone !== '') {
        this.phoneControl.setErrors({ 'name': this.validationError[errorCode] });

        this.errors.emit(this.phoneControl.errors);
      }
    }
  }

  public telInputObject(obg): void {
    this.telInput = obg;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.phoneControl.errors) {
      return this.phoneControl.errors;
    } else {
      return null;
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  writeValue(control: any): void {
    this.phoneControl.setValue(control);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
