export const LACK_OF_FUNDS = 2;

export const HIDE_COUNTRY_OF_BIRTH_FIELD = [23638445, 23638451, 23638453, 23638457, 23638464, 23638468, 23638491, 23638501];

export const INSTALLMENT_NOTIFICATION_ROLES = [
  'retention-agent',
  'retention_agents_j',

  'retention_file_opening',
  'retention_ai_manager',
  'retention_agent_manager_j',
  'retention_agent_manager',

  'conversion_ua',
  'conversion_sp',
  'conversion_interview',
  'conversion-incubation',
  'agent',
  'working_holiday_conversion',
  'collection',

  'admin',
];
