import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultGuardComponent} from './default-guard.component';

@NgModule({
    declarations: [
        DefaultGuardComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        DefaultGuardComponent
    ]
})
export class DefaultGuardModule {
}
