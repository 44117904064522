import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import localStore from 'store';
import {Router} from '@angular/router';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit, OnDestroy {

  public view_referral_announcement = false;

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {

    this.initReferralAnnouncement()
  }

  private initReferralAnnouncement() {
    const availableUserRoles = ['retention-agent', 'retention_agents_j', 'retention_file_opening', 'retention_agent_manager', 'csr-agent', 'conversion_interview', 'agent'];

    const user = localStore.get('user');

    if (user) {
      if (!availableUserRoles.includes(user.role)) {
        return;
      }
    } else {
      return;
    }

    this.view_referral_announcement = localStore.get('view_referral_announcement');

      if (this.view_referral_announcement) {
        setTimeout(() => {
          this.closeNotification('referral_announcement', null);
          this.view_referral_announcement = false;
        }, 180000);
      }
  }

  ngOnDestroy(): void {

  }

  closeNotification(announcement: string, element: any) {

    localStore.set('view_' + announcement, false);

    if (element) {
      element.remove();
    }
  }

  goToReferralProgram() {
    window.open('https://cv-crm-documents.s3.us-west-2.amazonaws.com/LCG-Employee+Referral+Program.pdf', '_blank');
  }
}

