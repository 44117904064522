import {User} from '../services/users/user';

import store from 'store';

export class CurrentUser {

    static user: User = null;

    static setUser(user: any): boolean {

        if (!user) {
            return false;
        }

        CurrentUser.user = null;

        store.set('user', user);

        return true;
    }

    static getUser(): User | null {

        if (CurrentUser.user) {
            return CurrentUser.user;
        }

        const user = store.get('user');

        if (!user) {
            return CurrentUser.user;
        }

        CurrentUser.user = user;

        return CurrentUser.user;
    }

    static get(name: string): any {

        const user: User | null = CurrentUser.getUser();

        if (user && user[name]) {
            return user[name];
        }

        return null;
    }
}
