import {Component, Input, OnInit} from '@angular/core';
import * as PaymentNotificationActions from '../../../../../actions/payment_notification.action';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss']
})
export class BubbleComponent implements OnInit {

  private timer: any;

  @Input() message: any;

  constructor(
      private store: Store<any>

  ) { }

  ngOnInit() {
    this.timer = setTimeout(() => {
      this.store.dispatch(new PaymentNotificationActions.RemoveBubbleMessage(this.message.id));
    }, 15000);
  }

  public openChat(): void {
    // this.store.dispatch(new ChatActions.SetActiveAgent(this.message.user_id));
    this.store.dispatch(new PaymentNotificationActions.TogglePaymentNotifications(true));
    this.store.dispatch(new PaymentNotificationActions.RemoveBubbleMessage(this.message.id));
  }

  public close(): void {
    clearTimeout(this.timer);
    this.store.dispatch(new PaymentNotificationActions.RemoveBubbleMessage(this.message.id));
  }

  public getStatus(status) {

  }

  public getProduct(product: string): string {
    switch (product) {
      case 'IELTS Ashton - Retention - General Course':
        return 'IELTS Conley University - Retention - General Course';
      case 'IELTS Ashton - Retention - Academic Course':
        return 'IELTS Conley University - Retention - Academic Course';
      default:
        return product;
    }
  }

}
