import {Component, OnInit, OnDestroy, NgZone} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as ChatActins from '../../../../actions/chat.actions';
import {ChatService} from '../../../../services/manager-chat/chat.service';
import md5 from 'md5';
import {UserService} from '../../../../services/users/user.service';

@Component({
  selector: 'app-chat-content',
  templateUrl: './chat-content.component.html',
  styleUrls: ['./chat-content.component.less']
})
export class ChatContentComponent implements OnInit, OnDestroy {

  public agent: any;

  public assigners = [];

  public loader: boolean;

  public message: string;

  private subs = {
    agent: null,
    history: null,
    message: null
  };

  constructor(
      private ngZone: NgZone,
      private store: Store<any>,
      private userService: UserService,
      private chatService: ChatService
  ) {
    this.subs.agent = this.store.pipe(select('user')).subscribe((value) => {
      if (!this.agent && this.agent !== value ) {
        this.agent = value;
      }
    });

    this.store.pipe(select('chat')).subscribe((value) => {
      this.ngZone.run(() => {
        this.assigners = value.assigners;
      });
    });
  }

  ngOnInit() {
    this.store.dispatch(new ChatActins.SetNotViewedMessage(0));
  }

  ngOnDestroy(): void {
    for (const name in this.subs) {
      if (this.subs.hasOwnProperty(name) && this.subs[name]) {
        this.subs[name].unsubscribe();
      }
    }
  }

  public send(): void {
    if (this.message.trim() !== '') {
      const newMessage = {
        body: {
          text: this.message
        },
        user_id: this.agent.id,
        type: 0,
        uid: md5(this.agent.id + new Date().getTime().toString()),
        created_at: new Date().getTime(),
        sender: 2,
      };

      this.chatService.sendMessage(newMessage).subscribe(res => {
        // this.store.dispatch(new ChatActins.SetMessage(newMessage));
        this.message = '';
      });
    }
  }

  public getAgentAvatar(crm_id): string {
    return this.userService.getAvatar(crm_id);
  }

}
