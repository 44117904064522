import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as ChatActions from '../../../actions/chat.actions';
import {ChatService} from '../../../services/manager-chat/chat.service';
import {CentrifugeService} from '../../../services/centrifuge/centrifuge.service';
import {PushNotificationsService} from 'ng-push';

@Component({
  selector: 'app-chat-manager',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.less']
})
export class ChatComponent implements OnInit, OnDestroy {

  private subs = {
    chat: null,
    agent: null,
    agents: null,
    centChat: null
  };

  private agent: any;

  public isOpen: boolean;

  public unread: any;

  constructor(
      private store: Store<any>,
      private chatServices: ChatService,
      private centrifugeService: CentrifugeService,
      private pushNotifications: PushNotificationsService
  ) {
    this.subs.chat = this.store.pipe(select('chat')).subscribe((value: any) => {
      this.isOpen = value.isOpen;
      this.unread = value.not_viewed;
    });

    this.subs.agent = this.store.pipe(select('user')).subscribe((value) => {
      if (!this.agent && this.agent !== value ) {
        this.agent = value;
      }
    });
  }

  ngOnInit() {
    // this.setStore();
    this.initCentrifuge();
  }

  ngOnDestroy(): void {
    for (const name in this.subs) {
      if (this.subs.hasOwnProperty(name) && this.subs[name]) {
        if (this.subs[name] &&this.subs[name].unsubscribe) {
          this.subs[name].unsubscribe();
        }
      }
    }
  }

  private setStore(): void {
    this.chatServices.getInfo().subscribe((res) => {
      this.store.dispatch(new ChatActions.SetAssigners(res.available_assigners));
      if (res.not_viewed) {
        this.store.dispatch(new ChatActions.SetNotViewedMessage(res.not_viewed));
      }
    });
  }

  private initCentrifuge(): void {
    // todo
    // this.subs.agents = this.centrifugeService.listen(`agents`).subscribe(res => {});

    this.subs.centChat = this.centrifugeService.listen(`chats_${this.agent.id}`).subscribe(({data}) => {
      // console.log('--- chats_${this.agent.id} chanel----', data);
      if (!this.isOpen && data.message) {
        this.store.dispatch(new ChatActions.SetBubbleMessage(data));
        if (document.visibilityState === 'hidden') {
          this.createPush(data);
        }
        this.store.dispatch(new ChatActions.SetNotViewedMessage( this.unread + 1));
        this.messageAudio();
      }
    });

    this.centrifugeService.listen(`online_offline_assigners`).subscribe(({data}) => {
      // console.log('--- online_offline_assigners chanel ----', data);
      if (data.event_name === 'offline') {
        this.store.dispatch(new ChatActions.UpdateAssigners(data.users));
      } else if (data.event_name === 'online') {
        this.store.dispatch(new ChatActions.SetAssigners(data.users));
      }
    });
  }

  private messageAudio(): void {
    const audio = new Audio('https://d1kd7yer7sk5mb.cloudfront.net/sounds/agent-notification.mp3');
    audio.load();
    audio.pause();
    audio.currentTime = 0;
    audio.play();
  }

  public openChat(): void {
    this.store.dispatch(new ChatActions.ToggleChat());
  }

  public createPush(data) {
    this.pushNotifications.create('You have a new message from ' + data.full_name, {
      body: data.message.body.text,
      // icon: this.currentUser.avatar,
      dir: 'auto',
    }).subscribe((notif) => {
      if (notif.event.type === 'show') {
        setTimeout(() => {
          notif.notification.close();
        }, 50000);
      }
    });
  }
}

