import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { RouterModule } from '@angular/router';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { HeaderPointsComponent } from './header-points.component';
import { HeaderPointsModalModule } from '../header-points-modal/header-points-modal.module';

@NgModule({
  declarations: [
    HeaderPointsComponent
  ],
  imports: [
    CommonModule,
    // RouterModule,

    MatIconModule,
    MatDialogModule,
    MatTooltipModule,

    HeaderPointsModalModule,
  ],
  exports: [
    HeaderPointsComponent
  ]
})
export class HeaderPointsModule {
}
