import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePickerComponent} from './date-picker.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {FormsModule} from '@angular/forms';
import {NbInputModule} from '@nebular/theme';
import {MatTooltipModule} from '@angular/material';
import {CustomTranslatePipeModule} from '../../../../pipes/customTranslate/customTranslate.pipe.module';

@NgModule({
    declarations: [
        DatePickerComponent
    ],
  imports: [
    CommonModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    FormsModule,
    NbInputModule,
    MatTooltipModule,
    CustomTranslatePipeModule
  ],
    exports: [
        DatePickerComponent
    ],
    entryComponents: [
        DatePickerComponent
    ]
})
export class DatePickerModule {
}
