import { Component, OnInit } from '@angular/core';
import * as ChatActions from '../../../../actions/chat.actions';
import * as PaymentNotificationActions from '../../../../actions/payment_notification.action';
import {select, Store} from '@ngrx/store';

@Component({
  selector: 'app-payment-window',
  templateUrl: './payment-window.component.html',
  styleUrls: ['./payment-window.component.scss']
})
export class PaymentWindowComponent implements OnInit {

  constructor(
      private store: Store<any>
  ) {
  }

  ngOnInit() {
  }

  public closeWindow() {
    this.store.dispatch(new PaymentNotificationActions.TogglePaymentNotifications(false));
  }

}
