import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderUnderComponent } from './header-under.component';

@NgModule({
  declarations: [
    HeaderUnderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    HeaderUnderComponent
  ]
})
export class HeaderUnderModule {
}
