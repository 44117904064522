import {Action} from '@ngrx/store';

export enum ChartActionTypes {
    SET_CHARTS = '[Charts] SetCharts'
}

export class SetCharts implements Action {
    readonly type = ChartActionTypes.SET_CHARTS;
    constructor(public payload: Array<any>) {}
}

export type ChartsActionUnion = SetCharts;
