import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-bool-filter',
    templateUrl: './bool-filter.component.html',
    styleUrls: ['./bool-filter.component.scss']
})
export class BoolFilterComponent implements OnInit {

    @Input() public model: any;

    @Output() public modelChange: EventEmitter<any> = new EventEmitter();

    @Output() public filterChange: EventEmitter<any> = new EventEmitter();

    public values: any[] = [
        {
            label: 'Yes',
            value: 'yes',
            checked: false
        },
        {
            label: 'No',
            value: 'no',
            checked: false
        },
        {
            label: 'All',
            value: '',
            checked: true
        }
    ];

    constructor() {
    }

    ngOnInit() {
    }

    public radioChange(event) {
        this.model = event.value;
        this.modelChange.emit(this.model);
        this.filterChange.emit(null);
    }
}
