import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatBottomSheet} from '@angular/material';
import {MultipleSelectorComponent} from '../multiple-selector/multiple-selector.component';

@Component({
    selector: 'app-autocomplete-bool',
    templateUrl: './autocomplete-bool.component.html',
    styleUrls: ['./autocomplete-bool.component.scss']
})
export class AutocompleteBoolComponent implements OnInit, OnChanges {

    public selection = [];

    @Input() public related: any;

    @Input() public fieldName: any;

    @Input() public selected: any = [];

    @Input() public model: any;

    @Input() public label: any;

    @Output() public change = new EventEmitter();

    constructor(private bottomSheet: MatBottomSheet) {
    }

    ngOnInit() {

    }
    ngOnChanges(changes: SimpleChanges) {
        if (!changes.model.firstChange && !changes.model.currentValue) {
            this.selected = [];
        }
        if (changes['selected'] && !changes.selected.currentValue) {
            this.selected = [];
        }

        if (changes.model.currentValue) {
            const selFilters = JSON.parse(this.model);
            this.selected = selFilters.map((data) => {
                return {
                    label: data.charAt(0).toUpperCase() + data.slice(1),
                    value: data
                }
            });
        }
    }

    public open() {
        this.bottomSheet.open(MultipleSelectorComponent, {
            data: {
                selected: Object.assign([], this.selected),
                selection: [
                    {
                        label: 'Empty',
                        value: 'empty'
                    },
                    {
                        label: 'Yes',
                        value: 'yes'
                    },
                    {
                        label: 'No',
                        value: 'no'
                    }
                    ],
            }
        }).afterDismissed().subscribe((result) => {
            const prev = this.selected.map(o => o.label).join('');
            const next = result.map(o => o.label).join('');
            if (prev !== next) {
                this.change.emit({
                    name: this.fieldName,
                    value: result
                });
            }
            this.selected = Object.assign([], result);
        });
    }

}
