import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators} from '@angular/forms';
import {DateConverter} from '../../helpers/DateConverter';
import {NotificationsService} from '../../services/notifications/notifications.service';
import {CalendarService} from '../../services/calendar/calendar.service';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import storage from 'store';
import {NOTIFICATION_TYPE} from '../../constants/notifications';

const DAY_TIME = 86400000;

@Component({
  selector: 'app-range-time-input',
  templateUrl: './range-time-input.component.html',
  styleUrls: ['./range-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RangeTimeInputComponent
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RangeTimeInputComponent),
      multi: true,
    }
  ]
})
export class RangeTimeInputComponent implements OnInit, OnChanges {

  @Input() public placeholder: string = 'Date';

  @Input() public timezone: string = null;
  @Input() public city: string = null;
  @Input() public rescheduleCount: number = null;
  @Input() public notification_type: number = null;
  @Input() public reschedule: number = null;
  @Input() public countryOfResidence: string = null;

  public limit = false;

  public user = storage.get('user');

  public userTimezone: string;

  public clientTime: string;

  public min = DateConverter.startOfDay(new Date());

  public max: Date | null = null;

  public DateConverter = DateConverter;

  public touched = false;
  public disabled = false;

  public control = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    time: new FormControl(null, [Validators.required])
  });

  public hourList = this.generateHourList();

  onChange = (setting) => {};
  onTouched = () => {};

  constructor(
    private service: NotificationsService,
    private calendar: CalendarService,
  ) {}

  ngOnInit() {
    this.setTimezone();
    this.getReschedule();

    this.control.valueChanges.subscribe((val) => {
      if (this.control.valid) {
        const newDate: Date = val.date;
        newDate.setHours(val.time.split(':')[0]);
        newDate.setMinutes(val.time.split(':')[1]);

        this.checkUserTime(newDate);

        this.onChange(newDate);
      }
    });
  }

  ngOnChanges(changes): void {
    if (changes.city && changes.city.currentValue && changes.city.currentValue.length > 3) {
      const val = this.control.getRawValue();
      const newDate: Date = val.date;

      newDate.setHours(val.time.split(':')[0]);
      newDate.setMinutes(val.time.split(':')[1]);

      this.checkUserTime(newDate);
    }
  }

  public getReschedule(): void {
    this.calendar.getReschedule(this.user.id).subscribe(res => {
      if (res && res.event_types.includes(NOTIFICATION_TYPE[this.notification_type])) {
        const reschedule = this.rescheduleHelper(this.rescheduleCount, res);
        if (reschedule) {
          const max = new Date().getTime() + reschedule;
          let maxDate = new Date(max);

          if (DAY_TIME <= reschedule) {
            maxDate = DateConverter.endOfDay(maxDate);
          }

          this.max = maxDate;
        }

        const reschedule_setup = _get(res, 'reschedule_setup', {});

        this.limit = reschedule_setup.status && this.rescheduleCount && this.rescheduleCount >= reschedule_setup.count_limit;
        if (this.limit) {
          this.disabled = true;
        }
      }
    });
  }

  rescheduleHelper(reschedule_count, data): number | null {
    if (!data || !data.status) return null;

    if (reschedule_count === null) {
      const first_setup = _get(data, 'first_setup', null);

      if (first_setup && first_setup.status) {
        return first_setup.time_limit * 1000;
      }
    } else {
      const reschedule_setup = _get(data, 'reschedule_setup', null);

      if (reschedule_setup && reschedule_setup.status && reschedule_count < reschedule_setup.count_limit) {
        const limits = _filter(reschedule_setup.settings, ['status', true]);

        if (limits && limits[reschedule_count]) {
          return limits[reschedule_count].time_limit * 1000;
        }
      }
    }

    return null;
  }

  private checkUserTime(newDate): void {
    if (this.timezone) {
      this.clientTime = newDate.toLocaleString('en', {timeZone: this.timezone});
    } else if (this.city && this.countryOfResidence) {
      this.service.getTimezone({
        city: this.city,
        country_of_residence: this.countryOfResidence
      }).subscribe(({time_zone}) => {
        this.clientTime = newDate.toLocaleString('en', {timeZone: time_zone});
      });
    }
  }

  public checkMinTime(time): boolean {
    const date: Date = this.control.get('date').value;
    const min = new Date();
    const max = this.max;

    if (date && max && min && date.getDate() === max.getDate() && date.getMonth() === max.getMonth() && date.getDate() === min.getDate() && date.getMonth() === min.getMonth()) {
      return this.parseTimeToMinutes(time) > this.parseTimeToMinutes(max.getHours() + ':' + max.getMinutes()) || this.parseTimeToMinutes(time) < this.parseTimeToMinutes(min.getHours() + ':' + min.getMinutes());
    }

    if (date && max && date.getDate() === max.getDate() && date.getMonth() === max.getMonth()) {
      return this.parseTimeToMinutes(time) > this.parseTimeToMinutes(max.getHours() + ':' + max.getMinutes());
    }

    if (date && min && date.getDate() === min.getDate() && date.getMonth() === min.getMonth()) {
      return this.parseTimeToMinutes(time) < this.parseTimeToMinutes(min.getHours() + ':' + min.getMinutes());
    }

    return false;
  }

  public generateHourList(): string[] {
    const hours = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        hours.push(formattedHour);
      }
    }
    return hours;
  }

  private findClosestTime(targetTime, hourList) {
    if (!hourList || hourList.length === 0) {
      throw new Error('Invalid hour list');
    }

    const targetMinutes = this.parseTimeToMinutes(targetTime);

    let closestTime = hourList[0];
    // let minDifference = Math.abs(targetMinutes - this.parseTimeToMinutes(closestTime));

    for (let i = 1; i < hourList.length; i++) {
      const currentDifference = targetMinutes - this.parseTimeToMinutes(hourList[i]);

      if (currentDifference < 0) {
        closestTime = hourList[i];
        break;
      }
    }

    return closestTime;
  }

  private parseTimeToMinutes(time): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  private setValue(value: Date): void {
    if (value) {
      this.control.patchValue({
        date: value,
        time: this.findClosestTime(value.getHours() + ':' + value.getMinutes(), this.hourList)
      });
    }
  }

  private setTimezone(): void{
    const offset = new Date().getTimezoneOffset();
    const offsetAbs = Math.abs(offset);

    this.userTimezone = 'GMT' + (offset < 0 ? "+" : "-") + ("00" + Math.floor(offsetAbs / 60)).slice(-2) + ":" + ("00" + (offsetAbs % 60)).slice(-2);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.control.invalid) {
      return { invalid: true };
    }

    return null;
  }

  writeValue(control: any): void {
    this.setValue(control);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
