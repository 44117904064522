import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/services/users/user';

@Component({
  selector: 'app-commission-split',
  templateUrl: './commission-split.component.html',
  styleUrls: ['../commissions.scss',]
})
export class CommissionSplitComponent implements OnInit {
  @Input() data: any;

  public currencyRatio = User.CURRENSY;

  public percentage = 0;
  public progress = 456;
  public commission = 0;
  public currentTargetIndex = 0;

  public reward_type: number;

  public for_each: boolean;

  public type: string;
  public slices: any[];

  public reached_by_user: number;

  public slicesShow: any[];

  ngOnInit(): void {
    if (!this.data) { return; }
    const reward_settings = this.data.reward_settings;
    this.slices = reward_settings.details.slices
    this.type = this.data.type;
    this.reached_by_user = this.data.reached_by_user || 0;
    this.reward_type = +reward_settings.reward_type;

    if (typeof reward_settings.details.for_each === 'boolean') {
      this.for_each = reward_settings.details.for_each && reward_settings.details.for_each_value;
    } else {
      this.for_each = reward_settings.details.for_each_value;
    }

    this.commission = this.data.commission || 0;

    this.slicesShow = this.slices.map((el, index) => {
      return {
        from_value: el.from_value,
        reward: el.reward,
        to_value: el.to_value,
        status: this.getStatus(el, index),
      }
    });
    if (this.currentTargetIndex === this.slices.length - 1) {
      this.setprogress(100);
    }
  }

  public getStatus(el: any, index: number): string {
    const val = this.reached_by_user
    if (val >= el.from_value) {
      if (val > el.to_value) {
        // this.commission += el.reward;
        return 'achieved';
      } else {
        this.currentTargetIndex = index;
        // this.commission += el.reward;
        this.calcProgress(el);
        return 'active'
      }
    } else {
      return 'next';
    }
  }

  public calcProgress(el: any): void {
    const val = Math.round((this.reached_by_user - el.from_value) / (el.to_value - el.from_value) * 100);
    this.setprogress(val);
  }
  public setprogress(val: number): void {
    // Start 456; // End 190 // Duration 266
    this.percentage = val;
    this.progress = 456 - (2.66 * val);
  }
}
