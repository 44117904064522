import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorModalComponent} from './error-modal.component';
import {MatButtonModule, MatDialogModule} from '@angular/material';

@NgModule({
    declarations: [
        ErrorModalComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule
    ],
    exports: [
        ErrorModalComponent
    ],
    entryComponents: [
        ErrorModalComponent
    ]
})
export class ErrorModalModule {
}
