import _isArray from 'lodash/isArray';
import _remove from 'lodash/remove';
import _mergeWith from 'lodash/mergeWith';
import {PaymentNotificationActionsType, PaymentNotificationActionsUnion} from '../actions/payment_notification.action';
import storage from 'store';

const initialState = {
    isOpen: false,
    bubbles: [],
    not_viewed: storage.get('unread_payment_notifications'),
    notifications: null
};

function customizer(objValue, srcValue) {
    if (_isArray(objValue)) {
        return objValue.concat(srcValue);
    }
}

export function paymentNotificationReducer(state: any = initialState, action: PaymentNotificationActionsUnion) {
    switch (action.type) {
        case PaymentNotificationActionsType.TOGGLE_PAYMENT_NOTIFICATIONS: {
            return {
                ...state,
                isOpen: typeof action.payload !== 'undefined' ? action.payload : !state.isOpen
            };
        }
        case PaymentNotificationActionsType.SET_BUBBLE_MESSAGE: {
            let add = true;
            state.bubbles.forEach(el => {
                if (JSON.stringify(el) === JSON.stringify(action.payload)) {
                    add = false;
                }
            });


            if (add) {
                return {
                    ...state,
                    bubbles: [...state.bubbles, action.payload]
                };
            } else {
                return {
                    ...state
                };
            }
        }
        case PaymentNotificationActionsType.REMOVE_BUBBLE_MESSAGE: {
            _remove(state.bubbles, (o) => o.id === action.payload);
            return {
                ...state,
                bubbles: [...state.bubbles]
            };
        }
        case PaymentNotificationActionsType.SET_NOT_VIEWED_MESSAGE: {
            let add = true;
            state.bubbles.forEach(el => {
                if (JSON.stringify(el) === JSON.stringify(action.payload.data)) {
                    add = false;
                }
            });

            if (add) {
                return {
                    ...state,
                    not_viewed: action.payload.count
                };
            } else {
                return {
                    ...state
                };
            }
        }
        case PaymentNotificationActionsType.SET_PAYMENT_NOTIFICATION_HISTORY: {
            let newState = {
                items: [],
                _meta: null
            };
            if (action.payload) {
                if (action.payload.meta.page === 1) {
                    newState = action.payload;
                } else {
                    newState = _mergeWith(state.messages, action.payload, customizer);
                }
            }
            return {
                ...state,
                messages: newState
            };
        }
        default: {
            return state;
        }
    }
}
