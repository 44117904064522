import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Rx';
import localStore from 'store';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private api: ApiService) {
  }

  public getCalendar(params): Observable<any> {
    return this.api.get('/calendar', {
      params: params
    });
  }

  public updateCalendar(data, type, id): Observable<any> {
    return this.api.put('/calendar' + '/' + type + '/' + id, {
      body: data
    });
  }

  public deleteCalendar(type, id): Observable<any> {
    return this.api.delete('/calendar/' + type + '/' + id, {});
  }

  public getSubordinateAgents(params): Observable<any> {
    return this.api.get('/users/subordinate-users', {params: params});
  }

  public reschedule(id: number): Observable<any> {
    return this.api.get(`/reschedule_rules/${id}`, {});
  }

  public getReschedule(id: number): Observable<any> {
    return new Observable((observer) => {
      const reschedule = localStore.get('reschedule');

      if (reschedule) {
        observer.next(reschedule);
        observer.complete();
      } else {
        this.reschedule(id).toPromise().then((res) => {
          localStore.set('reschedule', res);

          observer.next(res);
          observer.complete();
        }).catch((error) => {
          observer.error(error);
          observer.complete();
        });
      }
    });
  }
}
