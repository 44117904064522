import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleSelectorComponent } from './multiple-selector.component';
import {
    MatAutocompleteModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule,
    MatListModule, MatTooltipModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        MultipleSelectorComponent
    ],
  entryComponents: [
    MultipleSelectorComponent
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatListModule,
        MatInputModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule

    ],
    exports: [
        MultipleSelectorComponent
    ]
})
export class MultipleSelectorModule {
}
