import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Milestone {
  id: number,
  name: string,
  goal: number,
  current: number,
  overdueReturn: number,
  left: number,
  commission: number,
  color: string,
  status: string,
  icon: string,
  slug: string
}

@Component({
  selector: 'app-milestones-modal',
  templateUrl: './milestones-modal.component.html',
  styleUrls: ['./milestones-modal.component.scss',]
})
export class MilestonesModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public self: MatDialogRef<MilestonesModalComponent>,
  ) {
    this.self.addPanelClass('header-milestones-modal-panel');
  }

  public dateProgress = 0;

  public displayedColumns = ['name', 'goal', 'current', 'left', 'commission', 'status'];

  public timelineNames = [];

  public info: any = {
    monthlyGoal: 0,
    currentMilestoneGoal: 0,
    currentMilestoneCommission: 0,
    milestones: Array<Milestone>()
  }

  public mainCircle: any = {
    dailyGoal: 0,
    progress: 0
  }

  public stats: any = {
    achieved: 0,
    monthlyTargetCommission: 0,
    achievedCommission: 0,
    pendingCommission: 0,
    isMonthlyAchieved: false
  }

  public futureMilestone = null;

  ngOnInit(): void {
    this.setDateProgress();

    this.setData();
    this.setMainCircle();
    this.setStats();
  }

  public setDateProgress(): void {
    let today = new Date();

    let info = {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear()
    }

    let daysInMonth = new Date(info.year, info.month, 0).getDate();
    this.dateProgress = (info.day / daysInMonth) * 100;
  }

  public setMainCircle(): void {
    const data = this.data.data.short;

    if (!data.individual_target || !data.milestone_info) return;

    let reached = data.individual_target.reached_by_user || 0;
    let daily = this.info.monthlyGoal / data.milestone_info.total_working_days;

    this.mainCircle = {
      goal: daily.toFixed(0),
      progress: reached / daily * 100
    }
  }

  public setStats(): void {
    const data = this.data.data.short;

    if (!data.individual_target || !data.milestone_info || !data.milestone_info) return;

    let achievedCommission = this.getMilestonesPendingByStatus(true);
    let pendingCommission = this.getMilestonesPendingByStatus(false);
    let isMonthlyAchieved = false;

    if (data.individual_target.collected >= data.individual_target.goal) {
      achievedCommission += data.milestone_info.monthly_pending_amount;
      isMonthlyAchieved = true
    }

    if (data.individual_target.collected < data.individual_target.goal) {
      pendingCommission += data.milestone_info.monthly_pending_amount;
    }

    this.stats = {
      achieved: (data.individual_target.collected || 0).toFixed(0),
      monthlyTargetCommission: data.milestone_info.monthly_pending_amount,
      achievedCommission,
      pendingCommission,
      isMonthlyAchieved
    }
  }

  public setData(): void {
    const data = this.data.data.short;

    const milestones = data.milestone_info && data.milestone_info.milestones ? data.milestone_info.milestones : [];
    const allMilestones = data.milestone_info && data.milestone_info.milestones_info ? data.milestone_info.milestones_info : [];

    let milestone = milestones.find((i: any) => i.current);

    this.info = {
      monthlyGoal: data.individual_target && data.individual_target.goal ? data.individual_target.goal : 0,
      currentMilestoneGoal: milestone.goal,
      currentMilestoneCommission: milestone.pending_commission,
      milestones: this.mapMilestones(milestones)
    }

    this.setTimeline(allMilestones);
  }

  public mapMilestones(milestones): [Milestone] {
    return milestones.map(((m: any) => {
      const status = this.getMilestoneStatus(m);
      const achieved = m.achieved;

      return {
        id: m.milestone_number,
        name: this.getMilestoneName(m.start_date, m.end_date),
        goal: m.goal.toFixed(0),
        current: achieved,
        extraAmount: m.extra_amount || 0,
        overdueReturn: m.amount_from_previous_milestone.toFixed(0),
        left: achieved < m.goal ? (m.goal - achieved) : 0,
        commission: m.pending_commission.toFixed(0),
        color: m.color,
        status: status,
        icon: this.getMatIconNameByStatus(status),
        slug: this.getClassNameByStatus(status)
      }
    }))
  }

  public setTimeline(list: [any]): void {
    if (!list || list.length < 1) return;

    list.forEach(m => {
      const name = this.getMilestoneName(m.start_date, m.end_date);
      this.timelineNames.push(name)
    })
  }

  public getMilestonesPendingByStatus(status: boolean): number {
    const data = this.data.data.short;

    return data.milestone_info.milestones
      .filter(i => i.success_status === status)
      .map(i => i.pending_commission)
      .reduce((acc, m) => acc + m, 0)
  }

  public getMilestoneName(startTimestamp, endTimestamp): string {
    let startDay = new Date(startTimestamp * 1000).getDate();
    let end = new Date(endTimestamp * 1000)

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    return `${startDay} - ${end.getDate()} ${monthNames[end.getMonth()]} ${end.getFullYear()}`;
  }

  public getMilestoneStatus(milestone): string {
    if (milestone.success_status) return "Achieved";

    if (!milestone.success_status) {
      if (milestone.current) return "In Progress";

      return "Not Achieved";
    }

    return "";
  }

  public getMatIconNameByStatus(status): string {
    switch (status) {
      case "Achieved": return "check_circle"
      case "Not Achieved": return "warning"
      case "In Progress": return "access_time"
    }
  }

  public getClassNameByStatus(status): string {
    switch (status) {
      case "Achieved": return "achieved"
      case "Not Achieved": return "not_achieved"
      case "In Progress": return "in_progress"
    }
  }
}
