import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BoolFilterComponent} from './bool-filter.component';
import {MatRadioModule} from '@angular/material';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        BoolFilterComponent
    ],
    imports: [
        CommonModule,
        MatRadioModule,
        FormsModule
    ],
    exports: [
        BoolFilterComponent
    ]
})
export class BoolFilterModule {
}
