import { LOCALE_ID, Provider } from '@angular/core';
import { MatPaginatorIntl, MAT_DATE_LOCALE } from '@angular/material';
import { CustomMatPaginatorIntl } from './custom-mat-paginator-intl';

import { LocaleService } from './locale.service';

export class LocaleId extends String {
  constructor(private localeService: LocaleService) {
    super();
  }

  toString(): string {
    return this.localeService.currentLocale;
  }

  valueOf(): string {
    return this.toString();
  }
}

export const LocaleProvider = [
  {
    provide: LOCALE_ID,
    useClass: LocaleId,
    deps: [LocaleService],
  },
  {
    provide: MAT_DATE_LOCALE,
    useClass: LocaleId,
    deps: [LocaleService],
  },
  {
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl,
  },
]
