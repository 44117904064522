import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallmentNotificationComponent } from './installment-notification.component';
import {InstallmentModalComponent} from './installment-modal/installment-modal.component';
import {
  MatButtonModule, MatCheckboxModule, MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule, MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import {LoaderModule} from '../../../general/loader/loader.module';
import {SmallLoaderModule} from '../../../general/small-loader/small-loader.module';
import {FilterTableModule} from '../../../general/filter-table/filter-table.module';
import {
  InstallmentsNotificationPopupComponent
} from '../../../info-right-menu/modals/payment-link-v2/installments-notification-popup/installments-notification-popup.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSelectModule} from 'ngx-select-ex';
import {MultipleSelectorModule} from '../../../general/multiple-selector/multiple-selector.module';

@NgModule({
  declarations: [
    InstallmentNotificationComponent,
    InstallmentModalComponent,
    InstallmentsNotificationPopupComponent
  ],
  exports: [
    InstallmentNotificationComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    LoaderModule,
    SmallLoaderModule,
    FilterTableModule,
    MultipleSelectorModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgxSelectModule,
    MatDatepickerModule
  ],
  entryComponents: [
    InstallmentModalComponent,
    InstallmentsNotificationPopupComponent
  ]
})
export class InstallmentNotificationModule { }
