import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import es from 'src/assets/i18n/es.json';
// import en from 'src/assets/i18n/en.json';

@Pipe({
  name: 'customTranslate'
})
export class CustomTranslatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {}

  transform(label: string, block: string, textType?): string {
    if (this.translateService.currentLang === 'es') {
      if (textType === 'lowercase') {
        const capitalized = label.charAt(0).toUpperCase() + label.slice(1);
        return es[block][capitalized] || `'${capitalized}'`;
      } else if (textType === 'unknown') {
        const lower = label.toLocaleLowerCase();
        for (const key in es[block]) {
          return es[block][key].toLocaleLowerCase() === lower ? es[block][key] : label;
        }
      } else {
        return es[block][label] || `'${label}'`;
      }
    } else {
      return label;
    }
  }

}
