import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import SearchResponse from '../../../response/search.response';
import {Router} from '@angular/router';
import storage from 'store';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent implements OnInit, OnChanges {

  @Input() public column: any;

  @Input() public searchRes: SearchResponse | null;

  @Input() public initFilters: any;

  @Input() public hideClearColumn: any;

  @Output() public filterChange: EventEmitter<any> = new EventEmitter();

  public user = storage.get('user');

  public filters = {};

  constructor(private router: Router) {}

  public clearFilter() {
    for (const name in this.filters) {
      if (this.filters.hasOwnProperty(name) && this.filters[name]) {
        this.filters = {};
        this.dispatchChange();
        break;
      }
    }
  }

  public changeFilter(event) {
    if (event) {
      if (typeof event.value === 'object' && Object.keys(event.value).length === 0) {
        if (this.filters[event.name]) {
          delete this.filters[event.name];
        }
      } else {
        this.filters[event.name] = JSON.stringify(event.value.map(v => v.value));
      }
    }
    this.dispatchChange();
  }

  private dispatchChange() {
    const event = {};

    for (const name in this.filters) {
      if (this.filters.hasOwnProperty(name) && this.filters[name]) {
        event[name] = this.filters[name];
      }
    }

    this.filterChange.emit(event);

    this.eventTrack(event);
  }

  private eventTrack(data: any): void {
    window['cve']('track', 'crm', 'search', {
      id: this.user.id,
      search_query: data
    });
  }

  public clearModels() {
    for (const name in this.filters) {
      if (this.filters.hasOwnProperty(name) && this.filters[name]) {
        this.filters[name] = null;
      }
    }
  }

  ngOnInit() {
    this.filters = {};
    // this.initFilters = {};

    if (this.initFilters) {
      this.filters = Object.assign(this.filters, this.initFilters);
      // for (const name in this.initFilters) {
      //     if (this.initFilters.hasOwnProperty(name)) {
      //         this.filters[name] = this.initFilters[name];
      //     }
      // }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initFilters && !changes.initFilters.firstChange) {

      this.filters = Object.assign({}, this.initFilters);

    }
  }
}



