import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { getErrorMsg } from 'src/app/helpers/getErrorMsg';
import { ReferralsService } from 'src/app/services/referrals/referrals.service';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss',],
  encapsulation: ViewEncapsulation.None
})
export class ReferralsComponent implements OnInit {

  public loader: boolean;

  public types = {
    preview: 0,
    internal: 1,
    employee: 2
  }
  public type: number = 0;
  public internalStep = 0;

  public formData: FormData = new FormData();

  public form: FormGroup = new FormGroup({
    candidate_name: new FormControl(null, [Validators.required, Validators.maxLength(120)]),
    department: new FormControl(null, [Validators.required, Validators.maxLength(250)]),
    line_manager: new FormControl(null, [Validators.required, Validators.maxLength(120)]),
    vacancy_of_interest: new FormControl(null, [Validators.required, Validators.maxLength(120)]),
    motivation_letter: new FormControl(null, Validators.required),
  })

  constructor(
    private self: MatDialogRef<ReferralsComponent>,
    private referralsService: ReferralsService,
    private snackBar: MatSnackBar,
  ) { 
    this.self.addPanelClass('referrals-panel');
  }

  ngOnInit(): void {}

  public onSubmit(evt) {
    evt.preventDefault();
    if (!this.form.valid) { return; }
    this.loader = true;
    for (const key in this.form.value) {
      if (key === 'motivation_letter') { continue; }
      this.formData.append(key, this.form.value[key]);
    }
    this.referralsService.sendInvitation(
      this.formData
    ).toPromise().then((res) => {
      this.loader = false;
      this.showSnackBar('Your letter of motivation is uploaded successfully!');
      this.self.close()
    }, (err) => {
      this.onError(err);
    });
  }

  public onFileChange(evt) {
    const files = evt.target.files;
    if (!(files && files.length)) { return; }
    const file = files[0];
    this.formData.append('motivation_letter', file);
    this.form.get('motivation_letter').patchValue(file.name);
    this.form.get('motivation_letter').markAsTouched;
  }

  private showSnackBar(text: string) {
    this.snackBar.open(text, 'Ok', { duration: 5000 });
  }

  private onError(err, loader = false) {
    this.loader = loader;
    this.snackBar.open(getErrorMsg(err), 'Dismiss', {
      duration: 5000,
      panelClass: ['error-snackbar']
    });
  }
}
