import {Component, OnDestroy, OnInit} from '@angular/core';
import {delay, takeWhile, withLatestFrom} from 'rxjs/operators';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import localStore from 'store';
import lsStorage from 'store';

import {StateService} from '../../../@core/data/state.service';

import {select, Store} from '@ngrx/store';
import {MenuHelper} from '../../../../helpers/MenuHelper';

import _get from 'lodash/get';
import {INSTALLMENT_NOTIFICATION_ROLES} from '../../../../constants/general';
import {PermissionsHelper} from '../../../../helpers/Permission';

@Component({
    selector: 'default-layout',
    styleUrls: ['./default.layout.scss'],
    templateUrl: './default.layout.html'
})

export class DefaultLayoutComponent implements OnInit, OnDestroy {
    public menuItems: any = [];
    public defaultMenuItems: any = [...MenuHelper.defaultMenuItems];

    public can_view_salary_projection: boolean;
    public can_view_installment_notification: boolean;

    private user: any = lsStorage.get('user');

    layout: any = {};
    sidebar: any = {};
    expanded: boolean = false;

    christmas: boolean = false;

    private alive = true;

    currentTheme: string;

    constructor(
      protected permission: PermissionsHelper,
      protected stateService: StateService,
      protected menuService: NbMenuService,
      protected themeService: NbThemeService,
      protected bpService: NbMediaBreakpointsService,
      protected sidebarService: NbSidebarService,
      private store: Store<any>
    ) {
        this.can_view_installment_notification = INSTALLMENT_NOTIFICATION_ROLES.includes(this.user.role);

        this.stateService.onLayoutState()
            .pipe(takeWhile(() => this.alive))
            .subscribe((layout: string) => this.layout = layout);

        this.stateService.onSidebarState()
            .pipe(takeWhile(() => this.alive))
            .subscribe((sidebar: string) => {
                this.sidebar = sidebar;

            });

        const isBp = this.bpService.getByName('is');
        this.menuService.onItemSelect()
            .pipe(
                takeWhile(() => this.alive),
                withLatestFrom(this.themeService.onMediaQueryChange()),
                delay(20),
            )
            .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
                if (bpTo.width <= isBp.width) {
                    this.sidebarService.collapse('menu-sidebar');
                }
            });

        this.themeService.getJsTheme()

            .pipe(takeWhile(() => this.alive))
            .subscribe(theme => {
                this.currentTheme = theme.name;
            });
    }

    ngOnInit() {

        if (this.permission.can('general_settings', 'show_christmas_version', 'true')) {
            this.christmas = true;
        }

      this.menuItems = this.defaultMenuItems.filter(item => {
          return this.permission.boardSettings(item.type, 'view');
      });

      this.checkViewSalaryProjection();
    }

    public checkViewSalaryProjection() {
        const settings = localStore.get('settings');
        if (settings && settings.personal && settings.personal.can_view_salary_projection) {
            const cvsp = settings.personal.can_view_salary_projection
            if (cvsp.value && cvsp.value.value) {
                this.can_view_salary_projection = true;
            }
        }
    }

    ngOnDestroy() {
        this.alive = false;
    }

    toggleSideBar(event) {
        this.expanded = event;
    }
}
