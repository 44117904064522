import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuizService } from 'src/app/services/quiz/quiz.service';
// import { selectorTypes } from '../constants/selectorTypes';
import localStore from 'store';

@Component({
  selector: 'app-quiz-request',
  templateUrl: './quiz-request.component.html',
  styleUrls: ['./quiz-request.component.scss',],
  encapsulation: ViewEncapsulation.None
})
export class QuizRequestComponent implements OnInit {

  public loader: boolean = false;

  public isSent: boolean = false;

  public description: FormControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(2000)]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matSnack: MatSnackBar,
    private self: MatDialogRef<QuizRequestComponent>,
    // private matDialog: MatDialog,
    private quizService: QuizService,
  ) {
    self.addPanelClass('quiz-request-panel');
  }

  ngOnInit(): void {

  }

  public onClose() {
    this.self.close();
    localStore.remove('quizId');
    localStore.remove('quizSave');
  }

  public onSubmit() {
    const user = localStore.get('user');
    if (!this.description.valid) { return; }
    this.loader = true
    this.quizService.sendRequest(
      this.data.quiz_id,
      user.id,
      this.description.value
    ).toPromise().then((res) => {
      this.isSent = true;
      this.loader = false;
    }, (err) => {
      this.onError(err);
    })
  }

  private onError(err) {
    this.loader = false;
    let msg;
    if (err.error) {
      if (typeof err.error === 'string') { 
        msg = err.error;
      } else if (typeof err.error === 'object') {
        if (typeof err.error.error === 'string') {
          msg = err.error.error;
        } else {
          msg = JSON.stringify(err.error);
        }
      }
    }
    this.matSnack.open(msg, 'Dismiss', {
      duration: 3000,
      panelClass: ['error-snackbar']
    });
  }

}
