export class MenuHelper {

    static defaultMenuItems: any = [
        {
            title: 'aside.Agent_Dashboard',
            i18n: true,
            icon: 'far fa-id-card',
            link: '/dashboard/leads',
            type: 'leads'
        },
        {
            title: 'aside.Opportunities',
            i18n: true,
            icon: 'fas fa-bezier-curve',
            link: '/opportunities',
            type: 'opportunities'
        },
        {
            title: 'aside.Applications',
            i18n: true,
            icon: 'fas fa-portrait',
            link: '/applications',
            type: 'applications'
        },
        {
            title: 'aside.Calendar',
            i18n: true,
            icon: 'far fa-calendar-alt',
            link: '/calendar',
            type: 'calendar'
        },
        {
            title: 'aside.Rewards',
            i18n: true,
            icon: 'fas fa-trophy',
            link: '/rewards',
            type: 'rewards'
        },
        {
            title: 'aside.Partial',
            i18n: true,
            icon: 'fas fa-chart-pie',
            link: '/partial-payment',
            type: 'partials'
        },
        {
            title: 'aside.Risks',
            i18n: true,
            icon: 'fas fa-skull',
            link: '/risks',
            type: 'risks'
        },
        {
            title: 'CSR',
            icon: 'fas fa-stamp',
            link: '/csr',
            type: 'csr'
        },
        {
            title: 'Refunds',
            icon: 'fas fa-hand-holding-usd',
            link: '/refunds',
            type: 'refunds'
        },
        {
            title: 'Referral Program',
            icon: 'fas fa-user-plus',
            link: '/referral-program',
            type: 'referrals'
        },
        {
            title: 'Students',
            icon: 'fas fa-user-graduate',
            link: '/students',
            type: 'students'
        },
        {
            title: 'aside.Tickets',
            i18n: true,
            icon: 'fas fa-clipboard-list',
            link: '/tickets',
            type: 'tickets'
        },
        {
            title: 'PIP/PR',
            icon: 'fas fa-address-card',
            link: '/users',
            type: 'pip_pr'
        },
        {
            title: 'Empowerment Process',
            icon: 'fas fa-business-time',
            link: '/empowerment-process',
            type: 'performance_review'
        }
    ];

}
