import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {QuickPointsModalComponent} from '../quick-points-modal/quick-points-modal.component';

@Component({
    selector: 'app-view-rewards-modal',
    templateUrl: './view-rewards-modal.component.html',
    styleUrls: ['./view-rewards-modal.component.scss']
})
export class ViewRewardsModalComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private self: MatDialogRef<ViewRewardsModalComponent>,
                private matDialog: MatDialog) {
    }

    ngOnInit() {
    }

    public openPoints() {
        this.self.close(true);
        this.matDialog.open(QuickPointsModalComponent, {
            width: '800px',
            panelClass: 'points-info',
            hasBackdrop: true,
            data: ''
        });
    }
}
