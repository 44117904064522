import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';

@Component({
    selector: 'app-call-statistics-duration',
    templateUrl: './call-statistics-duration.component.html',
    styleUrls: ['./call-statistics-duration.component.scss']
})

export class CallStatisticsDurationComponent implements OnInit, OnDestroy {
    public seconds: any;

    public subs: any = {
        timer: null,
    };

    constructor(private store: Store<any>,
                private cdr: ChangeDetectorRef) {

    }

    ngOnInit() {
        this.subs.timer = this.store.pipe(select('timer')).subscribe((time) => {
            this.seconds = time;
            this.cdr.detectChanges();
        });
    }

    public getPercent() {
        return Math.floor((this.seconds / 15) * 100);

    }

    ngOnDestroy() {

    }


}
