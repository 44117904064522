import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface WizardCount {
  count: number;
  count_called: number;
  count_best: number;
}

@Injectable({
  providedIn: 'root'
})
export class WizardCountService {

  public count: WizardCount = {
    count: 0,
    count_called: 0,
    count_best: 0
  };

  private wizardCountSource = new BehaviorSubject(null);

  public wizardCount = this.wizardCountSource.asObservable();

  constructor() { }

  public setCount(count: WizardCount): void {
    this.count = count;

    this.wizardCountSource.next(count);
  }

}
