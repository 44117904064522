import { Component, OnInit } from '@angular/core';
import localStore from 'store';
import _get from 'lodash/get';
import {PERFORMANCE_STATUS} from '../../../constants/ep-process';
import {EpProcess} from '../../../interfaces/ep-process.interface';
import {Store} from '@ngrx/store';
import {CentrifugeService} from '../../../services/centrifuge/centrifuge.service';
import {map} from 'rxjs/operators/map';
import * as UserActionTypes from '../../../actions/user.action';

@Component({
  selector: 'app-ep-process',
  templateUrl: './ep-process.component.html',
  styleUrls: ['./ep-process.component.scss']
})
export class EpProcessComponent implements OnInit {

  public readonly PERFORMANCE_STATUS = PERFORMANCE_STATUS;

  public user = null;

  public performance: EpProcess = null;

  public progress(): number {
    if (this.performance && this.performance.details && this.performance.details.trial) {
      const now = new Date().getTime();
      const due_day = this.performance.details.trial.due_day * 1000;
      const start_time = this.performance.details.start_time * 1000;

      if (this.performance.details.start_time > 0 && this.performance.details.trial.due_day > 0) {
        const passed = start_time - now;
        if (passed < 0) {
          const period = due_day - start_time;

          return Math.round(-passed / period * 100);
        }
      }
    }

    return 0;
  }

  public left(): any {
    if (this.performance && this.performance.details && this.performance.details.trial) {
      const now = new Date().getTime();
      const due_day = this.performance.details.trial.due_day * 1000;

      const passed = due_day - now;
      if (passed > 0) {
        if (passed < 3600000) {
          return Math.round(passed / (1000 * 60)) + ' min';
        }
        if (passed < 86400000) {
          return Math.round(passed / (1000 * 60 * 60)) + ' hours';
        } else {
          return Math.round(passed / (1000 * 60 * 60 * 24)) + ' days';
        }
      } else {
        return '0';
      }
    }
    return '0'
  }

  public showTrial = false;

  constructor(
    private store: Store<any>,
    private centrifugeService: CentrifugeService,
  ) {
    this.store.select('user').subscribe(u => {
      this.user = u;
      this.performance = u.performance_process_details;
    });

    if (this.performance && this.performance.details && this.performance.details.trial) {
      this.showTrial = this.performance.details.trial.due_day * 1000 > new Date().getTime() && this.performance.details.trial.status && this.performance.details.end_time > 0
    }
  }

  ngOnInit() {
    if (
      this.performance.status === PERFORMANCE_STATUS.IN_PROGRESS &&
      _get(this.performance, 'details.main_document.use_main_document', true) &&
      (
        !_get(this.performance, 'details.main_document.document_sign_status', true) ||
        !_get(this.performance, 'details.main_document.url', '')
      )
    ) {
      this.documentSignSubscribe();
    }
  }

  private documentSignSubscribe(): void {
    const channelName = `ep_process_document_action_${this.user.id}`;

    this.centrifugeService.listen(channelName)
      .pipe(
        map(res => _get(res, 'data', {}))
      )
      .subscribe((data) => {
        if (data.action === 'signed') {
          this.user.performance_process_details.details.main_document.document_sign_status = true;

          // this.centrifugeService.disconnectChannel(channelName);
        }

        this.user.performance_process_details.details.main_document.url = data.url;

        localStore.set('user', this.user);

        this.store.dispatch(new UserActionTypes.SetUser(this.user));
      });
  }

}
