import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CallOccupationModalComponent} from './call-occupation-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatDialogModule} from '@angular/material';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    CallOccupationModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule
  ],
  exports: [
    CallOccupationModalComponent
  ]
})
export class CallOccupationModalModule {
}
