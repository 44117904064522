import { HttpClient } from "@angular/common/http";

export default class NameGenerator {
  
  constructor(private http: HttpClient){}

  public getFirstName() {
    return this.http.get('https://cv-agent-dash.s3.eu-central-1.amazonaws.com/json-data/first-names.json', {}).toPromise().then(res => {
      return this.pickRandom(res);
    })
  }
  public getMiddleName() {
    return this.http.get('https://cv-agent-dash.s3.eu-central-1.amazonaws.com/json-data/middle-names.json', {}).toPromise().then(res => {
      return this.pickRandom(res);
    })
  }
  public getLastName() {
    return this.http.get('https://cv-agent-dash.s3.eu-central-1.amazonaws.com/json-data/names.json', {}).toPromise().then(res => {
      return this.pickRandom(res);
    })
  }
  public getPlace() {
    return this.http.get('https://cv-agent-dash.s3.eu-central-1.amazonaws.com/json-data/places.json', {}).toPromise().then(res => {
      return this.pickRandom(res);
    })
  }

  private pickRandom(list) {
    return list[Math.floor(Math.random() * list.length)];
  }
}

