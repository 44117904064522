import {ActionReducerMap} from '@ngrx/store';
import {usersReducer} from './users.reducer';
import {chartsReducer} from './charts.reducer';
import {userReducer} from './user.reducer';
import {fieldsReducer} from './fields.reducer';
import {timerReducer} from './timer.reducer';
import {permissionsReducer} from './permissions.reducer';
import {chatReducer} from './chat.reducer';
import {paymentNotificationReducer} from './payment_notification.reducer';
import {paymentLinkReducer} from "./payment_link.reducer";

export const reducers: ActionReducerMap<any> = {
    users: usersReducer,
    charts: chartsReducer,
    user: userReducer,
    fields: fieldsReducer,
    timer: timerReducer,
    permissions: permissionsReducer,
    chat: chatReducer,
    paymentNotification: paymentNotificationReducer,
    paymentLinks: paymentLinkReducer
};
