import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CommissionsV3Component } from './commissions-v3.component';
import { CommissionModalModule } from './commission-modal/commission-modal.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MonthlyGoalVouchersModalModule } from './monthly-goal-vouchers-modal/monthly-goal-vouchers-modal.module';
import { MatIconModule } from '@angular/material/icon';
import { CommissionAchievedPopupModule } from '../commission-achieved-popup/commission-achieved-popup.module';
import { MilestonesModalModule } from './milestones-modal/milestones-modal.module';
import { ProductCommissionModalModule } from './product-commission-modal/product-commission-modal.module';
import { TranslateModule } from '@ngx-translate/core';
import {ToCurrencyPipeModule} from '../../pipes/to-currency/to-currency.pipe.module';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import { AvgComponent } from './avg/avg.component';
import { AvgModalComponent } from './avg-modal/avg-modal.component';
import {DecimalPipeModule} from '../../pipes/decimal/decimal.pipe.module';
import { EpProcessComponent } from './ep-process/ep-process.component';
import {MatProgressBarModule} from '@angular/material';
import { ToCurrencyPipe } from 'src/app/pipes/to-currency/to-currency.pipe';
import { PointsAchievedPopupComponent } from '../points-achieved-popup/points-achieved-popup.component';
import { PointsAchievedPopupModule } from '../points-achieved-popup/points-achieved-popup.module';

@NgModule({
  declarations: [
    CommissionsV3Component,
    AvgComponent,
    AvgModalComponent,
    EpProcessComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,

    MatTooltipModule,
    MatIconModule,
    NgCircleProgressModule,

    CommissionModalModule,
    MilestonesModalModule,
    MonthlyGoalVouchersModalModule,
    CommissionAchievedPopupModule,

    ProductCommissionModalModule,
    TranslateModule,
    ToCurrencyPipeModule,
    PointsAchievedPopupModule,
    NgbCarouselModule,
    DecimalPipeModule,
    MatProgressBarModule
  ],
  exports: [
    CommissionsV3Component,
    EpProcessComponent
  ],
  providers: [
    ToCurrencyPipe,
    PointsAchievedPopupComponent,
    DecimalPipe
  ],
  entryComponents: [
    AvgModalComponent
  ]
})
export class CommissionsV3Module {
}
