import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MonthlyGoalVouchersModalComponent} from './monthly-goal-vouchers-modal.component';


@NgModule({
  declarations: [
    MonthlyGoalVouchersModalComponent,
  ],
  imports: [
    CommonModule,
  ],
  entryComponents: [
    MonthlyGoalVouchersModalComponent
  ]
})
export class MonthlyGoalVouchersModalModule {
}
